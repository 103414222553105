import reduceReducers from 'reduce-reducers';
import {
  SET_FULFILLMENT_OPTIONS,
  RESET_FULFILLMENT_OPTIONS,
  RESET_SHIPPING_ADDRESS
} from 'bento-ordering/base/actions/actionTypes';
import {
  getIsAsapAvailableForLocationAndMethodSelector,
  getDatetimesWithRangesSelector
} from 'bento-ordering/locations/selectors/';

import { directlyEditableCartFields } from 'bento-ordering/cart/reducers';
import {
  areFulfillmentOptionSetSelector,
  areFulfillmentDeliveryOptionsSetSelector,
  getAsapLabelSelector
} from 'bento-ordering/cart/selectors';
import { fulfillmentOptions } from 'constants/fulfillmentOptions';
import validateAddressFactory, {
  validatingAddressInitialState
} from 'bento-ordering/base/reducers/validateAddressFactory';

export const initialState = {
  ...directlyEditableCartFields,
  ...validatingAddressInitialState
};

const fulfillmentOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FULFILLMENT_OPTIONS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_FULFILLMENT_OPTIONS: {
      return initialState;
    }
    case RESET_SHIPPING_ADDRESS: {
      return {
        ...state,
        shipping_street_address_1: '',
        shipping_street_address_2: '',
        shipping_city: '',
        shipping_state: '',
        shipping_postal_code: '',
        shipping_country: '',
        shipping_google_place_id: '',
        shipping_lat: null,
        shipping_lng: null
      };
    }
    default: {
      return state;
    }
  }
};

const cartWorkingCopy = reduceReducers(
  fulfillmentOptionsReducer,
  validateAddressFactory('cart')
);

export default cartWorkingCopy;

/* === SELECTORS === */

export const isDinerAddressDeliverableSelector = cartWorkingCopy => {
  return cartWorkingCopy.shipping_google_place_id
    ? cartWorkingCopy.has_online_ordering_delivery_zone ||
        cartWorkingCopy.is_within_doordash_radius
    : null;
};

/* if it's delivery, is there an address and is the address valid */
export const areFulfillmentOptionsValidSelector = ({
  cartWorkingCopyState
}) => {
  const isDinerAddressDeliverable = isDinerAddressDeliverableSelector(
    cartWorkingCopyState
  );

  const areFulfillmentDateTimeOptionsSet = areFulfillmentOptionSetSelector(
    cartWorkingCopyState
  );

  const fulfillmentDeliveryOptionsAreSet = areFulfillmentDeliveryOptionsSetSelector(
    cartWorkingCopyState
  );
  const areFulfillmentDeliveryOptionsValid =
    cartWorkingCopyState.online_ordering_fulfillment_method ===
    fulfillmentOptions.DELIVERY
      ? isDinerAddressDeliverable && fulfillmentDeliveryOptionsAreSet
      : true;

  const areFulfillmentOptionsValid =
    areFulfillmentDateTimeOptionsSet && areFulfillmentDeliveryOptionsValid;

  return !!areFulfillmentOptionsValid;
};

export const getAsapLaterOptionsSelector = (
  restaurantLocations,
  locationThrottledTimes,
  cartWorkingCopy
) => {
  const isAsapAvailableForLocationAndMethod = getIsAsapAvailableForLocationAndMethodSelector(
    {
      restaurantLocationsState: restaurantLocations,
      locationThrottledTimesState: locationThrottledTimes,
      cart: cartWorkingCopy,
      selectedMethod: cartWorkingCopy.online_ordering_fulfillment_method,
      onlineOrderingLocation: cartWorkingCopy.online_ordering_location
    }
  );

  const asapLabel = getAsapLabelSelector({
    locationThrottledTimes,
    restaurantLocations,
    cart: cartWorkingCopy
  });
  const laterRangesForLocationAndMethod = getDatetimesWithRangesSelector(
    restaurantLocations,
    cartWorkingCopy.online_ordering_location,
    cartWorkingCopy.online_ordering_fulfillment_method
  );

  return [
    {
      value: 1,
      disabled: !isAsapAvailableForLocationAndMethod,
      label: isAsapAvailableForLocationAndMethod
        ? asapLabel
        : 'ASAP (Currently Unavailable)'
    },
    {
      value: 0,
      disabled: !laterRangesForLocationAndMethod.length,
      label: 'Later'
    }
  ];
};
