import { getLocationInventoryEndpoint } from 'bento-ordering/base/constants/endpoints';
import { apiAction } from 'bento-ordering/base/utils/apiHelpers';

import { LOCATION_INVENTORY } from 'bento-ordering/locations/constants/entities';
import getByLocationActionTypeBuilder from 'bento-ordering/locations/actions/byLocationIdActionTypes';

/**
 * @function
 *
 *  that can be used to build action types for locationInventory entity with location id in meta
 */
export const getLocationInventoryActionTypes = getByLocationActionTypeBuilder(
  LOCATION_INVENTORY
);

/**
 * dispatch apiAction to location inventory endpoint
 * @param {number} locationId
 * @return {function}
 */
export const fetchLocationInventory = locationId =>
  function fetchLocationInventoryAction(dispatch) {
    if (!locationId) {
      console.warn(
        'fetchLocationInventory was called without an online ordering location id'
      );
      return null;
    }
    const endpoint = getLocationInventoryEndpoint(locationId);
    const actionTypes = getLocationInventoryActionTypes(locationId);

    dispatch(apiAction(endpoint, 'GET', actionTypes));
  };
