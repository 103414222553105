import { createTheme } from '@mui/material/styles';

import theme from 'styles/theme';

export function createDesignAndCustomizationTheme({
  customizableFields,
  selfHostedFonts
}) {
  // NOTE: This will load self-hosted fonts if they exist
  // Other custom fonts via Google, Typekit, Fontdeck, and MonoType are loaded
  // within the WebFont config
  const selfHostedFontFaces = theme.loadSelfHostedCssFontFaces(selfHostedFonts);
  const defaultFontFaces = theme.loadDefaultFonts();

  const typography = theme.getCustomTypography(customizableFields);
  const palette = theme.getCustomPalette(customizableFields);
  const { muiCardOutline } = theme.getComponentsStyles({ palette });
  const styleOverrides = theme.styleOverrides({
    fontFaces: [...defaultFontFaces, ...selfHostedFontFaces],
    muiCardOutline
  });

  const components = theme.createComponents(styleOverrides, theme.defaultProps);

  return createTheme({
    typography,
    palette,
    components,
    constants: theme.constants,
    fonts: theme.fonts,
    breakpoints: theme.breakpoints,
    boxShadows: {
      MuiCard: {
        base: theme.constants.boxShadows.normal,
        selected: theme.constants.boxShadows.selected
      }
    }
  });
}
