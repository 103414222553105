import get from 'lodash/get';

import {
  ADD_TO_CART_SUCCESS,
  RESET_LAST_ITEM,
  EDIT_CART_ITEM_SUCCESS,
  ADD_TO_CART_FAILURE
} from 'bento-ordering/cart/constants/actionTypes';
const initialState = {
  add: {},
  edit: {}
};
// this reducer is used to display a notification for adding an item to cart or editing a cart item
const lastItem = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CART_ITEM_SUCCESS: {
      return {
        ...state,
        edit: {
          product: action.payload.item.product,
          name: action.payload.item.name
        }
      };
    }
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        add: {
          product: get(action.payload, 'item.product', null),
          name: get(action.payload, 'item.name', 'Item')
        }
      };
    }
    case ADD_TO_CART_FAILURE: {
      return state;
    }
    case RESET_LAST_ITEM:
      return initialState;
    default:
      return state;
  }
};

export default lastItem;
