/**
 * All fulfillment options
 *
 * @type {{PICKUP: string, DINE_IN: string, DELIVERY: string}}
 */
export const fulfillmentOptions = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  DINE_IN: 'dine_in'
};

export const TITLE_BY_FULFILLMENT_METHOD = {
  [fulfillmentOptions.DINE_IN]: 'Dine-In',
  [fulfillmentOptions.DELIVERY]: 'Delivery',
  [fulfillmentOptions.PICKUP]: 'Pickup'
};

/**
 * Fulfillment options display text
 * @type {{FULFILLMENT_METHOD: string: string}}
 */
export const fulfillmentOptionsDisplayText = {
  [fulfillmentOptions.DELIVERY]: 'delivery',
  [fulfillmentOptions.PICKUP]: 'pickup',
  [fulfillmentOptions.DINE_IN]: 'dine-in'
};

/**
 * Online ordering fulfillment methods that does not require
 * a seating arrangement for the customer.
 *
 * @type {(string)[]}
 */
export const ALL_ONLINE_ORDERING_FULFILLMENT_METHODS = [
  fulfillmentOptions.DELIVERY,
  fulfillmentOptions.PICKUP
];

/**
 * Online ordering fulfillment methods that does not require
 * a seating arrangement for the customer.
 *
 * @type {(string)[]}
 */
export const ALL_CATERING_FULFILLMENT_METHODS = [
  fulfillmentOptions.DELIVERY,
  fulfillmentOptions.PICKUP
];

/**
 * All fulfillment methods
 *
 * @type {(string)[]}
 */
export const ALL_FULFILLMENT_METHODS = Object.values(fulfillmentOptions);
