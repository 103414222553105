import {
  GROUP_ORDER_500_RESPONSE_MESSAGE,
  GROUP_ORDER_404_RESPONSE_MESSAGE,
  GROUP_ORDER_1006_RESPONSE_MESSAGE,
  TRY_AGAIN_MESSAGE
} from 'bento-ordering/group_ordering/constants/groupOrder';

export const formGroupOrderError = status_code => {
  if (!status_code) {
    return null;
  }

  if (status_code === 404) {
    return {
      code: status_code,
      message: GROUP_ORDER_404_RESPONSE_MESSAGE
    };
  }

  if (status_code >= 400 && status_code < 600) {
    return {
      code: status_code,
      message: GROUP_ORDER_500_RESPONSE_MESSAGE,
      helper: TRY_AGAIN_MESSAGE
    };
  }

  if (status_code === 1006) {
    return {
      code: status_code,
      message: GROUP_ORDER_1006_RESPONSE_MESSAGE,
      helper: TRY_AGAIN_MESSAGE
    };
  }

  // If status code is not an error code
  return null;
};
