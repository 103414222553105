import {
  LIGHT_SOY,
  MEDIUM_SOY,
  MEDIUM_TOFU,
  SOY,
  TOMATO,
  WHITE,
  ERROR
} from 'styles/theme/colors';
import constants from 'styles/theme/constants';
import fonts from 'styles/theme/fonts';

export const styleOverrides = ({
  fontFaces = [],
  muiCardOutline = 'none'
}) => ({
  MuiCssBaseline: {
    styleOverrides: fontFaces.join('\n')
  },
  MuiCard: {
    styleOverrides: {
      root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: 8,
        boxShadow: constants.boxShadows.normal,
        outline: muiCardOutline,
        '&:hover': {
          boxShadow: constants.boxShadows.selected,
          bottom: 1
        }
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        height: '100%',
        padding: 20,
        '&:last-child': {
          paddingBottom: 20
        }
      }
    }
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        height: '100%'
      },
      focusHighlight: {
        opacity: '0 !important'
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: constants.boxShadows.appBar
      }
    }
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  MuiCardMedia: {
    styleOverrides: {
      root: {
        objectFit: 'cover'
      }
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        justifyContent: 'space-between'
      }
    }
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outlineColor: '-webkit-focus-ring-color',
          outlineStyle: 'auto',
          outlineWidth: '5px',
          outline: '5px auto -webkit-focus-ring-color',
          outlineOffset: '-2px'
        }
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: fonts.medium,
        fontSize: '20px !important',
        textTransform: 'capitalize',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '0 !important',
        marginRight: 24
      },
      wrapper: {
        display: 'flex',
        alignItems: 'flex-start'
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: fonts.medium,
        fontSize: 14,
        letterSpacing: 1.25,
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: LIGHT_SOY
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fill: LIGHT_SOY
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        overflow: 'hidden'
      }
    }
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        width: '100%'
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        padding: 0,
        marginLeft: 0
      },
      root: {
        '&.Mui-error': {
          color: TOMATO
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        width: '100%',
        color: MEDIUM_SOY
      },
      select: {
        width: '100%',
        '&:focus': {
          backgroundColor: WHITE,
          border: '0'
        },
        padding: '19px 12px 13px'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: `1px solid ${MEDIUM_TOFU} !important`
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'translate(12px, 22px) scale(1)',
        zIndex: 1
      },
      shrink: {
        color: `${SOY}`,
        transform: 'translate(12px, 14px) scale(0.75) !important',
        '&.Mui-focused': {
          color: `${SOY}`
        },
        '&.Mui-error': {
          color: ERROR
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%'
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: SOY
      }
    }
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${MEDIUM_TOFU}`,
        borderRadius: '4px',
        transition: 'none',
        color: MEDIUM_SOY,
        backgroundColor: `${WHITE} !important`
      },
      input: {
        top: '8px',
        position: 'relative',
        padding: '17px 12px 18px'
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: fonts.medium,
        letterSpacing: 1.25,
        borderRadius: '4px',
        textTransform: 'uppercase',
        padding: 0,
        '&:hover': {
          color: MEDIUM_SOY,
          backgroundColor: 'transparent !important'
        }
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '8px',
        overflowY: 'visible'
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        '&:first-child': {
          paddingTop: 0
        }
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        display: 'block',
        padding: 0
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0
      }
    }
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 'auto',
        padding: '20px',
        backgroundColor: WHITE,
        boxShadow: constants.boxShadows.appBar,
        zIndex: 100
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      dayDisabled: {
        color: LIGHT_SOY
      }
    }
  }
});
