export const GROUP_ORDER_JOIN_REQUEST = 'GROUP_ORDER_JOIN_REQUEST';
export const GROUP_ORDER_JOIN_SUCCESS = 'GROUP_ORDER_JOIN_SUCCESS';
export const GROUP_ORDER_JOIN_FAILURE = 'GROUP_ORDER_JOIN_FAILURE';

export const UPDATE_GUEST_REQUEST = 'UPDATE_GUEST_REQUEST';
export const UPDATE_GUEST_SUCCESS = 'UPDATE_GUEST_SUCCESS';
export const UPDATE_GUEST_FAILURE = 'UPDATE_GUEST_FAILURE';

export const GROUP_ORDER_LEAVE_REQUEST = 'GROUP_ORDER_LEAVE_REQUEST';
export const GROUP_ORDER_LEAVE_SUCCESS = 'GROUP_ORDER_LEAVE_SUCCESS';
export const GROUP_ORDER_LEAVE_FAILURE = 'GROUP_ORDER_LEAVE_FAILURE';

export const GROUP_ORDER_REMOVE_GUEST = 'GROUP_ORDER_REMOVE_GUEST';

export const GROUP_ORDER_CLOSE_CART = 'GROUP_ORDER_CLOSE_CART';

export const GROUP_ORDER_WEB_SOCKET_CONNECTION_FAILURE =
  'GROUP_ORDER_WEB_SOCKET_CONNECTION_FAILURE';
