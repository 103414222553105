// Required by Axios
import 'es6-promise/auto';
import 'whatwg-fetch';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { initializeSentry } from 'config/sentry';
import { exposeMomentForIntegrationTests } from 'test/testUtils';
import { initializeDatadog } from 'logging/datadog';
import logWarningOrError from 'logging/logWarningOrError';
import setupAxeCore from 'utils/axeCoreTool';

import { setLogger } from 'react-query';

initializeSentry();
exposeMomentForIntegrationTests();
initializeDatadog();
setupAxeCore();

if (process.env.NODE_ENV !== 'production' && process.env.IS_API_MOCKED) {
  const { worker } = require('./bento-ordering/api/mocks/browser');
  worker.start();
}
setLogger({
  error: error => {
    logWarningOrError(error, error);
  }
});

ReactDOM.render(<App />, document.getElementById('app'));
