import get from 'lodash/get';
import reduceReducers from 'reduce-reducers';

import {
  ADD_TO_CART_SUCCESS,
  DELETE_CART_SUCCESS,
  DELETE_CART_REQUEST,
  DELETE_CART_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_FAILURE,
  FETCH_CART_SUCCESS,
  FETCH_CART_REQUEST,
  FETCH_CART_FAILURE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  REMOVE_CART_ITEM_FAILURE_NOT_FOUND,
  EDIT_CART_REQUEST,
  EDIT_CART_SUCCESS,
  EDIT_CART_FAILURE,
  EDIT_CART_ITEM_REQUEST,
  EDIT_CART_ITEM_SUCCESS,
  EDIT_CART_ITEM_FAILURE,
  RESET_CART_ERROR,
  EDIT_LOCATION_REQUEST,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE
} from 'bento-ordering/cart/constants/actionTypes';
import {
  GROUP_ORDER_JOIN_REQUEST,
  GROUP_ORDER_JOIN_SUCCESS,
  GROUP_ORDER_JOIN_FAILURE,
  GROUP_ORDER_REMOVE_GUEST,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_FAILURE,
  GROUP_ORDER_LEAVE_SUCCESS,
  GROUP_ORDER_LEAVE_FAILURE,
  GROUP_ORDER_CLOSE_CART,
  GROUP_ORDER_WEB_SOCKET_CONNECTION_FAILURE
} from 'bento-ordering/group_ordering/actions/actionTypes';
import { GROUP_ORDER as GROUP_ORDER_ENTITY } from 'bento-ordering/group_ordering/constants/entities';
import {
  CREATE_SUCCESS,
  CREATE_FAILURE,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_FAILURE
} from 'bento-ordering/base/actions/actionTypes';

import setupDataFactory from 'bento-ordering/base/reducers/setupDataFactory';
import relateAddressToLocationsFactory from 'bento-ordering/base/reducers/relateAddressToLocationsFactory';
import cartValidation from 'bento-ordering/cart/reducers/cartValidation';

import { formGroupOrderError } from 'bento-ordering/cart/utils/formGroupOrderErrorObject';
import { SET_SEAT_ID_ON_CART_SUCCESS } from 'bento-ordering/investor_day/constants';

export const directlyEditableCartFields = {
  requested_fulfillment_is_asap: null,
  fulfillment_time: null,
  fulfillment_date: null,
  online_ordering_fulfillment_method: '',
  shipping_street_address_1: '',
  shipping_street_address_2: '',
  shipping_city: '',
  shipping_state: '',
  shipping_postal_code: '',
  shipping_country: '',
  shipping_google_place_id: '',
  shipping_lat: null,
  shipping_lng: null,
  has_online_ordering_delivery_zone: false,
  online_ordering_estimated_asap_pickup_time: 0,
  online_ordering_estimated_asap_delivery_range: [],
  seat_id_value: ''
};

export const initialCartState = {
  slug: '',
  total: '0.00',
  total_owed: 0,
  tax: '0.00',
  taxes_and_fees: null,
  subtotal: '0.00',
  quantity: 0,
  online_ordering_service_fee: '0.00',
  checkout_url: '',
  error: [],
  isFetchingAddToCart: false,
  isFetchingLocation: false,
  isFetching: false,
  items: [],
  ...directlyEditableCartFields
};

const cartReducer = (state = initialCartState, action) => {
  let groupOrderError;

  switch (action.type) {
    case ADD_TO_CART_SUCCESS: {
      // if this is a successful request we set the error to empty array
      // if this is unsuccessful, the api still returns 200.
      // we check if there the success flag is false, then  we set the message sent by the api as the error message

      // i am leaving this as an array for now in case we need to add multiple
      // errors
      return {
        ...state,
        ...action.payload.cart,
        error: [],
        isFetching: false,
        isFetchingAddToCart: false
      };
    }
    case RESET_CART_ERROR: {
      return {
        ...state,
        error: []
      };
    }
    case FETCH_CART_REQUEST:
    case REMOVE_CART_ITEM_REQUEST:
    case EDIT_CART_REQUEST:
    case DELETE_CART_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case ADD_TO_CART_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetchingAddToCart: true
      };
    }
    case SET_SEAT_ID_ON_CART_SUCCESS:
    case FETCH_CART_SUCCESS:
    case EDIT_CART_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    }
    case EDIT_CART_ITEM_REQUEST:
      return {
        ...state
      };
    case DELETE_CART_SUCCESS:
    case REMOVE_CART_ITEM_SUCCESS:
    case EDIT_CART_ITEM_SUCCESS: {
      return {
        ...state,
        ...action.payload.cart,
        isFetching: false,
        cartQuantityError: null
      };
    }
    case EDIT_CART_ITEM_FAILURE: {
      return {
        ...state,
        isFetching: false,
        cartQuantityError: get(action, 'payload.response.error')
      };
    }
    case REMOVE_CART_ITEM_FAILURE_NOT_FOUND: {
      return {
        ...state,
        items: action.payload
      };
    }
    case REMOVE_CART_ITEM_FAILURE:
    case EDIT_CART_FAILURE:
    case DELETE_CART_FAILURE:
    case FETCH_CART_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: [get(action, 'payload.response.error')]
      };
    case ADD_TO_CART_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isFetchingAddToCart: false,
        error: [get(action, 'payload.response.error')]
      };
    }
    case EDIT_LOCATION_REQUEST:
      return {
        ...state,
        isFetchingLocation: true
      };
    case EDIT_LOCATION_SUCCESS: {
      const newState = {
        ...state,
        ...action.payload,
        isFetchingLocation: false
      };

      // When the EDIT_LOCATION_SUCCESS action is dispatched, it means the response from the endpoint
      // was successful. The endpoint will remove the cart items if the online_ordering_fulfillment_method
      // was changed so we should update the cart items in state, accordingly.
      if (
        state.online_ordering_fulfillment_method !==
        action.payload.online_ordering_fulfillment_method
      ) {
        newState.items = [];
      }

      return newState;
    }
    // GROUP ORDER ACTIONS //
    case GROUP_ORDER_JOIN_REQUEST:
    case UPDATE_GUEST_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false
      };

    case GROUP_ORDER_JOIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        group_order: {
          ...state.group_order,
          current_diner: action.payload
        },
        groupOrderError: null
      };

    case UPDATE_GUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        group_order: {
          ...action.payload
        },
        groupOrderError: null
      };

    case GROUP_ORDER_LEAVE_SUCCESS:
      return {
        ...state,
        isError: false,
        groupOrderError: null
      };

    case GROUP_ORDER_LEAVE_FAILURE:
      groupOrderError = formGroupOrderError(get(action, 'payload.status'));
      return {
        ...state,
        isError: true,
        groupOrderError
      };

    case `[${GROUP_ORDER_ENTITY}] ${CREATE_SUCCESS}`:
    case `[${GROUP_ORDER_ENTITY}] ${FETCH_SUCCESS}`:
    case `[${GROUP_ORDER_ENTITY}] ${UPDATE_SUCCESS}`: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isError: false,
        groupOrderError: null
      };
    }

    case GROUP_ORDER_JOIN_FAILURE:
    case UPDATE_GUEST_FAILURE:
    case `[${GROUP_ORDER_ENTITY}] ${CREATE_FAILURE}`:
    case `[${GROUP_ORDER_ENTITY}] ${FETCH_FAILURE}`:
    case `[${GROUP_ORDER_ENTITY}] ${UPDATE_FAILURE}`:
    case `[${GROUP_ORDER_ENTITY}] ${DELETE_FAILURE}`: {
      groupOrderError = formGroupOrderError(get(action, 'payload.status'));
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isError: true,
        groupOrderError
      };
    }

    case GROUP_ORDER_REMOVE_GUEST: {
      let diners = state.group_order?.diners ?? [];
      diners = diners.filter(diner => diner.uuid !== action.payload?.guestUUID);

      return {
        ...state,
        group_order: {
          ...state.group_order,
          diners
        }
      };
    }

    case GROUP_ORDER_CLOSE_CART:
      return {
        ...state,
        group_order: {
          ...state.group_order,
          cart_closed: true
        }
      };

    case GROUP_ORDER_WEB_SOCKET_CONNECTION_FAILURE: {
      groupOrderError = formGroupOrderError(get(action, 'payload.status'));
      return {
        ...state,
        isError: true,
        groupOrderError
      };
    }

    case EDIT_LOCATION_FAILURE:
    default:
      return state;
  }
};

export default reduceReducers(
  initialCartState,
  cartReducer,
  setupDataFactory('cart'),
  relateAddressToLocationsFactory('cart'),
  cartValidation
);
