import logError from 'helpers/logError';
import logWarning from 'helpers/logWarning';
import shouldRaiseError from 'logging/shouldRaiseError';

export default (error, info = null) => {
  const { message, stack } = error;

  if (!shouldRaiseError(error)) {
    logWarning(message, info, stack);
  } else {
    logError(message ?? error, info, stack);
  }
};
