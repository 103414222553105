import reduceReducers from 'reduce-reducers';
import get from 'lodash/get';

import bentoApi, { baseApiState } from 'bento-ordering/base/reducers/bentoApi';
import { PROMOTIONS as ENTITY } from 'bento-ordering/promotions/constants/entities';
import { formatListResponse } from 'bento-ordering/base/actions/api';
import setupPromotionsData from 'bento-ordering/promotions/reducers/setupPromotionsData';
import {
  ADD_TO_CART_SUCCESS,
  EDIT_CART_ITEM_SUCCESS,
  FETCH_CART_FAILURE,
  EDIT_CART_ITEM_REQUEST,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS
} from 'bento-ordering/cart/constants/actionTypes';

const basePromotionState = {
  ...baseApiState,
  previousState: {}
};

const promotionsReducer = (entity, processPayload = payload => payload) => (
  state = basePromotionState,
  action
) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        ...processPayload(action.payload[entity]),
        isFetching: false,
        error: [],
        previousState: {
          byId: state.byId,
          allIds: state.allIds
        }
      };
    }
    case DELETE_CART_REQUEST:
    case REMOVE_CART_ITEM_REQUEST:
    case EDIT_CART_ITEM_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case DELETE_CART_SUCCESS:
    case REMOVE_CART_ITEM_SUCCESS:
    case EDIT_CART_ITEM_SUCCESS: {
      return {
        ...state,
        ...processPayload(action.payload[entity]),
        isFetching: false,
        error: [],
        previousState: {
          byId: state.byId,
          allIds: state.allIds
        }
      };
    }
    case FETCH_CART_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: [get(action, 'payload.response.error')]
      };
    }
    default:
      return state;
  }
};
const reducers = reduceReducers(
  basePromotionState,
  bentoApi(ENTITY),
  setupPromotionsData(ENTITY, formatListResponse),
  promotionsReducer(ENTITY, formatListResponse)
);

export default reducers;
