export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

export const EDIT_CART_REQUEST = 'EDIT_CART_REQUEST';
export const EDIT_CART_SUCCESS = 'EDIT_CART_SUCCESS';
export const EDIT_CART_FAILURE = 'EDIT_CART_FAILURE';

export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';

export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST';
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS';
export const DELETE_CART_FAILURE = 'DELETE_CART_FAILURE';

export const REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';
export const REMOVE_CART_ITEM_FAILURE_NOT_FOUND =
  'REMOVE_CART_ITEM_FAILURE_NOT_FOUND';

export const EDIT_CART_ITEM_REQUEST = 'EDIT_CART_ITEM_REQUEST';
export const EDIT_CART_ITEM_SUCCESS = 'EDIT_CART_ITEM_SUCCESS';
export const EDIT_CART_ITEM_FAILURE = 'EDIT_CART_ITEM_FAILURE';

export const CART_ITEMS_AVAILABLE_REQUEST = 'CART_ITEMS_AVAILABLE_REQUEST';
export const CART_ITEMS_AVAILABLE_SUCCESS = 'CART_ITEMS_AVAILABLE_SUCCESS';
export const CART_ITEMS_AVAILABLE_FAILURE = 'CART_ITEMS_AVAILABLE_FAILURE';
export const CART_ITEMS_AVAILABLE_RESET = 'CART_ITEMS_AVAILABLE_RESET';

export const RESET_CART_ERROR = 'RESET_CART_ERROR';
export const RESET_LAST_ITEM = 'RESET_LAST_ITEM';

export const EDIT_LOCATION_REQUEST = 'EDIT_LOCATION_REQUEST';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAILURE = 'EDIT_LOCATION_FAILURE';

export const SET_DOES_TOTAL_EXCEED_CART_MAXIMUM =
  'DOES_TOTAL_EXCEED_CART_MAXIMUM';
export const SET_IS_TOTAL_BELOW_CART_MINIUMUM =
  'SET_IS_TOTAL_BELOW_CART_MINIUMUM';

export const VALIDATE_CART_REQUEST = 'VALIDATE_CART_REQUEST';
export const VALIDATE_CART_SUCCESS = 'VALIDATE_CART_SUCCESS';
export const VALIDATE_CART_FAILURE = 'VALIDATE_CART_FAILURE';

export const RESET_PICKUP_ADDRESS_REQUEST = 'RESET_PICKUP_ADDRESS_REQUEST';
export const RESET_PICKUP_ADDRESS_SUCCESS = 'RESET_PICKUP_ADDRESS_SUCCESS';
export const RESET_PICKUP_ADDRESS_FAILURE = 'RESET_PICKUP_ADDRESS_FAILURE';
