import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import restaurantLocations from 'bento-ordering/locations/reducers';
import locationThrottledTimes from 'bento-ordering/locations/reducers/locationThrottledTimes';
import locationInventory from 'bento-ordering/locations/reducers/locationInventory';
import account from 'bento-ordering/account/reducers';
import brand from 'bento-ordering/brand/reducers';
import cart from 'bento-ordering/cart/reducers';
import promotions from 'bento-ordering/promotions/reducers';
import lastItem from 'bento-ordering/cart/reducers/lastItem';
import cartAvailable from 'bento-ordering/cart/reducers/cartAvailable';
import orderAmount from 'bento-ordering/cart/reducers/orderAmount';
import menu, {
  menuItemsDetail,
  productPreview
} from 'bento-ordering/menus/reducers';
import modal from 'bento-ordering/base/reducers/modal';
import cartWorkingCopy from 'bento-ordering/cart/reducers/cartWorkingCopy';
import notificationModal from 'bento-ordering/base/reducers/notificationModal';
import alertStatusReducer from 'bento-ordering/base/reducers/alertStatus';
import tracking from 'bento-ordering/base/reducers/tracking';
import setupDataFactory from 'bento-ordering/base/reducers/setupDataFactory';
import libraryLoad from 'bento-ordering/base/reducers/libraryLoad';
import scrollManager from 'bento-ordering/base/reducers/scrollManager';
import cartValidation from 'bento-ordering/cart/reducers/cartValidation';
import searchInput from 'bento-ordering/base/reducers/searchInput';
const user = setupDataFactory('user');

export default history =>
  combineReducers({
    restaurantLocations,
    locationInventory,
    locationThrottledTimes,
    account,
    brand,
    cart,
    promotions,
    cartAvailable,
    orderAmount,
    menu,
    modal,
    productPreview,
    menuItemsDetail,
    notificationModal,
    lastItem,
    tracking,
    form: formReducer,
    cartWorkingCopy,
    alertStatus: alertStatusReducer,
    router: connectRouter(history),
    user,
    libraryLoad,
    scrollManager,
    cartValidation,
    searchInput
  });
