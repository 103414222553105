/**
 * Find the difference between to dollar amount rounded to two decimal places
 */
export const getFormattedDollarDifference = (biggerValue, smallerValue) => {
  const difference = parseFloat(biggerValue) - parseFloat(smallerValue);
  return difference.toFixed(2);
};

/**
 * return a value with two decimal place
 */
export const getValueWithTwoDecimalPlace = value => {
  return parseFloat(value).toFixed(2);
};
