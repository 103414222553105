import {
  ONLINE_ORDERING_BASE_PATH,
  DINE_IN_BASE_PATH
} from 'bento-ordering/base/constants/basePaths';

const isBentoOrderingRoute = () => {
  const pathname = window.location.pathname;
  return (
    pathname.startsWith(ONLINE_ORDERING_BASE_PATH) ||
    pathname.startsWith(DINE_IN_BASE_PATH)
  );
};

export default isBentoOrderingRoute;
