export const GROUP_SETTINGS_TITLE = 'Create Group Order';
export const UPDATE_GROUP_SETTINGS_TITLE = 'Update Group Order';
export const GROUP_SETTINGS_CTA_LABEL = 'START SHARING';
export const UPDATE_GROUP_SETTINGS_CTA_LABEL = 'UPDATE';
export const START_NEW_ORDER = 'Start a new order';

export const HOW_IT_WORKS = {
  label: 'How it works',
  description: {
    inviteGuest:
      'Invite guests with the option to assign a deadline or spending limit.',
    payFor: 'Pay for everyone once they are done and submit it as one order.'
  }
};
export const SPENDING_LIMIT_BUDGET_STRING =
  'Keep your budget on track by selecting a per-person spending limit.';
export const SPENIDNG_LIMIT_TITLE = 'Spending Limit (optional)';
export const MOBILE_CREATE_GROUP_ORDER_CTA_LABEL = 'Create Group Order';

export const DEADLINE_CONTROL_TITLE = 'Deadline (optional)';
export const DEADLINE_CONTROL_STRING =
  'Add a deadline, so everyone knows when to add their items.';
export const NO_DEADLINE = 'NO DEADLINE';

export const DESKTOP_CREATE_GROUP_ORDER_CTA_LABEL = 'Group Order';
export const GROUP_ORDER_APP_BAR_ICON = 'group_order';

export const START_ORDERING = 'START ORDERING';
export const INVITE_HEADER = 'Invite Others to Join';

export const GUEST_CONFIRMATION_BUTTON = {
  primary: 'Confirm My Order',
  error: 'Start My Own Order',
  retry: 'Try Again'
};

export const getInviteHelperMaxGuests = (maxGuests = null) => {
  const invitableNumber = maxGuests - 1;
  return maxGuests
    ? `Invite up to ${invitableNumber} ${
        invitableNumber === 1 ? 'person' : 'people'
      } by sending them a shareable link or by having them scan the QR code below`
    : 'Invite others to join by sending them a shareable link or by having them scan the QR code below.';
};
export const SPENDING_LIMIT_REACHED = {
  SPENDING_LIMIT_OF: "You've reached the spending limit of",
  PLEASE_REMOVE_ITEMS:
    'Please remove items from your cart or adjust their quantities to continue to checkout.'
};

export const GROUP_ORDER_CART_HEADER_TITLE = `'s Group Order`;
export const SPENDING_LIMIT_PER_PERSON = 'Spending Limit Per Person';
export const DUE_BY = 'Due By';

export const MAX_GUESTS_REACHED = {
  title: 'Sorry, this Group Order has reached the maximum number of guests.',
  primaryButtonLabel: 'Start ordering on my own'
};

export const CART_IS_CLOSED = {
  title: 'Sorry, this group order has been closed.',
  primaryButtonLabel: 'Start ordering on my own'
};

export const GUESTS_STATUS_CHECKOUT_INFO = {
  noGuestHaveJoined: 'No guests have joined yet.',
  allGuestsHaveConfirmed:
    'You are ready to check out. All your guests have confirmed their items.',
  notAllGuestsHaveConfirmed:
    "When you click checkout, your guests won't be able to make any changes."
};

export const CANCEL_GROUP_ORDER_CONFIRMATION = {
  title: 'Are you sure you want to cancel this group order?',
  yes: 'Yes',
  no: 'No'
};

export const GROUP_ORDER_CHECKOUT_CONFIRMATION = {
  title: 'Are you ready to check out?',
  deadline: 'Your deadline for ordering is on',
  description:
    'If you check out now, the group order will be locked and your guests will not be able to edit their orders.',
  confirm: 'Yes, Checkout',
  cancel: 'Return to my cart',
  startover: 'Start a new order',
  retry: 'Try Again'
};

export const GROUP_ORDER_DINER_NAME_INPUT_MAX_LENGTH = '25';

export const GROUP_ORDER_500_RESPONSE_MESSAGE =
  'Sorry, we encountered an error.';
export const GROUP_ORDER_404_RESPONSE_MESSAGE =
  'Sorry, this Group Order is no longer available.';
export const GROUP_ORDER_1006_RESPONSE_MESSAGE =
  'Sorry, we encountered an error.';
export const TRY_AGAIN_MESSAGE = 'Please try again.';

// we have to use HTTPS codes in bento-stream due to API Gateway response limitation
// using 1006 here as it's the WebSocket close code for "abnormal closure"
export const WS_ABNORMAL_CLOSURE_CODE = 1006;
