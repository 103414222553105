import moment from 'moment';
import {
  DATE_OUTPUT_FORMAT,
  MILITARY_TIME,
  HOUR_MINUTE_DAY_TIME
} from 'bento-ordering/base/constants/timeFormat';
import { OVERNIGHT_HOURS } from 'bento-ordering/locations/constants';
import { isMidnight } from 'bento-ordering/base/utils/timeHelpers';
/**
 * Build the select options for dates for ui
 * have to check time options for date to see if date is disabled
 *
 * @param {string} date - in YYYY-MM-DD format
 * @param {{disabled: boolean}[]} timeOptions
 * @return {{value: string, label: string, disabled: boolean, timeOptions: {}[]}}
 */
export const buildDateSelectOption = (date, timeOptions = []) => {
  return {
    value: date,
    label: moment(date).format(DATE_OUTPUT_FORMAT),
    disabled: !timeOptions.filter(option => !option.disabled).length,
    timeOptions: timeOptions
  };
};

/**
 *
 * get the first object in an array that is not disabled
 *
 * @param {{}[]} arr - of objects that should have key for disabled
 * @return {{}} first object not disabled
 */
export const getFirstEnabled = arr => {
  return arr.find(option => !option.disabled);
};
/**
 * fallback if date option not found
 */
const DEFAULT_DATE_OPTION = {
  timeOptions: []
};

/**
 * fallback if time option not found
 */
const DEFAULT_TIME_OPTION = {};

/**
 * get the first available date and time
 *
 * @param {{value: string, label: string, disabled: boolean, timeOptions: {}[]}[]} fulfillmentDateOptions - objects with keys for
 * @return {{}[]} 2 member array with first date object not disabled and first time object not disabled
 */
export const getFirstAvailableDateTime = fulfillmentDateOptions => {
  const firstAvailableDate =
    getFirstEnabled(fulfillmentDateOptions) || DEFAULT_DATE_OPTION;
  const firstAvailableTime =
    getFirstEnabled(firstAvailableDate.timeOptions) || DEFAULT_TIME_OPTION;

  return [firstAvailableDate, firstAvailableTime];
};

/**
 * get the first available date and time
 *
 * @param {{value: string, label: string, disabled: boolean, timeOptions: {}[]}[]} fulfillmentDateOptions
 * @param {string} date - '2019-05-09'
 * @return {{}} date option
 */
export const findDateOption = (fulfillmentDateOptions, date) => {
  return (
    fulfillmentDateOptions.find(dateOption => dateOption.value === date) ||
    DEFAULT_DATE_OPTION
  );
};

/**
 * determine if date has time and if date is enabled
 *
 * @param {{value: string, label: string, disabled: boolean, timeOptions: {}[]}} dateOption
 * @param {string} time - '2019-05-09'
 * @return {boolean}
 */
export const getDoesDateHaveTimeEnabled = (dateOption, time) => {
  return !!dateOption.timeOptions.find(
    hour => !hour.disabled && hour.time === time
  );
};

/**
 * process hours list for consumption by select dropdown
 *
 * Second object is only returned if there are overnight hours
 *
 * @param {{time:string, disabled:boolean}[]} list
 * @return {[{label:'Today', values:[]}, {label: 'Overnight Hours', values:[]}]}
 */
export const processHoursList = list => {
  let hasGonePastMidnight = false;

  return list.reduce(
    (acc, currHour, currIndex, arr) => {
      const { time, disabled } = currHour;
      const momentTime = moment(time, MILITARY_TIME);
      const regularTime = momentTime.format(HOUR_MINUTE_DAY_TIME);
      const processed = { value: time, label: regularTime, disabled };
      const isLast = currIndex === arr.length - 1;
      // this is tricky, but the only way we have overnight hours is if
      // we have gone past midnight and midnight is not the first option
      if (currIndex !== 0 && isMidnight(time)) {
        hasGonePastMidnight = true;
      }

      if (hasGonePastMidnight) {
        acc[1].values.push(processed);
      } else {
        acc[0].values.push(processed);
      }

      if (isLast) {
        return acc.filter(optionGroup => !!optionGroup.values.length);
      }

      return acc;
    },
    [
      { label: 'Today', values: [] },
      { label: OVERNIGHT_HOURS, values: [] }
    ]
  );
};
