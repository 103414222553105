import moment from 'moment';
import { getNearestDatetimeInterval } from 'bento-ordering/base/utils/timeHelpers';
import { DATE_TIME_FORMAT } from 'bento-ordering/base/constants/timeFormat';
import { MINUTES_IN_ORDER_THROTTLE_WINDOW } from 'bento-ordering/locations/constants/orderThrottleSettings';

/**
 * get the prepared by time for a delivery order given delivery time
 * @param {string} fulfillmentDatetime (can accept a moment object as well)
 * @param {number} deliveryTimeInMinutes
 * @return {string} representing prepared by time for delivery datetime
 */
const getDeliveryPrepByTimeForThrottles = (
  fulfillmentDatetime,
  deliveryTimeInMinutes
) => {
  const lessDeliveryTimeMoment = moment(fulfillmentDatetime, DATE_TIME_FORMAT);

  if (!lessDeliveryTimeMoment.isValid()) {
    return '';
  }
  const lessDeliveryTime = lessDeliveryTimeMoment
    .subtract(deliveryTimeInMinutes, 'minutes')
    .format(DATE_TIME_FORMAT);

  return getNearestDatetimeInterval(
    lessDeliveryTime,
    MINUTES_IN_ORDER_THROTTLE_WINDOW
  );
};

export default getDeliveryPrepByTimeForThrottles;
