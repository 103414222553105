import update from 'immutability-helper';
import get from 'lodash/get';
import { formatRelateLocationsToAddressResponse } from 'bento-ordering/locations/reducers/';
import {
  UPDATE_FULFILLMENT_OPTIONS_REQUEST,
  UPDATE_FULFILLMENT_OPTIONS_SUCCESS,
  UPDATE_FULFILLMENT_OPTIONS_FAILURE
} from 'bento-ordering/locations/constants/actionTypes';
const initialState = {
  updatingFulfillmentOptionsError: '',
  isUpdatingFulfillmentOptions: false
};
const locationFulfillmentOptions = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FULFILLMENT_OPTIONS_REQUEST: {
      return {
        ...state,
        updatingFulfillmentOptionsError: '',
        isUpdatingFulfillmentOptions: true
      };
    }
    case UPDATE_FULFILLMENT_OPTIONS_SUCCESS: {
      const updateObj = {
        $merge: initialState,
        ...formatRelateLocationsToAddressResponse(action.payload.locations)
      };
      return update(state, updateObj);
    }
    case UPDATE_FULFILLMENT_OPTIONS_FAILURE:
      return {
        ...state,
        updatingFulfillmentOptionsError: get(
          action,
          'payload.response.error',
          ''
        ),
        isUpdatingFulfillmentOptions: false
      };

    default:
      return state;
  }
};

export default locationFulfillmentOptions;
