import React from 'react';
import { Provider } from 'react-redux';
import { render } from '@testing-library/react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { configureStore, testHistory } from 'config/store';
import { createDesignAndCustomizationTheme } from 'styles/theme/muiTheme';
import moment from 'moment';

const testTheme = createDesignAndCustomizationTheme({
  customizableFields: {},
  selfHostedFonts: []
});

function TestThemeProvider(props) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={testTheme} {...props} />
    </StyledEngineProvider>
  );
}

export function renderWithThemeProvider(ui) {
  return render(<TestThemeProvider>{ui}</TestThemeProvider>);
}

export const renderWithReduxAndRouter = (
  ui,
  initialState = {},
  path = '/',
  search = '',
  url
) => {
  testHistory.push({ pathname: url || path, search });
  const store = configureStore(initialState, testHistory);
  return {
    ...render(
      <TestThemeProvider>
        <Provider store={store}>
          <ConnectedRouter history={testHistory}>
            <Route exact path={path} render={() => ui} />
          </ConnectedRouter>
        </Provider>
      </TestThemeProvider>
    ),
    // adding `store` to the returned utilities to allow us
    // to reference it in our tests (just try to avoid using
    // this to test implementation details).
    store
  };
};
export const exposeMomentForIntegrationTests = () => {
  // set default mocked time for automated integration tests
  if (navigator.userAgent.search('BentoBox/Test') !== -1) {
    const today = new Date();
    const fake_date = +new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      15,
      30,
      0,
      0
    );
    moment.now = function() {
      return +new Date(fake_date);
    };
    window.moment = moment;
  }
};
export const setupGoogleMock = () => {
  /** * Mock Google Maps JavaScript API ***/
  const google = {
    maps: {
      Marker: class {
        setPosition() {}
        setMap() {}
      },
      Map: class {
        setTilt() {}
        fitBounds() {}
        setCenter() {}
        setOptions() {}
        setZoom() {}
      },
      places: {
        AutocompleteService: () => {},
        PlacesServiceStatus: {
          INVALID_REQUEST: 'INVALID_REQUEST',
          NOT_FOUND: 'NOT_FOUND',
          OK: 'OK',
          OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
          REQUEST_DENIED: 'REQUEST_DENIED',
          UNKNOWN_ERROR: 'UNKNOWN_ERROR',
          ZERO_RESULTS: 'ZERO_RESULTS'
        }
      },
      LatLngBounds: () => {
        return {
          extend: () => {}
        };
      },
      Geocoder: () => {},
      GeocoderStatus: {
        ERROR: 'ERROR',
        INVALID_REQUEST: 'INVALID_REQUEST',
        OK: 'OK',
        OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
        REQUEST_DENIED: 'REQUEST_DENIED',
        UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        ZERO_RESULTS: 'ZERO_RESULTS'
      }
    }
  };
  global.window.google = google;
};
