import fonts, { fontFamily } from 'styles/theme/fonts';
import { LIGHT_SOY, MEDIUM_SOY, SOY, WHITE } from 'styles/theme/colors';

const HEADING_LINE_HEIGHT = 1.25;

const headingStyles = {
  fontFamily,
  fontWeight: fonts.medium,
  fontSize: 20,
  letterSpacing: 0.25,
  lineHeight: HEADING_LINE_HEIGHT,
  textTransform: 'none',
  color: SOY
};

const typography = {
  fontFamily: `${fontFamily}, Helvetica Neue, Arial, sans-serif`,
  fontWeight: fonts.regular,
  body1: {
    fontSize: 14,
    color: MEDIUM_SOY
  },
  body2: { color: LIGHT_SOY },
  caption: {
    fontSize: 12
  },
  h1: headingStyles,
  h2: headingStyles,
  h3: {
    color: MEDIUM_SOY,
    textTransform: 'uppercase',
    fontSize: 12,
    textAlign: 'center',
    letterSpacing: 2,
    lineHeight: HEADING_LINE_HEIGHT,
    fontWeight: fonts.medium
  },
  h4: {
    fontSize: 16,
    color: SOY,
    letterSpacing: 0.25,
    fontWeight: fonts.medium
  },
  h5: {
    textTransform: 'uppercase',
    color: MEDIUM_SOY,
    fontSize: 12,
    letterSpacing: 2,
    lineHeight: HEADING_LINE_HEIGHT,
    fontWeight: fonts.medium
  },
  subtitle1: {
    color: WHITE,
    fontSize: 14,
    lineHeight: 1.43
  },
  subtitle2: {
    color: WHITE,
    fontSize: 14,
    marginBottom: '4px',
    lineHeight: 1.43
  }
};

export default typography;
