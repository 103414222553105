export const FETCH_LIST_REQUEST = `FETCH_LIST_REQUEST`;
export const FETCH_LIST_FAILURE = `FETCH_LIST_FAILURE`;
export const FETCH_LIST_SUCCESS = `FETCH_LIST_SUCCESS`;

export const DELETE_REQUEST = `DELETE_REQUEST`;
export const DELETE_FAILURE = `DELETE_FAILURE`;
export const DELETE_SUCCESS = `DELETE_SUCCESS`;

export const REORDER_LIST = `REORDER_LIST`;
export const LIST_ORDER_UPDATE_REQUEST = 'LIST_ORDER_UPDATE_REQUEST';
export const LIST_ORDER_UPDATE_SUCCESS = 'LIST_ORDER_UPDATE_SUCCESS';
export const LIST_ORDER_UPDATE_FAILURE = 'LIST_ORDER_UPDATE_FAILURE';

export const CLONE_REQUEST = `CLONE_REQUEST`;
export const CLONE_SUCCESS = `CLONE_SUCCESS`;
export const CLONE_FAILURE = `CLONE_FAILURE`;

export const CREATE_REQUEST = `CREATE_REQUEST`;
export const CREATE_FAILURE = `CREATE_FAILURE`;
export const CREATE_SUCCESS = `CREATE_SUCCESS`;

export const UPDATE_REQUEST = `UPDATE_REQUEST`;
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';

export const UPDATE_WORKING_COPY = 'UPDATE_WORKING_COPY';
export const UPDATE_WORKING_COPY_NESTED_ITEM =
  'UPDATE_WORKING_COPY_NESTED_ITEM';
export const UPDATE_WORKING_COPY_DYNAMIC_FIELDS =
  'UPDATE_WORKING_COPY_DYNAMIC_FIELDS';
export const RESET_WORKING_COPY = 'RESET_WORKING_COPY';
export const SET_WORKING_COPY = 'SET_WORKING_COPY';
export const RESET_WORKING_COPY_EDITED_STATE =
  'RESET_WORKING_COPY_EDITED_STATE';

export const UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS';

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export const FILTER_CHANGE = 'FILTER_CHANGE';
export const FILTER_RESET = 'FILTER_RESET';
export const ACKNOWLEDGE_FILTER_RESET = 'ACKNOWLEDGE_FILTER_RESET';

export const RESET_FIELD_ERRORS = 'RESET_FIELD_ERRORS';
export const RESET_API_STATE = 'RESET_API_STATE';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL';
export const OPEN_NOTIFICATION_MODAL = 'OPEN_NOTIFICATION_MODAL';

export const SET_FULFILLMENT_OPTIONS = 'SET_FULFILLMENT_OPTIONS';
export const RESET_FULFILLMENT_OPTIONS = 'RESET_FULFILLMENT_OPTIONS';

export const BOOTSTRAP = 'BOOTSTRAP';

export const SETUP_INITIAL_DATA_REQUEST = 'SETUP_INITIAL_DATA_REQUEST';
export const SETUP_INITIAL_DATA_SUCCESS = 'SETUP_INITIAL_DATA_SUCCESS';
export const SETUP_INITIAL_DATA_FAILURE = 'SETUP_INITIAL_DATA_FAILURE';

export const TOGGLE_ALERT_STATUS = 'TOGGLE_ALERT_STATUS';

export const GOOGLE_LIBRARY_LOADED = 'GOOGLE_LIBRARY_LOADED';

export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const RESET_SCROLL_POSITION = 'RESET_SCROLL_POSITION';

export const RESET_SHIPPING_ADDRESS = 'RESET_SHIPPING_ADDRESS';

export const CLOSE_SEARCH_INPUT = 'CLOSE_SEARCH_INPUT';
export const OPEN_SEARCH_INPUT = 'OPEN_SEARCH_INPUT';
