import get from 'lodash/get';
import {
  VALIDATE_CART_REQUEST,
  VALIDATE_CART_SUCCESS,
  VALIDATE_CART_FAILURE
} from 'bento-ordering/cart/constants/actionTypes';

const initialState = {
  cartValidationError: '',
  isValidatingCart: false,
  addressError: false // using this to determine if we should clear cartWorkingCopy address
};

const cartValidation = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_CART_REQUEST:
      return { ...state, isValidatingCart: true, addressError: false };
    case VALIDATE_CART_SUCCESS:
      return {
        ...state,
        ...action.payload,
        cartValidationError: '',
        isValidatingCart: false,
        addressError: false
      };
    case VALIDATE_CART_FAILURE:
      return {
        ...state,
        isValidatingCart: false,
        cartValidationError: get(
          action,
          'payload.response.error',
          'Your cart is not valid. Update time or empty cart to continue.'
        ),
        addressError: get(action, 'payload.response.error', '').includes(
          'update your address'
        )
      };

    default:
      return state;
  }
};

export default cartValidation;
