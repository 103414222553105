import { GOOGLE_LIBRARY_LOADED } from 'bento-ordering/base/actions/actionTypes';

const initialState = {
  google: false
};

const LibraryLoad = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_LIBRARY_LOADED: {
      return {
        ...state,
        google: true
      };
    }
    default: {
      return state;
    }
  }
};

export default LibraryLoad;
