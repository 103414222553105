import byLocationIdReducerFactory from 'bento-ordering/locations/reducers/byLocationIdReducerFactory';
import { LOCATION_THROTTLED_TIMES } from 'bento-ordering/locations/constants/entities';
import { getLocationThrottledTimesActionTypes } from 'bento-ordering/locations/actions/locationThrottledTimes';

const processSuccessPayload = payload => payload.location_throttled_times;

export default byLocationIdReducerFactory(
  LOCATION_THROTTLED_TIMES,
  getLocationThrottledTimesActionTypes().map(actionType => actionType.type),
  processSuccessPayload
);
