// BASE
export const CATERING_PATH = '/catering-store-v2';

// LOCATION
export const LOCATIONS_PATH = '/';
export const LOCATION_OFFLINE_PATH = '/offline';

// MENU
export const MENU_PATH = '/:locationSlug/menu';
export const MENU_ITEM_DETAIL_PATH = `${MENU_PATH}/:menuItemSlug`;
export const MENU_ITEM_UPSELL_DETAIL_PATH = `${MENU_PATH}/upsell/:menuItemSlug`;

// ORDER FULFILLMENT SETTINGS
export const ORDER_FULFILLMENT_SETTINGS_PATH = `${MENU_PATH}/order-settings`;

// CART
export const CART_PATH = `${MENU_PATH}/cart`;
export const CART_ITEM_PATH = `${MENU_PATH}/cart/:cartItemId`;
