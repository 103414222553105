/**
 * This helper will log an error to sentry with given subject.
 *
 * Optionally, if additional data is provided, a log entry
 * will be added to DataDog.
 *
 * @param subject {string}  error message
 * @param data    {*}       optional additional information to be logged
 */
export default (subject, data = null, stack) => {
  console.error(subject, stack);
  if (data !== null) {
    console.warn(`${subject} - Details:`, data, stack);
  }
};
