import { every } from 'lodash';
import { KNOWN_ERROR_CODES, KNOWN_ERROR_MESSAGES } from 'logging/constants';

/**
 * @param {string} message
 * @returns {boolean}
 */
const isKnownErrorMessage = message => {
  return !!KNOWN_ERROR_MESSAGES.find(knownErrorMessage => {
    const substrings = Object.values(knownErrorMessage);
    return every(substrings, substring => message.includes(substring));
  });
};

export default error => {
  const { code, message } = error;

  const stringMessage =
    typeof message === 'object' ? JSON.stringify(message) : String(message);

  return !(
    (code && KNOWN_ERROR_CODES.includes(code)) ||
    (message && isKnownErrorMessage(stringMessage))
  );
};
