const defaultProps = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiList: {
    defaultProps: {
      disablePadding: true
    }
  },
  MuiListItem: {
    defaultProps: {
      disableGutters: true
    }
  }
};

export default defaultProps;
