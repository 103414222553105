import { SET_DOES_TOTAL_EXCEED_CART_MAXIMUM } from 'bento-ordering/cart/constants/actionTypes';

const initialState = {
  isMaximumExceeded: false
};

const orderAmountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOES_TOTAL_EXCEED_CART_MAXIMUM:
      return {
        ...state,
        isMaximumExceeded: action.payload
      };
    default:
      return state;
  }
};

export default orderAmountReducer;
