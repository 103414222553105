import get from 'lodash/get';

import {
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  BOOTSTRAP
} from 'bento-ordering/base/actions/actionTypes';

export const baseApiState = {
  isFetching: false,
  isSaving: false,
  isError: false,
  errors: {},
  lastFetched: 0
};

const singleEntityReducer = (entity, initialState = {}) => {
  return (state = { ...baseApiState, ...initialState }, action) => {
    switch (action.type) {
      case `[${entity}] ${FETCH_REQUEST}`: {
        return {
          ...state,
          error: '',
          isFetching: true,
          isError: false
        };
      }

      case `[${entity}] ${FETCH_SUCCESS}`: {
        return {
          ...state,
          ...action.payload,
          isFetching: false,
          isError: false,
          lastFetched: action.meta.lastFetched
        };
      }

      case `[${entity}] ${FETCH_FAILURE}`: {
        return {
          ...state,
          isFetching: false,
          isError: true,
          error: get(action, 'payload.response.error')
        };
      }
      case `[${entity}] ${BOOTSTRAP}`: {
        return {
          ...state,
          ...action.payload,
          isFetching: false,
          isError: false,
          lastFetched: action.meta.lastFetched
        };
      }
      default:
        return state;
    }
  };
};

export default singleEntityReducer;
