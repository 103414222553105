import reduceReducers from 'reduce-reducers';

import { BRAND as ENTITY } from 'bento-ordering/brand/constants/entities';
import setupDataFactory from 'bento-ordering/base/reducers/setupDataFactory';
import singleEntity from 'bento-ordering/base/reducers/singleEntity';

const initialState = {
  logos: [],
  fonts: [],
  styles: {
    base: {},
    styles: {}
  }
};

const apiReducer = singleEntity(ENTITY, initialState);
const reducers = reduceReducers(apiReducer, setupDataFactory(ENTITY));

export default reducers;
