import moment from 'moment';
import getNowWithPrepForLocation from 'bento-ordering/locations/utils/getNowWithPrep';

import { getCartMinimumSelector } from 'bento-ordering/account/reducers';

import { fulfillmentOptions } from 'constants/fulfillmentOptions';
import getAsapLabel, {
  getAsapLabelRange
} from 'bento-ordering/base/utils/getAsapLabel';
import { getTime } from 'bento-ordering/base/utils/getTime';
import { getWeekday } from 'bento-ordering/base/utils/dates';
import { getItemByIdSelector } from 'bento-ordering/base/reducers/bentoApi';
import { getNextAvailableTimeFromThrottlesSelector } from 'bento-ordering/locations/selectors/locationThrottledTimes';

/* ======= SELECTORS ===== */

/**
 * determine if fulfillment options for asap/later and date and time are set
 * @param {{}} cart - state from redux
 * @return {boolean}
 */
export const areFulfillmentOptionSetSelector = cart =>
  cart.requested_fulfillment_is_asap === true ||
  (cart.requested_fulfillment_is_asap === false &&
    cart.fulfillment_date &&
    cart.fulfillment_time);

/**
 * determine if subtotal is below order min
 * @param {{}} cart - state from redux
 * @return {boolean}
 */
export const isSubtotalBelowMinimumSelector = cart => {
  const orderMinimum = getCartMinimumSelector(cart);
  return cart.subtotal < orderMinimum;
};

/**
 * determine if cart is delivery with no address
 * @param {{}} cart - state from redux
 * @return {boolean}
 */
export const isDeliveryWithNoAddressSelector = cart => {
  return (
    cart.online_ordering_fulfillment_method === fulfillmentOptions.DELIVERY &&
    !cart.shipping_street_address_1
  );
};

/**
 * determine if cart for delivery is valid
 * if it's delivery, is there an address? does not check whether the address is deliverable
 * @param {{}} cart - state from redux
 * @return {boolean}
 */
export const areFulfillmentDeliveryOptionsSetSelector = cart => {
  if (cart.online_ordering_fulfillment_method) {
    return cart.online_ordering_fulfillment_method ===
      fulfillmentOptions.DELIVERY
      ? !!cart.shipping_street_address_1
      : true;
  }
};
/**
 * get the label we use to display next to Asap
 * @param {{}} param
 * @param {{}} param.cart - or cartWorkingCopy state from redux
 * @param {{}} param.restaurantLocations - restaurantLocations state from redux
 * @param {{}} param.locationThrottledTimes - locationThrottledTimes state from redux
 * @return {string} - indicates Asap and estimated fulfillment time (with a range of minutes for delivery and just minutes for asap)
 */
export const getAsapLabelSelector = ({
  cart,
  restaurantLocations,
  locationThrottledTimes
}) => {
  const {
    estimatedAsapDeliveryRange,
    estimatedAsapPickupTime
  } = cartSelectorModule.getThrottledAdjustedAsapEstimate({
    cart,
    restaurantLocations,
    locationThrottledTimes
  });
  return cart.online_ordering_fulfillment_method === fulfillmentOptions.DELIVERY
    ? getAsapLabelRange(estimatedAsapDeliveryRange)
    : getAsapLabel(estimatedAsapPickupTime);
};

/**
 * get estimates that take into account throttles for asap delivery
 * and asap pickup
 * @param {{}} param
 * @param {{}} param.cart - or cartWorkingCopy state from redux
 * @param {{}} param.restaurantLocations - restaurantLocations state from redux
 * @param {{}} param.locationThrottledTimes - locationThrottledTimes state from redux
 * @return {{}} deliveryEstimates - provides delivery and asap
 * @return {number[]} deliveryEstimates.estimatedAsapDeliveryRange
 * - adjusted delivery minutes range (2 member array [min, max] if range exists on cart, otherwise empty array)
 * @return {number} deliveryEstimates.estimatedAsapPickupTime - provides pickup minutes
 */
export const getThrottledAdjustedAsapEstimate = ({
  cart,
  restaurantLocations,
  locationThrottledTimes
}) => {
  const onlineOrderingLocation = cart.online_ordering_location;
  const location = getItemByIdSelector(
    restaurantLocations,
    onlineOrderingLocation
  );
  const nowWithPrep = getNowWithPrepForLocation(location);

  const nextAvailableTime = getNextAvailableTimeFromThrottlesSelector({
    onlineOrderingLocation: cart.online_ordering_location,
    dateTime: nowWithPrep,
    locationThrottledTimes
  });
  const differenceBetweenNextAvailableAndNormalPrep = moment
    .duration(moment(nextAvailableTime).diff(nowWithPrep))
    .get('minutes');
  return {
    estimatedAsapDeliveryRange: (
      cart.online_ordering_estimated_asap_delivery_range || []
    ).map(time => time + differenceBetweenNextAvailableAndNormalPrep),
    estimatedAsapPickupTime:
      cart.online_ordering_estimated_asap_pickup_time +
      differenceBetweenNextAvailableAndNormalPrep
  };
};

/**
 * get the label for later time or asap
 * @param {{}} param
 * @param {{}} param.cart - or cartWorkingCopy state from redux
 * @param {{}} param.restaurantLocations - restaurantLocations state from redux
 * @param {{}} param.locationThrottledTimes - locationThrottledTimes state from redux
 * @return {string} Asap label 'ASAP (30 minutes)` or `ASAP (20 - 30 minutes)`
 */
export const getFulfillmentTimeOrASAPDisplaySelector = ({
  cart,
  restaurantLocations,
  locationThrottledTimes
}) => {
  const { fulfillment_time, fulfillment_date } = cart;

  if (fulfillment_time) {
    const selectedLocation = getItemByIdSelector(
      restaurantLocations,
      cart.online_ordering_location
    );

    const time = getTime(fulfillment_time);
    const day = getWeekday(fulfillment_date);
    const shouldDisplayDay = !moment(fulfillment_date).isSame(
      selectedLocation.today_date
    );
    return `${shouldDisplayDay ? `${day} ` : ''}${time.toLowerCase()}`;
  }

  return cartSelectorModule.getAsapLabelSelector({
    cart,
    restaurantLocations,
    locationThrottledTimes
  });
};

/**
 * get the label for later time or asap
 * @param {{}} cart - cart state from redux
 * @param {number} cartItemId

 * @return {({}|undefined)} cart item or undefined if not found
 */
export const getCartItemByIdSelector = (cart, cartItemId) => {
  return cart.items.find(item => item.id === cartItemId);
};

/**
 * get the label for later time or asap
 * @param {{}} cart - cart state from redux
 * @param {number} productId
 * @return {({}|undefined)} cart item or undefined if not found
 */
export const getCartItemsByProductSelector = (cart, productId) => {
  return cart.items?.filter(item => item.product === productId);
};

/**
 * util that returns sum of quantities for all cart items passed in
 * @param {[]} cartItems
 * @return {number}
 */
const getTotalQtyForCartItems = cartItems => {
  return cartItems.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);
};

/**
 * quantity of product in cart (can be spread across multiple cart items)
 * @param {{}} cart - cart state from redux
 * @param {number} productId
 * @return {number}
 */
export const getQuantityOfProductInCart = (cart, productId) => {
  const cartItems = getCartItemsByProductSelector(cart, productId);
  const qtyInCart = getTotalQtyForCartItems(cartItems);

  return qtyInCart;
};

export const selectCartGroupOrder = state => {
  return state.cart?.group_order ?? {};
};

export const selectCartGroupOrderSpendingLimit = state => {
  return state.cart?.group_order?.per_person_spending_limit ?? '';
};

export const selectCartGroupOrderAdminName = state => {
  return state.cart?.group_order?.admin_name ?? '';
};

export const selectCartGroupOrderDeadline = state => {
  return state.cart?.group_order?.ordering_deadline ?? null;
};

export const selectCartGroupOrderSlug = state => {
  return state.cart?.group_order?.slug ?? '';
};

export const selectCartGroupOrderCartClosed = state => {
  return state.cart?.group_order?.cart_closed ?? false;
};

export const selectIsGroupOrderCurrentDinerDoneOrdering = state => {
  return state.cart?.group_order?.current_diner?.done_ordering ?? false;
};

export const selectGroupOrderCurrentDinerUUID = state => {
  return state.cart?.group_order?.current_diner?.uuid ?? '';
};

export const selectCurentDinerAmountSpent = state => {
  return state.cart?.group_order?.current_diner?.amount_spent;
};

export const selectCartGroupOrderIsMaxGuestsReached = state => {
  return state.cart?.group_order?.is_max_guests_reached ?? false;
};

/**
 * Returns an object with two properties: adminDiner and guestDiners
 * The adminDiner will contain either the admin info or an empty object
 * if there is none.
 * The guestDiners will contain an array with all the guest diners
 * or an empty array if there is none.
 *
 * @param {*} groupOrderDiners an array of the group order diners
 * @returns an object with two properties: adminDiner and guestDiners
 */
export const getAdminAndGuestDiners = state => {
  const groupOrderDiners = state.cart?.group_order?.diners ?? [];

  let adminDiner = {};
  const guestDiners = [];

  for (let index = 0; index < groupOrderDiners.length; index++) {
    const currentDiner = groupOrderDiners[index];

    if (currentDiner?.admin) {
      adminDiner = currentDiner;
    } else {
      guestDiners.push(currentDiner);
    }
  }

  return { adminDiner, guestDiners };
};

/**
 * total quantity of items in cart
 * @param {{}} cart - cart state from redux
 * @return {number}
 */
export const getTotalCartItemQuantities = cart => {
  return getTotalQtyForCartItems(cart.items);
};

export const selectCartSubtotal = state => {
  return state.cart?.subtotal;
};

export const selectCartSlug = state => {
  return state.cart?.slug;
};

export const selectCartItems = state => {
  return state.cart?.items ?? [];
};

export const selectCartQuantity = state => {
  return state.cart?.items?.length ?? 0;
};

export const selectCartIsGroupOrder = state => {
  return state.cart?.is_group_order;
};

export const selectIsGroupOrderCurrentDinerAdmin = state => {
  return state.cart?.group_order?.current_diner?.admin ?? false;
};

export const selectPricePreview = state => {
  return state.productPreview?.final_price;
};

export const selectSpendingLimitPresets = state => {
  return state.cart?.group_ordering_spending_limits;
};

export const selectGroupOrderingMaxDiners = state => {
  return state.cart?.group_ordering_person_maximum;
};

export const selectIsGroupOrderingEnabled = state => {
  return state.cart?.group_ordering_enabled;
};

export const selectCheckoutUrl = state => {
  return state.cart?.checkout_url ?? '';
};

export const selectGroupOrderError = state => {
  return state.cart?.isError ? state.cart.groupOrderError : null;
};

export const selectGroupOrderErrorIs404 = state => {
  // 404 is group order not found
  const error = state.cart?.isError && state.cart?.groupOrderError;
  return error?.code === 404;
};

export const selectGroupOrderErrorIs1006 = state => {
  // 1006 is web socket close abnormal
  const error = state.cart?.isError && state.cart?.groupOrderError;
  return error?.code === 1006;
};

export const selectIsFetchingAddToCart = state =>
  !!state.cart?.isFetchingAddToCart;

const cartSelectorModule = {
  areFulfillmentOptionSetSelector,
  isSubtotalBelowMinimumSelector,
  isDeliveryWithNoAddressSelector,
  areFulfillmentDeliveryOptionsSetSelector,
  getAsapLabelSelector,
  getThrottledAdjustedAsapEstimate,
  getFulfillmentTimeOrASAPDisplaySelector,
  getCartItemByIdSelector,
  getCartItemsByProductSelector,
  getQuantityOfProductInCart,
  getTotalCartItemQuantities,
  selectCartSlug,
  selectCartItems,
  selectCartQuantity,
  selectCartIsGroupOrder,
  selectCartGroupOrder,
  selectIsGroupOrderCurrentDinerAdmin,
  selectCartGroupOrderSpendingLimit,
  selectCartGroupOrderAdminName,
  selectCartGroupOrderDeadline,
  selectCartGroupOrderSlug,
  selectCartGroupOrderCartClosed,
  selectIsGroupOrderCurrentDinerDoneOrdering,
  selectCartGroupOrderIsMaxGuestsReached,
  selectCartSubtotal,
  selectPricePreview,
  selectSpendingLimitPresets,
  selectGroupOrderingMaxDiners,
  selectIsGroupOrderingEnabled,
  getAdminAndGuestDiners,
  selectGroupOrderCurrentDinerUUID,
  selectCheckoutUrl,
  selectGroupOrderError,
  selectGroupOrderErrorIs404,
  selectGroupOrderErrorIs1006,
  selectCurentDinerAmountSpent,
  selectIsFetchingAddToCart
};

export default cartSelectorModule;
