import moment from 'moment';
import {
  DATE_INPUT_FORMAT,
  DAY_OUTPUT_FORMAT
} from 'bento-ordering/base/constants/timeFormat';

export const isToday = (dateString, selectedToday = null) => {
  const today = selectedToday
    ? moment(selectedToday, DATE_INPUT_FORMAT)
    : moment();

  const givenDate = moment(dateString, DATE_INPUT_FORMAT);
  return today.isSame(givenDate, 'day');
};

/**
 * return a list of date objects as list of date strings
 */
export const getDateObjectsAsString = dates => {
  return dates.map(day => {
    return day.value;
  });
};

/**
 * Returns day of the week text value.
 *
 * @param   {string}  date Format: YYYY-MM-DD
 * @returns {string}  Return formatted day. Format:ddd
 */
export const getWeekday = (date, format = DAY_OUTPUT_FORMAT) => {
  return moment(date, DATE_INPUT_FORMAT).format(format);
};

/**
 * Returns formatted day of the week.
 *
 * If date is today, return "Today". Else, returns day of the week.
 *
 * @param   {string} date
 * @returns {string} Format: Today or formatted date
 */
export const getFormattedDayOfWeek = (date, format = DAY_OUTPUT_FORMAT) => {
  return isToday(date) ? 'Today' : getWeekday(date, format);
};
