/**
 * Corresponds to the CartErrorEnum on bentobox repo.
 */
const cartErrorEnum = {
  CART_IS_EMPTY: 'CART_IS_EMPTY',
  ADDRESS_VALIDATION_ERROR: 'ADDRESS_VALIDATION_ERROR',
  BLOCKED_BY_ORDER_PACING: 'ORDER_PACING',
  CLOSED_LOCATION: 'CLOSED_LOCATION',
  NOT_ACCEPTING_ORDERS: 'NOT_ACCEPTING_ORDERS',
  DELETED_MENU: 'DELETED_MENU',
  ITEM_MINIMUM_NOT_MET: 'ITEM_MINIMUM_NOT_MET',
  DIGITAL_GIFT_CARD_ERROR: 'DIGITAL_GIFT_CARD_ERROR',
  DIGITAL_GIFT_CARD_PAYMENT_ERROR: 'DIGITAL_GIFT_CARD_PAYMENT_ERROR',
  DOORDASH_VALIDATION_ERROR: 'DOORDASH_VALIDATION_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_FULFILLMENT_TIME: 'INVALID_FULFILLMENT_TIME',
  INVALID_TIP_AMOUNT_ERROR: 'INVALID_TIP_AMOUNT_ERROR',
  ITEM_NOT_ON_MENU: 'ITEM_NOT_ON_MENU',
  ITEMS_UNAVAILABLE: 'ITEMS_UNAVAILABLE',
  LOW_INVENTORY: 'LOW_INVENTORY',
  NO_BALANCE_REMAINING_DIGITAL_GIFT_CARD_ERROR:
    'NO_BALANCE_REMAINING_DIGITAL_GIFT_CARD_ERROR',
  NO_ITEMS: 'NO_ITEMS',
  ORDER_ALREADY_PLACED: 'ORDER_ALREADY_PLACED',
  ORDER_MINIMUM_NOT_MET: 'ORDER_MINIMUM_NOT_MET',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PICKUP_ADDRESS_CHANGED: 'PICKUP_ADDRESS_CHANGED'
};

export const errorTypes = {
  ...cartErrorEnum,
  GENERIC_ERROR: 'GENERIC_ERROR',
  REQUEST_ABORTED: 'ECONNABORTED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};

/**
 * Corresponds to the errors not included in any specific enum class.
 */
export const standaloneErrorMessages = {
  ITEM_NOT_AVAILABLE_AT_THIS_TIME: 'This item is not available at this time.',
  CART_COULD_NOT_BE_FOUND: {
    start: 'Catering Cart with slug ',
    end: 'could not be found.'
  },
  COULD_NOT_ADD_ITEM_SOLD_OUT_INVENTORY: {
    start: 'Sorry, that item is sold out. We could not add',
    end: 'to your cart as it is currently sold out.'
  },
  COULD_NOT_ADD_ITEM_WOULD_EXCEED_INVENTORY: {
    start: 'Sorry, that item only had',
    end: 'of that item to your cart.'
  }
};
