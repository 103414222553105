import findKey from 'lodash/findKey';
import get from 'lodash/get';

import { MENU_ITEMS_DETAIL as entity } from 'bento-ordering/menus/constants/entities';
import bentoApi, {
  getItemByIdSelector
} from 'bento-ordering/base/reducers/bentoApi';

const apiReducer = bentoApi(entity);
export default apiReducer;

/* ==== SELECTORS ==== */

export const getMenuItemImage = state => get(state.images, '[0]', {});

export const getMenuItem = (state, menuItemSlug) => {
  const { menuItemsDetail } = state;

  const foundId = findKey(menuItemsDetail.byId, value => {
    return value.slug === menuItemSlug;
  });

  const menuItem = getItemByIdSelector(menuItemsDetail, foundId);

  return menuItem;
};

export const getMenuItemByProductId = (state, id) => {
  const menuItem = getItemByIdSelector(state, id);
  return menuItem;
};

export const getMenuEditItemByProductId = (state, id) => {
  const items = state.cart.items;
  const item = items.filter(item => item.id.toString() === id);
  return item[0];
};

export const getIsDoneFetchingMenuItemsDetail = state => {
  const { menuItemsDetail } = state;
  return !menuItemsDetail.isFetching && menuItemsDetail.lastFetched > 0;
};
