import {
  CLOSE_NOTIFICATION_MODAL,
  OPEN_NOTIFICATION_MODAL
} from 'bento-ordering/base/actions/actionTypes';

const initialState = {
  notificationType: null
};

const notificationModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION_MODAL:
      return {
        ...state,
        notificationType: action.payload.notificationType,
        modalProps: action.payload.modalProps || {}
      };

    case CLOSE_NOTIFICATION_MODAL:
      return {
        ...state,
        notificationType: null,
        modalProps: {}
      };
  }

  return state;
};

export default notificationModal;
