import {
  OPEN_SEARCH_INPUT,
  CLOSE_SEARCH_INPUT
} from 'bento-ordering/base/actions/actionTypes';

const intialState = {
  isOpen: false
};

const searchInput = (state = intialState, action = {}) => {
  switch (action.type) {
    case OPEN_SEARCH_INPUT:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE_SEARCH_INPUT:
      return {
        ...state,
        isOpen: false
      };
  }

  return state;
};

export default searchInput;
