import get from 'lodash/get';
import moment from 'moment';
import { fulfillmentOptions } from 'constants/fulfillmentOptions';
import {
  DATE_INPUT_FORMAT,
  DATE_TIME_FORMAT,
  MILITARY_TIME
} from 'bento-ordering/base/constants/timeFormat';
import { getNextAvailableTimeFromThrottlesSelector } from 'bento-ordering/locations/selectors/locationThrottledTimes';
import {
  combineDateWithCloseTime,
  getNearestDatetimeIntervalAfter
} from 'bento-ordering/base/utils/timeHelpers';
import getDeliveryPrepByTimeForThrottles from 'bento-ordering/locations/utils/getDeliveryPrepByTimeForThrottles';
/**
 * get the first location menu given throttles
 * recursive function
 * @param {Object} params
 * @param {number} params.selectedLocationId
 * @param {{}[]} params.locationMenus
 * @param {{}} params.locationThrottledTimes
 * @param {{}} params.cart
 *
 * @return {{date: string, open_time: string, close_time: string, menu_id: number}}
 * @return {null} if no location menus
 */
const getFirstLocationMenu = ({
  selectedLocationId,
  locationMenus,
  locationThrottledTimes,
  fulfillmentMethod,
  cart
}) => {
  if (!locationMenus.length) {
    return null;
  }

  const firstAvailableLocationMenu = locationMenus[0];
  const firstAvailableDate = get(firstAvailableLocationMenu, 'date');
  const firstAvailableHour = get(
    firstAvailableLocationMenu,
    'hour_ranges[0].open_time'
  );
  const firstCloseTime = get(
    firstAvailableLocationMenu,
    'hour_ranges[0].close_time'
  );

  let preparedByTime = `${firstAvailableDate}T${firstAvailableHour}`;
  const firstCloseDateTime = combineDateWithCloseTime(
    firstAvailableDate,
    firstCloseTime
  );

  if (fulfillmentMethod === fulfillmentOptions.DELIVERY) {
    preparedByTime = getDeliveryPrepByTimeForThrottles(
      preparedByTime,
      cart.total_delivery_time
    );
  }

  const nextAvailableTime = getNextAvailableTimeFromThrottlesSelector({
    onlineOrderingLocation: selectedLocationId,
    locationThrottledTimes,
    dateTime: preparedByTime
  });

  let nextAvailableTimeMoment = moment(nextAvailableTime, DATE_TIME_FORMAT);
  // have to add back delivery time for delivery to get fulfillment time
  if (fulfillmentMethod === fulfillmentOptions.DELIVERY) {
    nextAvailableTimeMoment = nextAvailableTimeMoment.add(
      cart.total_delivery_time,
      'minutes'
    );
    nextAvailableTimeMoment = moment(
      getNearestDatetimeIntervalAfter(
        nextAvailableTimeMoment.format(DATE_TIME_FORMAT)
      )
    );
  }

  if (
    nextAvailableTimeMoment.isSameOrBefore(
      moment(firstCloseDateTime, DATE_TIME_FORMAT)
    )
  ) {
    return {
      date: nextAvailableTimeMoment.format(DATE_INPUT_FORMAT),
      open_time: nextAvailableTimeMoment.format(MILITARY_TIME),
      close_time: firstCloseTime,
      menu_id: get(firstAvailableLocationMenu, 'hour_ranges[0].menu_id')
    };
  } else {
    const objectForLogging = {
      selectedLocationId,
      locationMenus,
      nextAvailableTimeMoment: nextAvailableTimeMoment.toISOString(),
      firstCloseDateTime,
      cartTotalDeliveryTime: cart.total_delivery_time,
      locationThrottledTimes
    };
    const nextLocationMenus = _getNextLocationMenus(
      locationMenus,
      objectForLogging
    );
    return getFirstLocationMenu({
      selectedLocationId,
      locationMenus: nextLocationMenus,
      locationThrottledTimes,
      fulfillmentMethod,
      cart
    });
  }
};
/**
 * removes the first location menu hour range or the first date if that date
 * has no hour ranges left after removing the first one
 * @private
 * @param {{}[]} locationMenus - {date: '2019-05-03', hour_ranges:[{open_time:'08:00', close_time: '20:00' menu_id:3}]}
 * @return {{}[]} - trimmed location menus (either removes the first location menu or the first range from the first location menu)
 *
 * @throws Error if no more location menus
 */
const _getNextLocationMenus = (locationMenus, objectForLogging) => {
  if (!locationMenus.length) {
    throw Error('No location menus left to find');
  }
  const firstAvailableLocationMenu = locationMenus[0];
  const firstLocationMenuHourRanges = firstAvailableLocationMenu.hour_ranges;
  const remainingHourRanges = firstLocationMenuHourRanges.slice(1);
  const remainingLocationMenus = locationMenus.slice(1);

  // if there are hour ranges left after removing the first one, then we
  // just return the current locationMenu with first hour range removed
  if (remainingHourRanges.length) {
    return [
      {
        ...locationMenus[0],
        hour_ranges: remainingHourRanges
      },
      ...remainingLocationMenus
    ];
  } else if (remainingLocationMenus.length) {
    // if there are still remainingLocationMenus after removing the first
    // location menu, then we return all locationMenus after the first one
    return remainingLocationMenus;
  } else {
    // if no location menus are left, that means we have exhausted them
    // this is an unlikely scenario, so [] can returned and getFirstLocationMenu
    // will handle it.
    console.warn(
      `Ran out of location menus. Context: ${JSON.stringify(objectForLogging)}`
    );
    return [];
  }
};

export default getFirstLocationMenu;
