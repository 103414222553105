import { BENTO_ORDERING_APP, CATERING_APP } from 'constants/apps';
import isBentoOrderingRoute from 'bento-ordering/base/utils/isBentoOrderingRoute';
import isCateringRoute from 'catering/helpers/isCateringRoute';

class PathNotSupportedException extends Error {
  constructor(message = '', ...args) {
    super(message, ...args);
    this.name = 'PathNotSupportedException';
    this.message = `${message} is not a supported path`;
  }
}

const getAppType = () => {
  // ssr check
  if (!window) {
    return null;
  }
  const pathname = window.location.pathname;
  if (isCateringRoute()) {
    return CATERING_APP;
  } else if (isBentoOrderingRoute()) {
    return BENTO_ORDERING_APP;
  } else {
    throw new PathNotSupportedException(pathname);
  }
};

export default getAppType;
