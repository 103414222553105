import getApplicationVariant, {
  APPLICATION_VARIANTS
} from 'bento-ordering/utils/getApplicationVariant';
import {
  fulfillmentOptions,
  ALL_ONLINE_ORDERING_FULFILLMENT_METHODS
} from 'constants/fulfillmentOptions';

/**
 * Last set of fulfillment methods retrieved
 * @type {array}
 * @private
 */
let _fulfillmentMethods = null;

/**
 * Fulfillment methods for current application variant
 *
 * @param {boolean}   cache use cached values
 * @return {(string)[]}
 */
export const getFulfillmentMethods = (cache = true) => {
  if (cache && _fulfillmentMethods !== null) {
    return _fulfillmentMethods;
  }

  switch (getApplicationVariant()) {
    case APPLICATION_VARIANTS.DINE_IN:
      _fulfillmentMethods = [fulfillmentOptions.DINE_IN];
      break;
    case APPLICATION_VARIANTS.ONLINE_ORDERING:
    default:
      _fulfillmentMethods = ALL_ONLINE_ORDERING_FULFILLMENT_METHODS;
      break;
  }

  return _fulfillmentMethods;
};
