import moment from 'moment';
import { DATE_TIME_FORMAT } from 'bento-ordering/base/constants/timeFormat';

const isLocationMenuExpired = (closeDateTime, fulfillmentTime) => {
  if (!closeDateTime || !fulfillmentTime) {
    return false;
  }
  const expireDateTime = moment(closeDateTime, DATE_TIME_FORMAT);
  const fulfillmentDateTime = moment(fulfillmentTime, DATE_TIME_FORMAT);

  return expireDateTime.isBefore(fulfillmentDateTime);
};

export default isLocationMenuExpired;
