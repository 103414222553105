export const BASE_APPLICATION_FONT_URL =
  'https://media-cdn.getbento.com/fonts/circular/';

export const FONT_FORMATS = {
  EMBEDDED_OPENTYPE: 'embedded-opentype',
  OPENTYPE: 'opentype',
  SVG: 'svg',
  TRUETYPE: 'truetype',
  WOFF: 'woff',
  WOFF2: 'woff2'
};
