import getBasePath from 'bento-ordering/utils/getBasePath';
import {
  ONLINE_ORDERING_BASE_PATH,
  DINE_IN_BASE_PATH
} from 'bento-ordering/base/constants/basePaths';

export const APPLICATION_VARIANTS = {
  ONLINE_ORDERING: 'online-ordering',
  DINE_IN: 'dine-in',
  NONE: ''
};

let _applicationVariant = null;
export default (useCache = true) => {
  if (useCache && _applicationVariant !== null) {
    return _applicationVariant;
  }

  switch (getBasePath()) {
    case ONLINE_ORDERING_BASE_PATH:
      _applicationVariant = APPLICATION_VARIANTS.ONLINE_ORDERING;
      break;
    case DINE_IN_BASE_PATH:
      _applicationVariant = APPLICATION_VARIANTS.DINE_IN;
      break;
    default:
      _applicationVariant = APPLICATION_VARIANTS.NONE;
  }

  return _applicationVariant;
};
