import get from 'lodash/get';
import update from 'immutability-helper';
import {
  RELATE_LOCATIONS_TO_CART_REQUEST,
  RELATE_LOCATIONS_TO_CART_SUCCESS,
  RELATE_LOCATIONS_TO_CART_FAILURE
} from 'bento-ordering/locations/constants/actionTypes';

export const relateAddressToLocationsInitialState = {
  isSettingAddress: false,
  error: []
};
const relateAddressToLocationsFactory = (
  entity,
  processPayload = payload => ({ $merge: payload })
) => (state = relateAddressToLocationsInitialState, action) => {
  switch (action.type) {
    case RELATE_LOCATIONS_TO_CART_REQUEST: {
      return {
        ...state,
        isSettingAddress: true,
        error: []
      };
    }
    case RELATE_LOCATIONS_TO_CART_SUCCESS: {
      let updateObj = processPayload(
        action.payload[entity],
        action.payload.googlePlaceId
      );
      // this is using immutability helper. 2 cases here:
      // 1) is this the default processPayload, in which case an object is returned with the top level key of $merge? if so we want to reset the defaults (for loading etc.).

      // 2) if it's a custom processPayload, then we want to call merge for the initialState (to reset for loading, errors) and then the updateObject has the correct helper key defined deep down in the object ... something like {byId:{1: {$merge: {someKey: 'hello!'}}}
      if (updateObj.$merge) {
        updateObj = {
          $merge: {
            ...updateObj.$merge,
            ...relateAddressToLocationsInitialState
          }
        };
      } else {
        updateObj = {
          $merge: relateAddressToLocationsInitialState,
          ...updateObj
        };
      }

      return update(state, updateObj);
    }
    case RELATE_LOCATIONS_TO_CART_FAILURE: {
      return {
        ...state,
        isSettingAddress: false,
        error: [get(action, ['payload', 'message'])]
      };
    }
    default:
      return state;
  }
};

export default relateAddressToLocationsFactory;
