export const SORT_LOCATIONS = 'SORT_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';

export const LOCATION_HOURS_AVAILABLE_REQUEST =
  'LOCATION_HOURS_AVAILABLE_REQUEST';
export const LOCATION_HOURS_AVAILABLE_SUCCESS =
  'LOCATION_HOURS_AVAILABLE_SUCCESS';
export const LOCATION_HOURS_AVAILABLE_FAILURE =
  'LOCATION_HOURS_AVAILABLE_FAILURE';

export const LOCATION_MENU_AVAILABLE_REQUEST =
  'LOCATION_MENU_AVAILABLE_REQUEST';
export const LOCATION_MENU_AVAILABLE_SUCCESS =
  'LOCATION_MENU_AVAILABLE_SUCCESS';
export const LOCATION_MENU_AVAILABLE_FAILURE =
  'LOCATION_MENU_AVAILABLE_FAILURE';

export const LOCATION_FILTERED_HOURS_REQUEST =
  'LOCATION_FILTERED_HOURS_REQUEST';
export const LOCATION_FILTERED_HOURS_SUCCESS =
  'LOCATION_FILTERED_HOURS_SUCCESS';
export const LOCATION_FILTERED_HOURS_FAILURE =
  'LOCATION_FILTERED_HOURS_FAILURE';

export const VALIDATE_ADDRESS_REQUEST = 'VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_FAILURE = 'VALIDATE_ADDRESS_FAILURE';

export const RELATE_LOCATIONS_TO_CART_REQUEST =
  'RELATE_LOCATIONS_TO_CART_REQUEST';
export const RELATE_LOCATIONS_TO_CART_SUCCESS =
  'RELATE_LOCATIONS_TO_CART_SUCCESS';
export const RELATE_LOCATIONS_TO_CART_FAILURE =
  'RELATE_LOCATIONS_TO_CART_FAILURE';

export const UPDATE_FULFILLMENT_OPTIONS_REQUEST =
  'UPDATE_FULFILLMENT_OPTIONS_REQUEST';
export const UPDATE_FULFILLMENT_OPTIONS_SUCCESS =
  'UPDATE_FULFILLMENT_OPTIONS_SUCCESS';
export const UPDATE_FULFILLMENT_OPTIONS_FAILURE =
  'UPDATE_FULFILLMENT_OPTIONS_FAILURE';

export const FETCH_BY_LOCATION_REQUEST = 'FETCH_BY_LOCATION_REQUEST';
export const FETCH_BY_LOCATION_SUCCESS = 'FETCH_BY_LOCATION_SUCCESS';
export const FETCH_BY_LOCATION_FAILURE = 'FETCH_BY_LOCATION_FAILURE';
