import get from 'lodash/get';
import update from 'immutability-helper';
import {
  VALIDATE_ADDRESS_REQUEST,
  VALIDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS_FAILURE
} from 'bento-ordering/locations/constants/actionTypes';

export const validatingAddressInitialState = {
  isValidatingAddress: false,
  error: []
};
const validateAddressFactory = (
  entity,
  processPayload = payload => ({ $merge: payload })
) => (state = validatingAddressInitialState, action) => {
  switch (action.type) {
    case VALIDATE_ADDRESS_REQUEST: {
      return {
        ...state,
        isValidatingAddress: true,
        error: []
      };
    }
    case VALIDATE_ADDRESS_SUCCESS: {
      let updateObj = processPayload(action.payload[entity]);

      if (updateObj.$merge) {
        updateObj = {
          $merge: { ...updateObj.$merge, ...validatingAddressInitialState }
        };
      } else {
        updateObj = { $merge: validatingAddressInitialState, ...updateObj };
      }

      return update(state, updateObj);
    }
    case VALIDATE_ADDRESS_FAILURE: {
      return {
        ...state,
        isValidatingAddress: false,
        error: [get(action, ['payload', 'message'])]
      };
    }
    default:
      return state;
  }
};

export default validateAddressFactory;
