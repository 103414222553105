import moment from 'moment';
import { DATE_TIME_FORMAT } from 'bento-ordering/base/constants/timeFormat';

/**
 * add a location's prep time to now
 * used for calculating estimated prepared by time
 * @param {{}} location - object from redux state that has current prep time key
 * @return {string} date time, eg: '2019-05-03T13:00'
 */
const getNowWithPrepForLocation = location =>
  moment()
    .add(location.current_prep_time, 'minutes')
    .format(DATE_TIME_FORMAT);

export default getNowWithPrepForLocation;
