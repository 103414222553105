import { TOGGLE_ALERT_STATUS } from 'bento-ordering/base/actions/actionTypes';
import {
  NO_ASAP_FOR_DELIVERY_AND_PICKUP,
  NO_SET_ADDRESS,
  ASAP_NOT_AVAILABLE_FOR_THROTTLES
} from 'bento-ordering/base/constants/orderAlerts';

const initialState = {
  [NO_ASAP_FOR_DELIVERY_AND_PICKUP]: true,
  [ASAP_NOT_AVAILABLE_FOR_THROTTLES]: false,
  [NO_SET_ADDRESS]: false,
  metaData: {}
};

const alertStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ALERT_STATUS:
      return {
        ...state,
        metaData: { ...state.metaData, ...action.payload.metaData },
        [action.payload.alert]: !state[action.payload.alert]
      };
  }
  return state;
};

export default alertStatusReducer;
