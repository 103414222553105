import buildActionTypes from 'bento-ordering/base/utils/buildActionTypes';
import {
  FETCH_BY_LOCATION_REQUEST,
  FETCH_BY_LOCATION_SUCCESS,
  FETCH_BY_LOCATION_FAILURE
} from 'bento-ordering/locations/constants/actionTypes';
/**
 *  build action types for byLocation reducers & actions
 * @param {string} entity
 * @return {function}
 */
const getByLocationActionTypeBuilder = entity => {
  /**
   * @param locationId
   * @return {function} that can be invoked to build actionTypes with meta for location id
   */
  return locationId =>
    buildActionTypes(entity, [
      { type: FETCH_BY_LOCATION_REQUEST },
      { type: FETCH_BY_LOCATION_SUCCESS, meta: { locationId } },
      { type: FETCH_BY_LOCATION_FAILURE }
    ]);
};
export default getByLocationActionTypeBuilder;
