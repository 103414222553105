import moment from 'moment';

import {
  HOUR_MINUTE_DAY_TIME,
  MILITARY_TIME,
  DATE_TIME_FORMAT
} from 'bento-ordering/base/constants/timeFormat';

/**
 * input HH:mm
 * output h:mma
 */
export const getTime = time => {
  return moment(time, MILITARY_TIME).format(HOUR_MINUTE_DAY_TIME);
};

export const getTimeFromDateTime = dateTime => {
  return moment(dateTime, DATE_TIME_FORMAT).format(HOUR_MINUTE_DAY_TIME);
};
