import {
  OPEN_MODAL,
  CLOSE_MODAL
} from 'bento-ordering/base/actions/actionTypes';

const intialState = {
  isOpen: false
};

const modal = (state = intialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false
      };
  }

  return state;
};

export default modal;
