import { PRODUCTION, STAGING, DEVELOPMENT, UNKNOWN } from './constants';

export const getEnvironment = () => {
  // Determine what environment we're in by looking at the URL.

  let environment, host;

  try {
    host = window.location.host;
  } catch (e) {
    host = '';
  }

  if (!host) {
    host = '';
  }

  if (host === '') {
    environment = UNKNOWN;
  } else if (
    ['localtest.me', 'localhost'].some(substring => host.includes(substring))
  ) {
    environment = DEVELOPMENT;
  } else if (host.includes('bentosandbox.com')) {
    environment = STAGING;
  } else {
    // Production is more than just getbento.com since client sites can have their own domain.
    // Therefore, anything that doesn't fall under STAGING, DEVELOPMENT, or UNKNOWN is PRODUCTION.
    environment = PRODUCTION;
  }

  return environment;
};
