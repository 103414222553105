/**
 * build action types by prepending the entity with brackets before each action type and then returns the rest of the actionType object so that other keys used by RSAA can be appended
 * action types should have a type key.
 * @param {string} entity
 * @param {(Object)[]} actionTypes
 * @return {(Object)[]}
 */
const buildActionTypes = (entity, actionTypes) => {
  if (!entity || typeof entity !== 'string') {
    throw Error(
      'must call buildActionTypes with string for entity for first argument'
    );
  }

  if (!Array.isArray(actionTypes) || actionTypes.length !== 3) {
    throw Error(
      'must call buildActionTypes with an array of 3 types for second argument'
    );
  }

  return actionTypes.map(actionType => ({
    ...actionType,
    type: `[${entity}] ${actionType.type}`
  }));
};
export default buildActionTypes;
