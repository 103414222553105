import { createPalette } from 'styles/theme/colors';
import {
  getCustomTypography,
  getCustomPalette,
  getComponentsStyles,
  getGlobalStyles
} from 'styles/theme/designAndCustomization';
import { styleOverrides } from 'styles/theme/overrides';
import typography from 'styles/theme/typography';
import fonts, {
  loadSelfHostedCssFontFaces,
  loadDefaultFonts
} from 'styles/theme/fonts';
import constants from 'styles/theme/constants';
import { createComponents } from 'styles/theme/components';
import defaultProps from 'styles/theme/defaultProps';
import breakpoints from 'styles/theme/breakpoints';

export default {
  constants,
  createPalette,
  getCustomTypography,
  getCustomPalette,
  getComponentsStyles,
  getGlobalStyles,
  fonts,
  typography,
  createComponents,
  styleOverrides,
  defaultProps,
  breakpoints,
  loadSelfHostedCssFontFaces,
  loadDefaultFonts
};
