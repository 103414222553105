import {
  GOOGLE_ANALYTICS_4,
  INITIALIZE_LOCATION_PIXEL,
  SET_REFERRAL_SOURCE,
  SET_UTM_CAMPAIGN
} from 'bento-ordering/base/constants/tracking';

const initialState = {
  ga4Initialized: false,
  initializedPixels: [],
  referralSource: '',
  utmCampaign: ''
};

const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_ANALYTICS_4:
      return {
        ...state,
        ga4Initialized: true
      };
    case INITIALIZE_LOCATION_PIXEL:
      return {
        ...state,
        initializedPixels: [
          ...state.initializedPixels,
          action.payload.locationPixel
        ]
      };
    case SET_REFERRAL_SOURCE:
      return {
        ...state,
        referralSource: action.payload.referralSource
      };
    case SET_UTM_CAMPAIGN:
      return {
        ...state,
        utmCampaign: action.payload.utmCampaign
      };

    default:
      return { ...state };
  }
};

export default trackingReducer;
