import moment from 'moment';
import get from 'lodash/get';

import getApplicationVariant, {
  APPLICATION_VARIANTS
} from 'bento-ordering/utils/getApplicationVariant';
import { fulfillmentOptions } from 'constants/fulfillmentOptions';
import isLocationMenuExpired from 'bento-ordering/menus/utils/isLocationMenuExpired';
import { combineDateWithCloseTime } from 'bento-ordering/base/utils/timeHelpers';

/**
 * Extract location's fulfillment options if available
 *
 * @param   {Object}  location  Online Ordering location details
 * @param   {string}  method    Fulfillment method
 *
 * @returns {{}}  Returns fulfillment details or empty object
 */
const _getFulfillmentOptions = (location = {}, method) => {
  if (!location.fulfillment_options) {
    return {};
  }

  return location.fulfillment_options[method] || {};
};

/**
 * Determines if location is closed for dine-in.
 *
 * dine-in timing of fulfillment will always be asap
 * and orders cannot be placed off hours.
 *
 * @param   {Object}  location Online Ordering location details
 *
 * @returns {boolean} Returns whether location is closed or not
 */
const _isLocationClosedForDineIn = location => {
  if (location.is_closed_for_dine_in) {
    return true;
  }

  const {
    is_asap_available = false,
    asap_date_with_range = {}
  } = _getFulfillmentOptions(location, fulfillmentOptions.DINE_IN);

  if (!is_asap_available) {
    return true;
  }

  const fulfillmentTime = moment().format();
  const closedDateTime = combineDateWithCloseTime(
    asap_date_with_range.date,
    asap_date_with_range.close_time
  );

  return isLocationMenuExpired(closedDateTime, fulfillmentTime);
};

/**
 * reads off a location to see if it is currently accepting orders based off
 * of hours and asap availability
 *
 * @param {object} location taken (from byId in redux state)
 * @param {string} selectedMethod (delivery or pickup)
 * @return {boolean}
 */
export const getIsLocationAcceptingOrdersForMethodSelector = (
  location,
  selectedMethod
) => {
  const optionsForMethod = get(
    location,
    ['fulfillment_options', selectedMethod],
    {}
  );

  const hasLaterOptions = !!get(optionsForMethod, 'datetimes_with_ranges', [])
    .length;

  const asapIsAvailable = get(optionsForMethod, 'is_asap_available', false);

  return hasLaterOptions || asapIsAvailable;
};

/**
 * Determines if location is closed for online ordering.
 *
 * Pickup and delivery allow order placement for fulfillment at a custom time.
 *
 * @param   {Object}  location Online Ordering location details
 *
 * @returns {boolean} Returns whether location is closed or not
 */
const _isLocationClosedForOnlineOrdering = location => {
  const isLocationAcceptingPickupOrders = getIsLocationAcceptingOrdersForMethodSelector(
    location,
    fulfillmentOptions.PICKUP
  );
  const isLocationAcceptingDeliveryOrders = getIsLocationAcceptingOrdersForMethodSelector(
    location,
    fulfillmentOptions.DELIVERY
  );

  return (
    location.is_closed ||
    (!isLocationAcceptingPickupOrders && !isLocationAcceptingDeliveryOrders)
  );
};

/**
 * Determines if location is closed based on application variant.
 *
 * Pickup and delivery allow order placement for fulfillment at a custom time.
 * For dine-in, timing of fulfillment will always be asap
 * and orders cannot be placed off hours.
 *
 * @param   {Object}  location Online Ordering location details
 *
 * @returns {boolean} Returns whether location is closed or not
 */
export default location => {
  switch (getApplicationVariant()) {
    case APPLICATION_VARIANTS.DINE_IN:
      return _isLocationClosedForDineIn(location);
    case APPLICATION_VARIANTS.ONLINE_ORDERING:
    default:
      return _isLocationClosedForOnlineOrdering(location);
  }
};
