import get from 'lodash/get';
import set from 'lodash/set';

import reduceReducers from 'reduce-reducers';
import bentoApi from 'bento-ordering/base/reducers/bentoApi';
import setupDataFactory from 'bento-ordering/base/reducers/setupDataFactory';

import { LOCATION as ENTITY } from 'bento-ordering/locations/constants/entities';
import { formatListResponse } from 'bento-ordering/base/actions/api';

import locationFulfillmentOptions from 'bento-ordering/locations/reducers/locationFulfillmentOptions';
import validateAddressFactory from 'bento-ordering/base/reducers/validateAddressFactory';
import relateAddressToLocationsFactory from 'bento-ordering/base/reducers/relateAddressToLocationsFactory';

/*
  we build an update object using immutability helper keywords here specific to
  locations list

*/
const formatValidateDataResponse = response => {
  // first we get the location referenced in the response
  const listItem = formatListResponse(response);

  // then we get the fulfillment options for that location
  const fulfillmentOptions = get(listItem, [
    'byId',
    listItem.allIds[0],
    'fulfillment_options'
  ]);

  // we store the datetime with ranges
  const datetimesWithRanges = get(
    fulfillmentOptions,
    ['delivery', 'datetimes_with_ranges'],
    []
  );

  // make a copy of list item so we don't mutate anything, also only care
  // about byId key
  const updateObject = {
    byId: listItem.byId
  };

  // make a copy of fulfillmentOptions so we don't mutate anything
  const updatedFulfillmentOptions = {
    ...fulfillmentOptions
  };
  // we set a new $merge key on delivery datetime ranges
  // so that immutability helper knows where to start merging
  // the state tree (we want to merge the individual location's datetimes with ranges for delivery only)
  set(updatedFulfillmentOptions, ['delivery'], {
    $merge: {
      datetimes_with_ranges: datetimesWithRanges
    }
  });
  // then we just set our new updateObject byId to have these new objects
  // we created above
  set(updateObject, ['byId', listItem.allIds[0]], {
    fulfillment_options: updatedFulfillmentOptions
  });

  return updateObject;
};

export const formatRelateLocationsToAddressResponse = (
  response,
  googlePlaceId = ''
) => {
  const apiListFormat = formatListResponse(response);

  // go through all the ids and make update objects using $merge. this will combine the incoming props for this location with what is already on state. the api returns updated fulfillment options, and then distance to address, and whether the address is deliverable from this location
  const updateObject = {
    byId: apiListFormat.allIds.reduce((acc, curr) => {
      acc[curr] = { $merge: apiListFormat.byId[curr] };
      return acc;
    }, {})
  };
  if (googlePlaceId) {
    updateObject.googlePlaceId = { $set: googlePlaceId };
  }
  return updateObject;
};

const reducers = reduceReducers(
  bentoApi(ENTITY),
  validateAddressFactory('locations', formatValidateDataResponse),
  setupDataFactory('locations', formatListResponse),
  relateAddressToLocationsFactory(
    'locations',
    formatRelateLocationsToAddressResponse
  ),
  locationFulfillmentOptions
);

export default reducers;
