import isUndefined from 'lodash/isUndefined';

import {
  GROUP_ORDER_SETTINGS,
  GROUP_ORDER_INVITE,
  GROUP_ORDER_JOIN
} from 'bento-ordering/group_ordering/constants/paths';

const isBlankParam = param => isUndefined(param) || param === '';

/*


  Here is our url structure

  There is 2 root paths for this application `/online-ordering/` and `/dine-in-ordering/`

  It renders the locations page for multi location businesses.

  Then we have 2 categories
  1) location-specific routes
    a) Location detail page /location-slug/
    b) Menu page /location-slug/menu
    c) Order Settings Page /location-slug/menu/order-settings
    d) Menu Item Page /location-slug/menu/menu-item-slug
  2) cart routes
    a) Cart page /cart/
    b) Cart Item page /cart/cart-item-id


  this path does not include the base path, since that's part of the root url

*/

export const getOnlineOrderingUrlParams = path => {
  const urlParams = path.split('/').slice(1);
  const isRoot = isBlankParam(urlParams[0]);
  const hasMenu = urlParams[1] === 'menu';
  const hasCart = urlParams[0] === 'cart';
  const isCartPage = hasCart && isBlankParam(urlParams[1]);
  const isCartItemPage = hasCart && !isBlankParam(urlParams[1]);
  const isOrderSettings = hasMenu && urlParams[2] === 'order-settings';
  const locationSlug =
    urlParams[0] === 'cart' ||
    urlParams[0] === GROUP_ORDER_SETTINGS.slice(1) ||
    urlParams[0] === GROUP_ORDER_INVITE.slice(1) ||
    urlParams[0] === GROUP_ORDER_JOIN.slice(1) ||
    urlParams[0] === ''
      ? undefined
      : urlParams[0];
  const isMenuPage = hasMenu && isBlankParam(urlParams[2]);
  const isMenuItemPage = !!(
    hasMenu &&
    !isOrderSettings &&
    !isBlankParam(urlParams[2])
  );

  let menuItemSlug;
  if (isMenuItemPage && urlParams[2] !== 'upsell') {
    menuItemSlug = urlParams[2];
  } else if (isMenuItemPage && urlParams[3]) {
    menuItemSlug = urlParams[3];
  } else {
    menuItemSlug = undefined;
  }

  const isLocationPage =
    !isBlankParam(locationSlug) &&
    locationSlug !== 'cart' &&
    isBlankParam(urlParams[1]);
  const cartItemId = isCartItemPage ? urlParams[1] : undefined;

  return {
    isMenuPage,
    isLocationPage,
    locationSlug,
    isOrderSettings,
    isMenuItemPage,
    menuItemSlug,
    isCartPage,
    isCartItemPage,
    cartItemId,
    isRoot
  };
};
