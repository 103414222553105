import singleEntity from 'bento-ordering/base/reducers/singleEntity';

import { MENU as ENTITY } from 'bento-ordering/menus/constants/entities';

const apiReducer = singleEntity(ENTITY);

export default apiReducer;

export { default as menuItemsDetail } from 'bento-ordering/menus/reducers/menuItemsDetail';
export { default as productPreview } from 'bento-ordering/menus/reducers/productPreview';
