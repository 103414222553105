// constants regardless of environment

export const APPNAME = 'online-ordering';

export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'development';
export const UNKNOWN = 'unknown';

export const SENTRY_DSN =
  'https://4919c2514a1e46cc9fd83615509f68a6@sentry.io/1513815';
