import get from 'lodash/get';
import { formValueSelector } from 'redux-form';

import {
  GET_PRICE_PREVIEW_REQUEST,
  GET_PRICE_PREVIEW_SUCCESS,
  GET_PRICE_PREVIEW_FAILURE,
  RESET_PRICE_PREVIEW
} from 'bento-ordering/menus/constants/actionTypes';
import { getValueWithTwoDecimalPlace } from 'helpers/formattedDollar';
import { getOnlineOrderingUrlParams } from 'bento-ordering/utils/url';
import {
  getCartItemByIdSelector,
  getQuantityOfProductInCart
} from 'bento-ordering/cart/selectors';
import { getMenuItem } from './menuItemsDetail';

const initialState = {
  base_price: 0,
  final_price: 0,
  addon_price: 0,
  isFetching: false,
  isSaving: false,
  errors: [],
  lastFetched: 0
};

const productPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICE_PREVIEW_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_PRICE_PREVIEW_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    }
    case GET_PRICE_PREVIEW_FAILURE: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors
      };
    }
    case RESET_PRICE_PREVIEW: {
      return {
        ...state,
        isFetching: false,
        ...initialState
      };
    }
    default:
      return state;
  }
};

export default productPreviewReducer;

// === SELECTORS ===

const subtractFromInventory = (inventory, quantity) => {
  if (inventory !== null) {
    return inventory - quantity;
  }
  return null;
};

// selects state from redux form for menu item detail form
// this is redux form's preferred way of accessing form state,
// rather than trying to access from root state directly
const selector = formValueSelector('menuItemDetail');

const basePreviewSelector = (state, qtyCurrentlyInCart) => {
  const { productPreview } = state;

  const {
    isFetching: isUpdatingPreview,
    inventory,
    final_price: finalPrice
  } = productPreview;

  const requestedQty = selector(state, 'quantityInput') || 1;
  const inventoryLessCart = subtractFromInventory(
    inventory,
    qtyCurrentlyInCart
  );
  const pricePreview = getValueWithTwoDecimalPlace(finalPrice);
  const isSoldOut = inventory === 0;

  return {
    isUpdatingPreview,
    qtyCurrentlyInCart,
    inventory,
    requestedQty,
    inventoryLessCart,
    pricePreview,
    isSoldOut
  };
};
export const productPreviewHelperSelectorMenuItem = state => {
  const { menuItemSlug } = getOnlineOrderingUrlParams(
    state.router.location.pathname
  );
  const menuItemProduct = getMenuItem(state, menuItemSlug);
  const menuItemProductId = get(menuItemProduct, 'id');
  const qtyCurrentlyInCart = menuItemProductId
    ? getQuantityOfProductInCart(state.cart, menuItemProductId)
    : 0;
  const {
    isUpdatingPreview,
    inventory,
    requestedQty,
    inventoryLessCart,
    pricePreview,
    isSoldOut
  } = basePreviewSelector(state, qtyCurrentlyInCart);

  const inventoryPreview = subtractFromInventory(
    inventoryLessCart,
    requestedQty
  );

  return {
    isUpdatingPreview,
    qtyCurrentlyInCart,
    ...inventoryPreviewHelper(inventoryPreview),
    inventoryLessCart,
    pricePreview,
    isSoldOut,
    inventory,
    inventoryPreview,
    requestedQty
  };
};

const inventoryPreviewHelper = inventoryPreview => ({
  previewedQuantityExceedsInventory:
    inventoryPreview === null ? false : inventoryPreview < 0,
  inventoryIsDepletedWithCurrentQuantity: inventoryPreview === 0
});

export const productPreviewHelperSelectorCartItem = state => {
  const { cart, router } = state;
  const { cartItemId } = getOnlineOrderingUrlParams(router.location.pathname);
  const cartItem = getCartItemByIdSelector(cart, parseInt(cartItemId));
  const qtyCurrentlyInCart = getQuantityOfProductInCart(
    state.cart,
    get(cartItem, 'product')
  );
  const {
    isUpdatingPreview,
    inventory,
    requestedQty,
    inventoryLessCart,
    pricePreview,
    isSoldOut
  } = basePreviewSelector(state, qtyCurrentlyInCart);

  if (!cartItem) {
    return {};
  }
  const newRequestedInventory = requestedQty - cartItem.quantity;

  const inventoryPreview = subtractFromInventory(
    inventoryLessCart,
    newRequestedInventory
  );

  return {
    isUpdatingPreview,
    qtyCurrentlyInCart,
    inventoryLessCart,
    ...inventoryPreviewHelper(inventoryPreview),
    pricePreview,
    isSoldOut,
    inventory,
    inventoryPreview,
    requestedQty
  };
};
