import { useSelector } from 'react-redux';
import { INVESTOR_DAY_ORDERING } from 'constants/featureFlags';

export const useHasInvestorDayFeatureFlag = () => {
  const betaFeatureFlags =
    useSelector(state => state.account.beta_feature_flags) ?? [];

  return betaFeatureFlags.includes(INVESTOR_DAY_ORDERING);
};

export function accountHasInvestorDayFeatureFlag(account) {
  return account.beta_feature_flags?.includes(INVESTOR_DAY_ORDERING);
}
