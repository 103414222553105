import {
  SET_SCROLL_POSITION,
  RESET_SCROLL_POSITION
} from 'bento-ordering/base/actions/actionTypes';

const initialState = {
  menu: 0
};

const scrollManager = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_POSITION:
      return {
        ...state,
        [action.payload.entity]: action.payload.position
      };
    case RESET_SCROLL_POSITION:
      return {
        ...state,
        [action.payload.entity]: 0
      };
    default: {
      return state;
    }
  }
};

export default scrollManager;
