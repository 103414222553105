/* h:mm A ex: 4:55pm */
export const HOUR_MINUTE_DAY_TIME = 'h:mma';

/* HH:mm:ss  ex: 03:55:32 */
export const HOUR_MINUTE_SECONDS = 'HH:mm:ss';
export const MILITARY_TIME = 'HH:mm';

export const DATE_OUTPUT_FORMAT = 'MMM D';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const DAY_OUTPUT_FORMAT = 'ddd';
export const FULL_DAY_OUTPUT_FORMAT = 'dddd';

export const MIDNIGHT = '00:00';

export const REOPENS_STORE_AT_INPUT_FORMAT = 'YYYY-MM-DDThh:mm:ssTZD';
export const REOPENS_STORE_AT_OUTPUT_FORMAT = 'ddd[,] MM/DD [at] h:mm a';

/* MMM  ex: FEB */
export const ABBREVIATED_MONTH = 'MMM';

export const GROUP_ORDERING_DEADLINE_INPUT_FORMAT = 'YYYY-MM-DDThh:mm:ssTZD';
/* ex: Feb 2, 12:30 PM */
export const GROUP_ORDERING_DEADLINE_OUTPUT_FORMAT = 'MMM D, h:mm A';

export const DATE_YYYY_MM_DD_FORMAT = 'yyyy-mm-dd';
