/**
 * This helper will log a warning to DataDog with given subject.
 *
 * @param subject {string}  warning message
 * @param data    {*}       optional additional information to be logged
 */
export default (subject, data = null, stack = null) => {
  if (data) {
    console.warn(`${subject} - Details: `, data, stack);
  } else {
    console.warn(subject);
  }
};
