import {
  WHITE,
  SOY,
  MEDIUM_SOY,
  NO_OPACITY_WHITE,
  ERROR
} from 'styles/theme/colors';

import {
  FONT_PRESET_MAP,
  DEFAULT_LETTER_SPACING,
  HEADER_AND_NAVIGATION_LETTER_SPACING
} from 'styles/theme/constants';

export const DEFAULT_BUTTON_COLORS = {
  color: WHITE,
  backgroundColor: SOY,
  hoverColor: WHITE,
  hoverBackgroundColor: MEDIUM_SOY
};

export const DEFAULT_MENU_ITEM_NAME_VALUES = {
  letterSpacing: DEFAULT_LETTER_SPACING,
  fontWeight: 500,
  uppercase: false,
  font: '',
  color: SOY,
  textTransform: 'none'
};

export const DEFAULT_MENU_SECTION_TITLE_VALUES = {
  letterSpacing: DEFAULT_LETTER_SPACING,
  fontWeight: 500,
  uppercase: false,
  font: '',
  color: SOY,
  fontSize: '20px'
};

export const DEFAULT_CARD_AND_BACKGROUND_VALUES = {
  cardBackgroundColor: WHITE,
  cardOutlineColor: NO_OPACITY_WHITE,
  pageBackgroundColor: WHITE,
  dropShadow: true
};

export const DEFAULT_HEADER_AND_NAVIGATION_VALUES = {
  accentColor: MEDIUM_SOY,
  headerBackgroundColor: WHITE,
  headerLinkColor: SOY,
  headerLinkHoverColor: MEDIUM_SOY,
  sectionNavigationLinkColor: SOY,
  sectionNavigationLinkHoverColor: MEDIUM_SOY,
  letterSpacing: HEADER_AND_NAVIGATION_LETTER_SPACING,
  fontWeight: 500,
  uppercase: false,
  font: '',
  textTransform: 'none'
};

export const DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES = {
  fontWeight: 400,
  fontFamily: FONT_PRESET_MAP.circular_std_book,
  italics: false,
  fontStyle: 'normal',
  letterSpacing: DEFAULT_LETTER_SPACING
};

export const DEFAULT_ERROR_VALUES = {
  color: ERROR
};

export const DEFAULT_FONT_FAMILY = FONT_PRESET_MAP.circular_std_medium;

export const DEFAULT_BACKGROUND_VALUES = {
  secondary: 'rgba(0,0,0,0.5)'
};

const getHeaderDefaultFontPreset = fontpreset =>
  !fontpreset || fontpreset === 'none' ? DEFAULT_FONT_FAMILY : fontpreset;

export function getButtonColorsWithDefaults({
  button_font_color,
  button_background_color,
  button_hover_font_color,
  button_hover_background_color
}) {
  return {
    button_font_color: button_font_color || DEFAULT_BUTTON_COLORS.color,
    button_background_color:
      button_background_color || DEFAULT_BUTTON_COLORS.backgroundColor,
    button_hover_font_color:
      button_hover_font_color || DEFAULT_BUTTON_COLORS.hoverColor,
    button_hover_background_color:
      button_hover_background_color ||
      DEFAULT_BUTTON_COLORS.hoverBackgroundColor
  };
}

export function getCardAndBackgroundColorsWithDefaults({
  card_background_color,
  card_outline_color,
  page_background_color,
  drop_shadow
}) {
  return {
    card_background_color:
      card_background_color ||
      DEFAULT_CARD_AND_BACKGROUND_VALUES.cardBackgroundColor,
    card_outline_color:
      card_outline_color || DEFAULT_CARD_AND_BACKGROUND_VALUES.cardOutlineColor,
    page_background_color:
      page_background_color ||
      DEFAULT_CARD_AND_BACKGROUND_VALUES.pageBackgroundColor,
    drop_shadow: drop_shadow ?? DEFAULT_CARD_AND_BACKGROUND_VALUES.dropShadow
  };
}

export function getHeaderAndNavigationWithDefaults({
  accent_color,
  header_background_color,
  header_link_color,
  header_link_hover_color,
  section_navigation_link_color,
  section_navigation_link_hover_color,
  letter_spacing,
  fontpreset,
  weight,
  uppercase,
  font
}) {
  return {
    accent_color:
      accent_color || DEFAULT_HEADER_AND_NAVIGATION_VALUES.accentColor,
    header_background_color:
      header_background_color ||
      DEFAULT_HEADER_AND_NAVIGATION_VALUES.headerBackgroundColor,
    header_link_color:
      header_link_color || DEFAULT_HEADER_AND_NAVIGATION_VALUES.headerLinkColor,
    header_link_hover_color:
      header_link_hover_color ||
      DEFAULT_HEADER_AND_NAVIGATION_VALUES.headerLinkHoverColor,
    section_navigation_link_color:
      section_navigation_link_color ||
      DEFAULT_HEADER_AND_NAVIGATION_VALUES.sectionNavigationLinkColor,
    section_navigation_link_hover_color:
      section_navigation_link_hover_color ||
      DEFAULT_HEADER_AND_NAVIGATION_VALUES.sectionNavigationLinkHoverColor,
    letter_spacing:
      letter_spacing || DEFAULT_HEADER_AND_NAVIGATION_VALUES.letterSpacing,
    fontpreset: getHeaderDefaultFontPreset(fontpreset),
    weight: weight || DEFAULT_HEADER_AND_NAVIGATION_VALUES.fontWeight,
    uppercase: uppercase || DEFAULT_HEADER_AND_NAVIGATION_VALUES.uppercase,
    font: font || DEFAULT_HEADER_AND_NAVIGATION_VALUES.font,
    textTransform: DEFAULT_HEADER_AND_NAVIGATION_VALUES.textTransform
  };
}

export function getItemDescriptionsAndPricesWithDefaults({
  fontpreset,
  letter_spacing,
  weight,
  italics,
  font
}) {
  return {
    fontpreset:
      !fontpreset || fontpreset === 'none'
        ? DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES.fontFamily
        : fontpreset,
    letter_spacing:
      letter_spacing ||
      DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES.letterSpacing,
    weight: weight || DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES.fontWeight,
    italics: italics || DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES.italics,
    font: font || '',
    fontStyle: DEFAULT_ITEM_DESCRIPTION_AND_PRICES_VALUES.fontStyle
  };
}

export function getMenuItemNamesWithDefaults({
  fontpreset,
  letter_spacing,
  weight,
  color,
  uppercase,
  font
}) {
  return {
    fontpreset: getHeaderDefaultFontPreset(fontpreset),
    letter_spacing:
      letter_spacing || DEFAULT_MENU_ITEM_NAME_VALUES.letterSpacing,
    weight: weight || DEFAULT_MENU_ITEM_NAME_VALUES.fontWeight,
    uppercase: uppercase || DEFAULT_MENU_ITEM_NAME_VALUES.uppercase,
    font: font || DEFAULT_MENU_ITEM_NAME_VALUES.font,
    color: color || DEFAULT_MENU_ITEM_NAME_VALUES.color,
    textTransform: DEFAULT_MENU_ITEM_NAME_VALUES.textTransform
  };
}

export function getMenuSectionTitlesWithDefaults({
  fontpreset,
  letter_spacing,
  weight,
  color,
  uppercase,
  font,
  size
}) {
  return {
    fontpreset: getHeaderDefaultFontPreset(fontpreset),
    letter_spacing:
      letter_spacing || DEFAULT_MENU_SECTION_TITLE_VALUES.letterSpacing,
    weight: weight || DEFAULT_MENU_SECTION_TITLE_VALUES.fontWeight,
    uppercase: uppercase || DEFAULT_MENU_SECTION_TITLE_VALUES.uppercase,
    font: font || DEFAULT_MENU_SECTION_TITLE_VALUES.font,
    color: color || DEFAULT_MENU_SECTION_TITLE_VALUES.color,
    size: size || DEFAULT_MENU_SECTION_TITLE_VALUES.fontSize
  };
}

export function getErrorStatesWithDefaults({ error_color }) {
  return {
    error_color: error_color || DEFAULT_ERROR_VALUES.color
  };
}
