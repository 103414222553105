import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import last from 'lodash/last';
import moment from 'moment';

import { sortByDistance } from 'helpers/locations/sortByDistance';
import { filterByIsClosed } from 'helpers/locations/filterByIsClosed';

import {
  getItemByIdSelector,
  getProcessedListSelector
} from 'bento-ordering/base/reducers/bentoApi';

import { getFulfillmentMethods } from 'bento-ordering/cart/utils/getFulfillmentMethods';
import {
  get15MinIntervals,
  isDateTimeWithinMenuHoursRange,
  isDateTimeBeforeMenuHoursRangeEnd,
  isMidnight
} from 'bento-ordering/base/utils/timeHelpers';
import {
  NO_ASAP_FOR_DELIVERY_AND_PICKUP,
  NO_SET_ADDRESS,
  ASAP_NOT_AVAILABLE_FOR_THROTTLES,
  ORDERING_NOT_CURRENTLY_AVAILABLE
} from 'bento-ordering/base/constants/orderAlerts';

import {
  LOCATION_OFFLINE,
  LOCATION_ASAP_OFFLINE
} from 'bento-ordering/locations/constants/paths';
import { buildDateSelectOption } from 'bento-ordering/locations/utils/fulfillmentOptionsFilter';
import isLocationClosed from 'bento-ordering/locations/utils/isLocationClosed';

import {
  DATE_INPUT_FORMAT,
  DATE_TIME_FORMAT
} from 'bento-ordering/base/constants/timeFormat';
import {
  selectCombineHoursWithLocationThrottles,
  getNextAvailableTimeFromThrottlesSelector
} from 'bento-ordering/locations/selectors/locationThrottledTimes';
import { ASAP_BOUNDARY_IN_MINUTES_FOR_THROTTLES } from 'bento-ordering/locations/constants/orderThrottleSettings';
import { selectFirstRestaurantLocationError } from 'bento-ordering/locations/selectors/selectFirstRestaurantLocationError';
import getNowWithPrepForLocation from 'bento-ordering/locations/utils/getNowWithPrep';
import getFirstLocationMenu from 'bento-ordering/locations/utils/getFirstLocationMenu';
import createDeepEqualSelector from 'bento-ordering/utils/createDeepEqualSelector';

/**
 *  go through all locations and return a list of available methods
 * @param {Object} restaurantLocations
 * @param {Object} locationThrottledTimes
 * @return {string[]}
 * array indicating which methods are available for all locations eg: ['pickup', 'delivery']
 */
export const getAllLocationsAvailableFulfillmentMethodSelector = (
  restaurantLocations,
  locationThrottledTimes,
  cart
) => {
  const locations = Object.values(restaurantLocations.byId);
  const methodAvailability = locations.reduce(
    (acc, curr) => {
      const fulfillmentMethodsAvailableForLocation = locationSelectorModule.getAvailableFulfillmentMethodSelector(
        {
          restaurantLocations,
          locationThrottledTimes,
          onlineOrderingLocation: curr.id,
          cart
        }
      );

      fulfillmentMethodsAvailableForLocation.forEach(method => {
        acc[method] = true;
      });

      return acc;
    },
    { pickup: false, delivery: false }
  );
  return Object.keys(methodAvailability).filter(
    method => methodAvailability[method]
  );
};

/**
 * Determine which online ordering fulfillment methods are available at
 * the current location any time in the next 7 days (meaning they will be
 * displayed as options)
 * @param {Object} param
 * @param {{}} param.restaurantLocations
 * @param {{}} param.locationThrottledTimes
 * @param {number} param.onlineOrderingLocation - id
 * @param {{}} param.cart
 * @return {boolean}
 */
export const getAvailableFulfillmentMethodSelector = ({
  restaurantLocations,
  locationThrottledTimes,
  onlineOrderingLocation,
  cart
}) => {
  const fulfillmentOptionsForApplicationVariation = getFulfillmentMethods();
  return fulfillmentOptionsForApplicationVariation.filter(
    fulfillmentMethod =>
      locationSelectorModule.getDatetimesWithRangesSelector(
        restaurantLocations,
        onlineOrderingLocation,
        fulfillmentMethod
      ).length ||
      locationSelectorModule.getIsAsapAvailableForLocationAndMethodSelector({
        restaurantLocationsState: restaurantLocations,
        locationThrottledTimesState: locationThrottledTimes,
        cart,
        selectedMethod: fulfillmentMethod,
        onlineOrderingLocation
      })
  );
};

/**
 * Determine if all locations for online ordering are closed
 * If restaurant has 1 location with no menu hours or is offline -> true
 * If restaurant has > 1 locations with no menu hours, both are offline -> true
 * @param {object} restaurantLocations state from redux
 * @return {boolean}
 */
export const areAllLocationsClosedSelector = restaurantLocations => {
  const locationIds = restaurantLocations.allIds;
  const locationsObject = restaurantLocations.byId;

  return locationIds.every(locationId =>
    isLocationClosed(locationsObject[locationId])
  );
};

/**
 * Determines if online ordering location is closed
 *
 * @param   {object}  restaurantLocations Object with restaurant locations state from Redux
 * @param   {number}  locationId          Location's details reference key
 *
 * @returns {boolean} Returns whether location is closed or not
 */
export const isLocationClosedSelector = (restaurantLocations, locationId) => {
  // If a malformed slug is part of the URL, locationId will be null.
  if (!locationId) {
    return true;
  }
  const locationsObject = restaurantLocations.byId;
  return isLocationClosed(locationsObject[locationId]);
};

/**
 * Get the location name for the given location ID.
 *
 * @param {object} restaurantLocations - Restaurant locations from the redux state.
 * @param {number} locationId - The ID of the location for which name is to be fetched.
 * @return {string|null} - The location name if found, otherwise null.
 */
export const selectOnlineOrderingLocationName = (
  restaurantLocations,
  locationId
) => {
  if (!locationId) {
    return null;
  }
  const locationsObject = restaurantLocations.byId;
  return locationsObject[locationId]?.location?.name || null;
};

/**
 * Determine if we should redirect to offline pages
 * @param {object} state state from redux
 * @return {string}
 */
export const getOfflinePageDestination = state => {
  const { restaurantLocations, account } = state;
  const { allIds } = restaurantLocations;
  const storeTemporarilyDisabled = account.store_temporarily_disabled;
  const error = selectFirstRestaurantLocationError(state);
  const hasAsapOfflineError = error?.response?.next_available_asap_datetime;

  if (
    !window.location.pathname.includes('/asap-offline') &&
    hasAsapOfflineError
  ) {
    return LOCATION_ASAP_OFFLINE;
  }
  if (
    !window.location.pathname.includes('/offline') &&
    !hasAsapOfflineError &&
    (storeTemporarilyDisabled ||
      allIds.length === 0 ||
      areAllLocationsClosedSelector(restaurantLocations))
  ) {
    return LOCATION_OFFLINE;
  }
  return null;
};

/**
 * get the fulfillment options for a location from location state
 * @param {object} restaurantLocationsState
 * @param {number} selectedId (of online ordering location)
 *
 * @return {object} {delivery: {...}, pickup:{...}} or undefined
 */
const getFulfillmentOptionsForLocationSelector = (
  restaurantLocationsState,
  selectedId
) => {
  return get(
    getItemByIdSelector(restaurantLocationsState, selectedId),
    'fulfillment_options'
  );
};

/**
 *  pull off the fulfillment options key for a given location and method
  corresponds only to selected method

 * @param {object} restaurantLocationsState
 * @param {number} selectedId of location on cart
 * @param {string} selectedMethod on cart
 * @return {object} {datetimes_with_ranges: [...], is_asap_available: false}
 * if none found, return undefined
 */
const getFulfillmentOptionsForLocationAndMethodSelector = (
  restaurantLocationsState,
  selectedId,
  selectedMethod
) => {
  const fulfillmentOptionsForLocation = getFulfillmentOptionsForLocationSelector(
    restaurantLocationsState,
    selectedId
  );

  return get(fulfillmentOptionsForLocation, selectedMethod);
};

/**
 * get the is asap available for a given location and method
 * take into account throttled times
 * @param {Object} params
 * @param {{}} params.cart
 * @param {{}} params.restaurantLocationsState
 * @param {{}} params.locationThrottledTimesState
 * @param {string} params.selectedMethod - could pull this off of cart, but sometimes we want to see the other method, so allow this to be separate param
 * @param {number} params.onlineOrderingLocation - could pull this off of cart, but sometimes we want to see other locations, so allow this to be separate param
 * @return {boolean}
 */
export const getIsAsapAvailableForLocationAndMethodSelector = ({
  restaurantLocationsState,
  cart,
  locationThrottledTimesState,
  selectedMethod,
  onlineOrderingLocation
}) => {
  const fulfillmentOptionsForLocationAndMethod = getFulfillmentOptionsForLocationAndMethodSelector(
    restaurantLocationsState,
    onlineOrderingLocation,
    selectedMethod
  );
  const isAsapAvailableWithoutThrottles = get(
    fulfillmentOptionsForLocationAndMethod,
    'is_asap_available',
    false
  );

  if (!isAsapAvailableWithoutThrottles) {
    return false;
  }

  return getIsAsapAvailableWithThrottles({
    restaurantLocationsState,
    cart,
    onlineOrderingLocation,
    locationThrottledTimesState
  });
};
/**
 * get asap availability given throttled times
 * determines nearest 15 minute window to now + prep
 * and sees if there is less than 30 minutes difference
 * between "normal" prep by time and prep by time
 * given throttles
 * @param {Object} params
 * @param {{}} params.restaurantLocationsState
 * @param {{}} params.cart
 * @param {number} params.onlineOrderingLocation -  could pull this form cart, but sometimes we want to to see other locations
 * @param {{}} params.locationThrottledTimesState
 * @return {boolean}
 */
export const getIsAsapAvailableWithThrottles = ({
  restaurantLocationsState,
  cart,
  onlineOrderingLocation,
  locationThrottledTimesState
}) => {
  const location = getItemByIdSelector(
    restaurantLocationsState,
    onlineOrderingLocation
  );
  const nowWithPrep = getNowWithPrepForLocation(location);

  const nextAvailableTime = getNextAvailableTimeFromThrottlesSelector({
    locationThrottledTimes: locationThrottledTimesState,
    onlineOrderingLocation,
    dateTime: nowWithPrep
  });

  const nextAvailableDateTimeMoment = moment(
    nextAvailableTime,
    DATE_TIME_FORMAT
  );
  const durationInMinutes = nextAvailableDateTimeMoment.diff(
    nowWithPrep,
    'minutes'
  );

  const doesDurationFallWithinAsapBoundary =
    durationInMinutes < ASAP_BOUNDARY_IN_MINUTES_FOR_THROTTLES;

  const asapMenuEndsDateTime = locationSelectorModule.getAsapLocationMenuEndDatetime(
    restaurantLocationsState,
    cart
  );

  const asapMenuEndsDateTimeMoment = moment(asapMenuEndsDateTime);
  let doesNextAvailableTimeFallWithinAsapMenuHours = true;
  if (asapMenuEndsDateTimeMoment.isValid()) {
    const lastAvailablePrepByDatetime = asapMenuEndsDateTimeMoment.add(
      location.current_prep_time,
      'minutes'
    );
    doesNextAvailableTimeFallWithinAsapMenuHours = lastAvailablePrepByDatetime.isAfter(
      nextAvailableDateTimeMoment
    );
  }

  return (
    doesDurationFallWithinAsapBoundary &&
    doesNextAvailableTimeFallWithinAsapMenuHours
  );
};

/**
 *  get the 'later' datetime objects for a given location and method
 * @param {object} restaurantLocations (from redux state)
 * @param {number} selectedLocation (id)
 * @param {string} selectedMethod (delivery or pickup)
 * @return {array} [{date: '2019-2-1', hour_ranges: [{open_time: '19:00', close_time: '20:00'}...]}, ...]. returns empty array if none found
 */
export const getDatetimesWithRangesSelector = (
  restaurantLocations,
  selectedLocation,
  selectedMethod
) => {
  return get(
    getFulfillmentOptionsForLocationAndMethodSelector(
      restaurantLocations,
      selectedLocation,
      selectedMethod
    ),
    'datetimes_with_ranges',
    []
  );
};

/**
 * select datetimes with ranges
 *
 * uses the values pulled from cart (cartWorkinCopy can be passed for cart here as well)
 *
 * @param {Object} params
 * @param {{}} params.restaurantLocations
 * @param {{}} params.cart
 * @return {{}[]} fulfillment datetimes
 */
const selectDatetimesWithRange = ({ restaurantLocations, cart }) => {
  const fulfillmentDatetimes = getDatetimesWithRangesSelector(
    restaurantLocations,
    cart.online_ordering_location,
    cart.online_ordering_fulfillment_method
  );
  return fulfillmentDatetimes;
};

/**
 * select location throttled times for location on cart
 *
 *
 * @param {Object} params
 * @param {{}} params.locationThrottledTimes
 * @param {{}} params.cart
 * @return {{}[]} fulfillment datetimes
 */
const selectLocationThrottledTimesForCartLocation = ({
  locationThrottledTimes,
  cart
}) => {
  return locationThrottledTimes.byLocationId[cart.online_ordering_location];
};

/**
 * select cart's fulfillment method.
 *
 * cartWorkingCopy can be passed
 *
 * @param {Object} params
 * @param {{}} params.cart
 *
 * @return {string} - PICKUP, DELIVERY, DINE-IN
 */
const selectCartFulfillmentMethod = ({ cart }) =>
  cart.online_ordering_fulfillment_method;

/**
 * select cart's delivery time.
 *
 * cartWorkingCopy can be passed
 *
 * @param {Object} params
 * @param {{}} params.cart
 *
 * @return {number}
 */
const selectCartDeliveryTime = ({ cart }) => cart.total_delivery_time;
/**
 *  get the 'later' dates and their times
 *
 * we have to get them together so we know if all times for the date
 * are disabled
 *
 * @param {Object} params
 * @param {{}} params.restaurantLocations (from redux state)
 * @param {{}} params.cart can be cart or cart workingCopy (from redux state)
 * @param {{}} params.locationThrottledTimes  (from redux state)
 *
 * @return {{value: string, label: string, disabled: boolean, timeOptions: {}[]}}
 */
export const selectFulfillmentDateOptionsForLocationAndMethod = createDeepEqualSelector(
  [
    selectDatetimesWithRange,
    selectLocationThrottledTimesForCartLocation,
    selectCartFulfillmentMethod,
    selectCartDeliveryTime
  ],
  (
    fulfillmentDatetimes,
    locationThrottledTimesForLocation,
    selectedMethod,
    totalDeliveryTime
  ) => {
    return fulfillmentDatetimes.map(fulfillmentDateTime => {
      const fulfillmentHoursForDate = getFulfillmentHoursForDateSelector({
        fulfillmentDatetimes,
        locationThrottledTimesForLocation,
        selectedMethod,
        totalDeliveryTime,
        selectedDate: fulfillmentDateTime.date
      });

      const dateOptions = buildDateSelectOption(
        fulfillmentDateTime.date,
        fulfillmentHoursForDate
      );
      return dateOptions;
    });
  }
);

/**
 * modify later options to handle "overnight" hours
 *
 * @param {[]} todayTimeRanges time ranges from today (pulled off of locations state)
 * @param {object} nextDayFirstRange the first range from the next day
 * @return {[]} of time ranges
 */
const getOvernightHours = (todayTimeRanges, nextDayFirstRange) => {
  const todayLastTimeRangeClose = get(last(todayTimeRanges), 'close_time');
  const endsAtMidnight = isMidnight(todayLastTimeRangeClose);

  const nextDayFirstStartTime = get(nextDayFirstRange, 'open_time');
  const nextDayStartsAtMidnight = isMidnight(nextDayFirstStartTime);

  const goesPastMidnight = endsAtMidnight && nextDayStartsAtMidnight;
  const processedRanges = [...todayTimeRanges];
  if (goesPastMidnight) {
    processedRanges.push(nextDayFirstRange);
  }
  return processedRanges;
};

/**
 *  get the 'later' times
 *
 * @param {Object} params
 * @param {{}} params.fulfillmentDatetimes (pulled off from redux state)
 * @param {{}} params.locationThrottledTimesForLocation (pulled off from from redux state)
 * @param {number} params.totalDeliveryTime
 * @param {string} params.selectedMethod (delivery or pickup)
 * @param {string} params.selectedDate '2019-04-20'
 * @return {array} of strings representing times ['20:00', ...]
 */
export const getFulfillmentHoursForDateSelector = ({
  fulfillmentDatetimes,
  locationThrottledTimesForLocation,
  selectedDate,
  selectedMethod,
  totalDeliveryTime
}) => {
  const indexOfDateTimeForSelectedDate = findIndex(
    fulfillmentDatetimes,
    dateTime => dateTime.date === selectedDate
  );

  const selectedDateTime = fulfillmentDatetimes[indexOfDateTimeForSelectedDate];
  let availableTimeRanges = get(selectedDateTime, 'hour_ranges', []);

  const nextAvailableDay = get(fulfillmentDatetimes, [
    indexOfDateTimeForSelectedDate + 1
  ]);
  const nextAvailableDayDate = get(nextAvailableDay, 'date');

  const nextDayFirstRange = get(nextAvailableDay, ['hour_ranges', 0]);
  if (
    nextAvailableDayDate &&
    moment(nextAvailableDayDate).isSame(moment(selectedDate).add(1, 'days'))
  ) {
    availableTimeRanges = getOvernightHours(
      availableTimeRanges,
      nextDayFirstRange
    );
  }

  const availableTimes = availableTimeRanges.reduce((acc, currentRange) => {
    let openTime = moment(currentRange.open_time, 'HH:mm').format('HH:mm');

    if (acc.includes(openTime)) {
      openTime = moment(openTime, 'HH:mm')
        .add(15, 'minutes')
        .format('HH:mm');
    }

    const timeStrings = get15MinIntervals(openTime, currentRange.close_time);
    return [...acc, ...timeStrings];
  }, []);
  const combinedHoursWithLocationThrottlesResult = selectCombineHoursWithLocationThrottles(
    {
      times: availableTimes,
      locationThrottledTimesForLocation,
      selectedMethod,
      totalDeliveryTime,
      selectedDate
    }
  );
  return combinedHoursWithLocationThrottlesResult;
};

/**
 * get the location object from state by slug
 *
 * @param {object} restaurantLocations (from redux state)
 * @param {string} slug (location slug)
 * @return {object} location from byId or empty {}
 */
export const getLocationBySlugSelector = (restaurantLocationsState, slug) => {
  const id = restaurantLocationsState.allIds.find(id => {
    return restaurantLocationsState.byId[id].location.slug === slug;
  });
  return restaurantLocationsState.byId[id] || {};
};

/**
 * get list of lat and lng from location in state
 * @param {object} restaurantLocations (from redux state)
 * @return {array} [{lat: '20.00', lng: '80.000', id: 100}] or empty array if no locations
 */
export const getLocationsLatLngSelector = restaurantLocationsState =>
  getProcessedListSelector(restaurantLocationsState).map(
    getLocationLatLngSelector
  );
/**
 * get lat and lng and id from location in state
 * @param {object} location (from redux state byId)
 * @return {object} {lat: '20.00', lng: '80.000', id: 100}. key will point to undefined if not found
 */
export const getLocationLatLngSelector = location => ({
  lat: get(location, 'location.lat'),
  lng: get(location, 'location.lng'),
  id: get(location, 'id')
});

/**
 * get lat lgn and id for selected location
 * @param {object} restaurantLocations (from redux state)
 * @return {object} {lat: '20.00', lng: '80.000', id: 100}. keys will point to undefined if not found
 */
export const getSelectedLocationLatLngSelector = (
  restaurantLocationsState,
  id
) =>
  getLocationLatLngSelector(getItemByIdSelector(restaurantLocationsState, id));

/**
 * Get order setting alert
 * There are a few cases when we should alert the user.
 * This selector will let use determine if we should
 * display the alert to the user.
 * Cases:
 * 1) ASAP not available for pickup
 * 2) ASAP not available for delivery
 * 3) ASAP not available for pickup and delivery
 * 4) Item has been added to cart
 *
 * This selector checks which alert should be displayed
 * base on the above cases but we also have a alertStatus
 * reducer that keeps track if the alert has been displayed
 * already because we only want to display some of these alerts
 * once.
 * @param {boolean} isAsapAvailableForPickup
 * @param {boolean} isAsapAvailableForDelivery
 * @param {object} alertStatus state from reducer
 * @return {string} type of alert to be displayed or null
 */
export const getOrderSettingAlertSelector = (
  isAsapAvailableForPickup,
  isAsapAvailableForDelivery,
  isAsapAvailableWithThrottles,
  alertStatus,
  isPreviewMenuOn = false
) => {
  if (isPreviewMenuOn) {
    return ORDERING_NOT_CURRENTLY_AVAILABLE;
  }

  if (alertStatus[NO_SET_ADDRESS]) {
    return NO_SET_ADDRESS;
  }
  if (!isAsapAvailableWithThrottles) {
    return ASAP_NOT_AVAILABLE_FOR_THROTTLES;
  }
  if (
    alertStatus[NO_ASAP_FOR_DELIVERY_AND_PICKUP] &&
    (!isAsapAvailableForDelivery || !isAsapAvailableForPickup)
  ) {
    return NO_ASAP_FOR_DELIVERY_AND_PICKUP;
  }
  return null;
};

/**
 * get first available fulfillment option (locationMenu)
 * takes into account throttles
 * @param {Object} params
 * @param {{}} params.restaurantLocations (from redux state)
 * @param {number} params.selectedLocation (id)
 * @param {string} params.selectedMethod (delivery or pickup)
 * @param {{}} params.locationThrottledTimes
 * @param {{}} params.cart
 * @return {{date: string, open_time: string, close_time: string, menu_id: number}} representing the first available later locationMenu
 */
export const getFirstAvailableFulfillmentOption = ({
  restaurantLocations,
  selectedLocationId,
  fulfillmentMethod,
  locationThrottledTimes,
  cart
}) => {
  const locationMenus = locationSelectorModule.getDatetimesWithRangesSelector(
    restaurantLocations,
    selectedLocationId,
    fulfillmentMethod
  );

  return getFirstLocationMenu({
    locationMenus,
    locationThrottledTimes,
    selectedLocationId,
    fulfillmentMethod,
    cart
  });
};

/**
 * get the today date at location
 * @param {object} restaurantLocations (from redux state)
 * @param {number} locationId (id)
 * @return {string} '2019-02-04' representing the date at location in location's timezone
 */
export const todaySelector = (restaurantLocations, locationId) => {
  const location = getItemByIdSelector(restaurantLocations, locationId);
  return get(location, 'today_date');
};

/**
 * When sorting locations that have delivery available,
 * prioritize locations offering in-house delivery over
 * locations that use doordash
 *
 * Then, for locations where the the delivery carriers
 * are the same, sort by distance
 *
 */
const sortByCarrierThenDistance = (location1, location2) => {
  const carrier1 = location1.uses_doordash ? 1 : 0;
  const carrier2 = location2.uses_doordash ? 1 : 0;

  if (carrier1 === carrier2) {
    return sortByDistance(location1, location2);
  } else {
    return carrier1 - carrier2;
  }
};

/**
 * after diner enters address, we sort based on locations that deliver vs.
 * pickup locations vs. other. this selector grabs restaurants that deliver
 * to diner's address
 * @param {object} restaurantLocations (from redux state)
 * @return {array} locations that deliver to address sorted by distance
 */
export const locationsThatDeliverToAddressSelector = restaurantLocationsState => {
  const restaurantLocations = getProcessedListSelector(
    restaurantLocationsState
  );
  return restaurantLocations
    .filter(location => !location.is_closed && location.does_deliver_to_address)
    .sort(sortByCarrierThenDistance);
};

/**
 * after diner enters address, we sort based on locations that deliver
 * vs. pickup locations vs. other. this selector grabs restaurants the diner
 * can pick up from
 * @param {object} restaurantLocations (from redux state)
 * @return {array} locations that offer pickup sorted by distance
 */
export const locationsForPickupSelector = restaurantLocationsState => {
  const restaurantLocations = getProcessedListSelector(
    restaurantLocationsState
  );

  return restaurantLocations
    .filter(
      location =>
        !location.is_closed &&
        Object.prototype.hasOwnProperty.call(
          location,
          'does_deliver_to_address'
        ) &&
        !location.does_deliver_to_address
    )
    .sort(sortByDistance);
};

/**
 *
 * after diner enters address, we sort based on locations that deliver vs.
 * pickup locations vs. other. this selector grabs restaurants are closed
 * ("Other locations")
 * @param {object} restaurantLocations (from redux state)
 * @return {array} Other locations (closed)
 */
export const closedLocationsSelector = restaurantLocationsState => {
  const restaurantLocations = getProcessedListSelector(
    restaurantLocationsState
  );

  return filterByIsClosed(restaurantLocations);
};

/**
 * process the locations list and sort all locations where inactive locations
 * is stored at the end of the list
 * @param {Object} restaurantLocations - redux state
 * @return {{}[]} sorted restaurant locations
 */
export const getSortedLocationsListSelector = restaurantLocations => {
  const locations = getProcessedListSelector(restaurantLocations);
  const activeLocations = [];
  const inactiveLocations = [];

  locations.forEach(location => {
    const isLocationInactive = isLocationClosedSelector(
      restaurantLocations,
      location.id
    );
    if (isLocationInactive) {
      inactiveLocations.push(location);
    } else {
      activeLocations.push(location);
    }
  });

  return [...activeLocations, ...inactiveLocations];
};

// ==== LOCATION MENU SELECTORS ====

/**
 * get the location menu for asap
 * @param {object} restaurantLocations (from redux state)
 * @param {object} cart (from redux state)
 * @return {object} location menu for available for asap (empty object if not found). also, api will return this object with null values for all the fields if asap is not available.
 */
export const getLocationMenuForAsapSelector = (restaurantLocations, cart) => {
  return get(
    getFulfillmentOptionsForLocationAndMethodSelector(
      restaurantLocations,
      cart.online_ordering_location,
      cart.online_ordering_fulfillment_method
    ),
    'asap_date_with_range',
    {}
  );
};
/**
 * get the location menu for asap end time
 * @param {{}} restaurantLocations (from redux state)
 * @param {{}} cart (from redux state)
 * @return {string} represents datetime for when asap menu ends
 */
export const getAsapLocationMenuEndDatetime = (restaurantLocations, cart) => {
  const locationMenu = locationSelectorModule.getLocationMenuForAsapSelector(
    restaurantLocations,
    cart
  );

  const endDatetime = `${locationMenu.date}T${locationMenu.close_time}`;
  let endDateTimeMoment = moment(endDatetime, DATE_TIME_FORMAT);
  if (!endDateTimeMoment.isValid()) {
    return null;
  }
  if (isMidnight(locationMenu.close_time)) {
    endDateTimeMoment = endDateTimeMoment.add('1', 'day');
  }
  return endDateTimeMoment.format(DATE_TIME_FORMAT);
};

/**
 * get the location menu for asap or first later option
 * @param {{}} restaurantLocations (from redux state)
 * @param {{}} cart (from redux state)
 * @param {{}} locationThrottledTimes
 * @return {{}} location menu for available for asap (empty object if not found). returns the first later locationMenu if asap is not currently available. If neither of these is found, returns an object with empty values for keys for date, , open_time, close_time, and menu_id
 */
export const getLocationMenuForAsapOrFirstLaterSelector = (
  restaurantLocations,
  cart,
  locationThrottledTimes
) => {
  let locationMenu =
    getLocationMenuForAsapSelector(restaurantLocations, cart) || {};

  if (!locationMenu.menu_id) {
    locationMenu = locationSelectorModule.getFirstAvailableFulfillmentOption({
      restaurantLocations,
      selectedLocationId: cart.online_ordering_location,
      fulfillmentMethod: cart.online_ordering_fulfillment_method,
      // could read off of cart for oolocation and method but
      // this keeps it flexible. we send in cart for total delivery time
      cart,
      locationThrottledTimes
    });
  }

  return locationMenu;
};

/**
 * get the location menu for later
 * @param {object} restaurantLocations (from redux state)
 * @param {object} cart (from redux state)
 * @return {object} location menu for selected date and time on the cart. this will return null if there are no locationMenus that encompass the date and time on the cart.
 */
export const getLocationMenuForLaterSelector = (restaurantLocations, cart) => {
  const options = getDatetimesWithRangesSelector(
    restaurantLocations,
    cart.online_ordering_location,
    cart.online_ordering_fulfillment_method
  );
  let locationMenu = null;
  for (let i = 0; i < options.length; i++) {
    const currOption = options[i];
    const isSameDay = currOption.date === cart.fulfillment_date;
    // if the fulfillment date matches this date, then we can see
    // which hour range matches
    if (isSameDay) {
      for (let x = 0; x < currOption.hour_ranges.length; x++) {
        const currRange = currOption.hour_ranges[x];

        const isBetween = isDateTimeWithinMenuHoursRange(
          cart.fulfillment_date,
          cart.fulfillment_time,
          currRange.open_time,
          currRange.close_time
        );

        if (isBetween) {
          locationMenu = { ...currRange, date: currOption.date };
          break;
        }
      }

      if (locationMenu) {
        break;
      }
    }
  }
  return locationMenu;
};

/**
 * get the next location menu for later
 * @param {object} restaurantLocations (from redux state)
 * @param {object} cart (from redux state)
 * @return {object} closest location menu that is in the future for selected date and time on the cart. handles both same day, and next day. this will return null if there are no locationMenus that encompass the date and time on the cart.
 */
const getNextLocationMenuForLater = (restaurantLocations, cart) => {
  const options = getDatetimesWithRangesSelector(
    restaurantLocations,
    cart.online_ordering_location,
    cart.online_ordering_fulfillment_method
  );
  let locationMenu = null;

  for (let i = 0; i < options.length; i++) {
    const currOption = options[i];
    const isSameDay = currOption.date === cart.fulfillment_date;
    // if the fulfillment date matches this date, then we can see
    // which hour range matches
    if (isSameDay) {
      for (let x = 0; x < currOption.hour_ranges.length; x++) {
        const currRange = currOption.hour_ranges[x];

        const isBefore = isDateTimeBeforeMenuHoursRangeEnd(
          cart.fulfillment_date,
          cart.fulfillment_time,
          currRange.close_time
        );

        if (isBefore) {
          locationMenu = { ...currRange, date: currOption.date };
          break;
        }
      }

      if (locationMenu) {
        break;
      }
    }
  }
  // if there is no locationMenu found,
  // we need to look in the next day's options
  if (!locationMenu) {
    const datesAfterFulfillment = options.filter(curr => {
      return moment(curr.date, DATE_INPUT_FORMAT).isAfter(
        moment(cart.fulfillment_date, DATE_INPUT_FORMAT)
      );
    });
    if (!datesAfterFulfillment.length) {
      return null;
    }
    const firstDateOption = datesAfterFulfillment[0];

    locationMenu = {
      ...firstDateOption.hour_ranges[0],
      date: firstDateOption.date
    };
  }

  return locationMenu;
};

/**
 * get the location menu for later (exact match) or next location menu for later. use this if you always want to return a locationMenu
 * @param {object} restaurantLocations (from redux state)
 * @param {object} cart (from redux state)
 * @return {object} location menu that is in the future for selected date and time on the cart. this will return null if there are no locationMenus that encompass the date and time on the cart. will return null if nothing is found
 */
export const getLocationMenuForLaterOrNextSelector = (
  restaurantLocations,
  cart
) => {
  let locationMenu =
    getLocationMenuForLaterSelector(restaurantLocations, cart) || {};

  if (!locationMenu.menu_id) {
    locationMenu = getNextLocationMenuForLater(restaurantLocations, cart);
  }
  return locationMenu;
};

/**
 * Retrieves the Facebook location pixel for a given location
 *
 * @param {object} restaurantLocations (from redux state)
 * @param {number} locationId - the selected location id
 * @return {string}
 */
export const getLocationPixelSelector = (restaurantLocations, locationId) => {
  const { location } = getItemByIdSelector(restaurantLocations, locationId);

  return get(location, 'fb_location_pixel_id');
};

/**
 * Retrieves the current location according to the location_slug present in the cart.
 *
 * @param {*} state the redux state
 * @returns returns the current location object
 */
export const getCurrentLocationSelector = state => {
  const restaurantLocations = state.restaurantLocations;
  const location_slug = state.cart.location_slug;

  const currentLocation = getLocationBySlugSelector(
    restaurantLocations,
    location_slug
  );

  return currentLocation;
};

export const getIsPreviewMenuOn = (state, location = null) => {
  const currentLocation = location ?? getCurrentLocationSelector(state);
  const isPreviewMenu =
    !currentLocation.accepting_orders && !currentLocation.is_closed;

  return isPreviewMenu;
};

export const getCurentLocationReopenStoreAtFieldSelector = state => {
  const currentLocation = getCurrentLocationSelector(state);

  return currentLocation?.reopen_store_at ?? '';
};

const locationSelectorModule = {
  getAllLocationsAvailableFulfillmentMethodSelector,
  getAvailableFulfillmentMethodSelector,
  getDatetimesWithRangesSelector,
  getIsAsapAvailableForLocationAndMethodSelector,
  getAsapLocationMenuEndDatetime,
  getLocationMenuForAsapSelector,
  getFirstAvailableFulfillmentOption,
  getLocationMenuForAsapOrFirstLaterSelector
};
export default locationSelectorModule;
