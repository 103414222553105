import _ from 'lodash';

function withDefaultProps(styleOverrides, options) {
  Object.keys(options).forEach(option => {
    if (option in styleOverrides) {
      styleOverrides[option] = {
        ...styleOverrides[option],
        ...options[option]
      };
    }
  });

  return styleOverrides;
}

function createComponents(styleOverrides, options) {
  if (_.isEmpty(options)) {
    return styleOverrides;
  }

  return withDefaultProps(styleOverrides, options);
}

export { withDefaultProps, createComponents };
