import get from 'lodash/get';
import {
  SETUP_INITIAL_DATA_REQUEST,
  SETUP_INITIAL_DATA_SUCCESS,
  SETUP_INITIAL_DATA_FAILURE
} from 'bento-ordering/base/actions/actionTypes';

const setupPromotionsData = (entity, processPayload = payload => payload) => (
  state = {},
  action
) => {
  switch (action.type) {
    case `${SETUP_INITIAL_DATA_REQUEST}`: {
      return {
        ...state,
        isFetching: true,
        error: []
      };
    }
    case `${SETUP_INITIAL_DATA_SUCCESS}`: {
      const data = action.payload[entity].data;
      const error = action.payload[entity].error;
      return {
        ...state,
        ...processPayload(data),
        isFetching: false,
        error: error ? [error] : []
      };
    }
    case `${SETUP_INITIAL_DATA_FAILURE}`: {
      return {
        ...state,
        isFetching: false,
        error: [get(action, 'payload')]
      };
    }
    default:
      return state;
  }
};

export default setupPromotionsData;
