import baseTypography from 'styles/theme/typography';
import { createPalette } from 'styles/theme/colors';
import { FONT_PRESET_MAP } from 'styles/theme/constants';

import {
  getButtonColorsWithDefaults,
  getCardAndBackgroundColorsWithDefaults,
  getHeaderAndNavigationWithDefaults,
  getItemDescriptionsAndPricesWithDefaults,
  getMenuItemNamesWithDefaults,
  getMenuSectionTitlesWithDefaults,
  getErrorStatesWithDefaults,
  DEFAULT_BACKGROUND_VALUES
} from 'styles/helpers/emptyThemeValues';

function getFontFamily(fontFamily, font) {
  if (fontFamily === 'custom') {
    return font;
  }

  const fontFamilyDisplayName = FONT_PRESET_MAP?.[fontFamily];
  if (fontFamilyDisplayName) {
    return fontFamilyDisplayName;
  }

  return fontFamily;
}

export function getButtonColors(buttonColors = {}) {
  const buttonColorsWithDefaults = getButtonColorsWithDefaults(buttonColors);

  const {
    button_font_color: fontColor,
    button_background_color: bgColor,
    button_hover_font_color: hoverFontColor,
    button_hover_background_color: hoverBgColor
  } = buttonColorsWithDefaults;

  return {
    palette: {
      button: {
        font: {
          main: fontColor,
          hover: hoverFontColor
        },
        background: {
          main: bgColor,
          hover: hoverBgColor
        }
      }
    }
  };
}

export function getCardAndBackgroundStyles(cardAndBackground = {}) {
  const cardAndBackgroundWithDefault = getCardAndBackgroundColorsWithDefaults(
    cardAndBackground
  );
  const {
    card_background_color: cardBgColor,
    card_outline_color: cardOutlineColor,
    drop_shadow: dropShadow,
    page_background_color: pageBackgroundColor
  } = cardAndBackgroundWithDefault;

  return {
    palette: {
      card: {
        background: {
          main: cardBgColor
        },
        outline: {
          main: cardOutlineColor
        },
        dropShadow
      },
      page: {
        background: {
          main: pageBackgroundColor
        }
      }
    }
  };
}

export function getHeaderAndNavigationStyles(headerAndNavigation = {}) {
  const headerAndNavigationWithDefaults = getHeaderAndNavigationWithDefaults(
    headerAndNavigation
  );
  const {
    accent_color: accentColor,
    header_background_color: headerBgColor,
    header_link_color: headerLinkColor,
    header_link_hover_color: headerHoverLinkColor,
    section_navigation_link_color: sectionNavLinkColor,
    section_navigation_link_hover_color: sectionNavHoverLinkColor,
    letter_spacing: letterSpacing,
    fontpreset: fontFamily,
    uppercase,
    weight,
    font,
    textTransform
  } = headerAndNavigationWithDefaults;

  const typography = {
    fontFamily: getFontFamily(fontFamily, font),
    fontWeight: weight,
    textTransform: uppercase ? 'uppercase' : textTransform,
    letterSpacing
  };

  return {
    typography: {
      header: typography,
      sectionNav: typography
    },
    palette: {
      header: {
        accent: {
          main: accentColor
        },
        background: {
          main: headerBgColor
        },
        link: {
          main: headerLinkColor,
          hover: headerHoverLinkColor
        }
      },
      sectionNav: {
        accent: {
          main: accentColor
        },
        link: {
          main: sectionNavLinkColor,
          hover: sectionNavHoverLinkColor
        }
      }
    }
  };
}

export function getItemDescriptionsAndPrices(itemDescriptionsAndPrices = {}) {
  const itemDescriptionsAndPricesWithDefaults = getItemDescriptionsAndPricesWithDefaults(
    itemDescriptionsAndPrices
  );
  const {
    fontpreset: fontFamily,
    letter_spacing: letterSpacing,
    italics,
    weight,
    font,
    fontStyle
  } = itemDescriptionsAndPricesWithDefaults;

  return {
    typography: {
      itemDescriptionsAndPrices: {
        fontFamily: getFontFamily(fontFamily, font),
        fontStyle: italics ? 'italic' : fontStyle,
        fontWeight: weight,
        letterSpacing
      }
    }
  };
}

export function getMenuItemNames(menuItemNames = {}) {
  const menuItemNamesWithDefaults = getMenuItemNamesWithDefaults(menuItemNames);

  const {
    fontpreset: fontFamily,
    letter_spacing: letterSpacing,
    color,
    uppercase,
    weight,
    font,
    textTransform
  } = menuItemNamesWithDefaults;

  return {
    palette: {
      menuItemNames: {
        main: color
      }
    },
    typography: {
      menuItemNames: {
        fontFamily: getFontFamily(fontFamily, font),
        fontWeight: weight,
        textTransform: uppercase ? 'uppercase' : textTransform,
        letterSpacing
      }
    }
  };
}

export function getMenuSectionTitles(menuSectionTitles = {}) {
  const menuSectionTitlesWithDefaults = getMenuSectionTitlesWithDefaults(
    menuSectionTitles
  );

  const {
    fontpreset: fontFamily,
    letter_spacing: letterSpacing,
    color,
    uppercase,
    weight,
    size,
    font
  } = menuSectionTitlesWithDefaults;

  return {
    palette: {
      menuSectionTitles: {
        main: color
      }
    },
    typography: {
      menuSectionTitles: {
        fontFamily: getFontFamily(fontFamily, font),
        fontWeight: weight,
        fontSize: size,
        textTransform: uppercase ? 'uppercase' : 'none',
        letterSpacing
      }
    }
  };
}

export function getErrorStates(errorStates = {}) {
  const errorStatesWithDefaults = getErrorStatesWithDefaults(errorStates);

  const { error_color: errorColor } = errorStatesWithDefaults;

  return {
    palette: {
      error: {
        main: errorColor
      }
    }
  };
}

export function getCustomTypography(customizableFields) {
  const {
    menu_item_names: menuItemNames,
    menu_section_titles: menuSectionTitles,
    header_and_navigation: headerAndNavigation,
    item_descriptions_and_prices: itemDescriptionsAndPrices
  } = customizableFields;

  const { typography: menuItemNamesTypography = {} } = getMenuItemNames(
    menuItemNames
  );
  const { typography: menuSectionTitlesTypography = {} } = getMenuSectionTitles(
    menuSectionTitles
  );
  const {
    typography: headerAndNavigationTypography = {}
  } = getHeaderAndNavigationStyles(headerAndNavigation);
  const {
    typography: itemDescriptionAndPricesTypography = {}
  } = getItemDescriptionsAndPrices(itemDescriptionsAndPrices);

  const customTypography = {
    ...menuItemNamesTypography,
    ...menuSectionTitlesTypography,
    ...headerAndNavigationTypography,
    ...itemDescriptionAndPricesTypography
  };

  return {
    ...baseTypography,
    ...customTypography
  };
}

export function getCustomPalette(customizableFields) {
  const basePalette = createPalette();

  const {
    button_colors: buttonColors,
    menu_item_names: menuItemNames,
    menu_section_titles: menuSectionTitles,
    card_and_background: cardAndBackground,
    header_and_navigation: headerAndNavigation,
    error_states: errorStates
  } = customizableFields;

  const { palette: buttonPalette = {} } = getButtonColors(buttonColors);
  const { palette: menuItemNamesPalette = {} } = getMenuItemNames(
    menuItemNames
  );
  const { palette: menuSectionTitlesPalette = {} } = getMenuSectionTitles(
    menuSectionTitles
  );
  const { palette: cardAndBackgroundPalette = {} } = getCardAndBackgroundStyles(
    cardAndBackground
  );
  const {
    palette: headerAndNavigationPalette = {}
  } = getHeaderAndNavigationStyles(headerAndNavigation);
  const { palette: errorStatesPalette = {} } = getErrorStates(errorStates);

  const customPalette = {
    ...buttonPalette,
    ...menuItemNamesPalette,
    ...menuSectionTitlesPalette,
    ...cardAndBackgroundPalette,
    ...headerAndNavigationPalette,
    ...errorStatesPalette
  };

  return {
    ...basePalette,
    ...customPalette,
    background: {
      default: cardAndBackgroundPalette.page.background.main,
      secondary: DEFAULT_BACKGROUND_VALUES.secondary
    }
  };
}

export function getComponentsStyles({ palette }) {
  const componentsStyles = {};

  const outlineColor = palette.card.outline.main;
  componentsStyles.muiCardOutline = `1px solid ${outlineColor}`;

  return componentsStyles;
}

export function getGlobalStyles({ palette }) {
  return {
    '.cookie-management-preferences-container': {
      color: palette.header.link.main,
      display: 'block',
      width: '100%',
      textAlign: 'center',
      padding: '0.5rem 0',
      textDecoration: 'underline',
      transition: 'all 0.2s ease',
      '&:hover': {
        color: palette.header.link.hover
      }
    }
  };
}
