import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { ROOT } from 'constants/endpoints';

/**
 * Clones an object, and removes all keys that have an empty string value.
 * Necessary to prevent the API from attempting to filter.
 * @param {object} queryObject - object with keys that will map to query params
 * @returns {object} cloned object without empty string values
 */
export const removeEmptyParams = queryObject => {
  const modifiedQueryObject = {};
  const has = Object.prototype.hasOwnProperty;

  Object.entries(queryObject).forEach(([key, value]) => {
    if (has.call(queryObject, key) && queryObject[key] !== '') {
      modifiedQueryObject[key] = value;
    }
  });

  return modifiedQueryObject;
};

/**
 * Takes an object and returns a querystring.
 * @param {object} queryObject - object with keys that will map to query params
 * @returns {string} URI encoded string of concatenated query params
 */
export const serialize = queryObject => {
  const cleansedObject = removeEmptyParams(queryObject);
  const queryString = [];
  const has = Object.prototype.hasOwnProperty;

  Object.entries(cleansedObject).forEach(([key, value]) => {
    if (has.call(cleansedObject, key)) {
      queryString.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(value)
      );
    }
  });

  return queryString.join('&');
};

/**
 * Given a url path, it will return the full API URL for that path which will
 * include the protocol, domain name, port (when necessary), and path
 * @param {string} endpoint - path to the api resource
 * @param {object} queryParams - object with keys that will map to query params
 * @returns {string} - Full API URL to resource
 */
export const buildApiEndpoint = (endpoint, queryParams = null) => {
  let formattedEndpoint = endpoint;
  if (process.env.NODE_ENV !== 'production') {
    if (formattedEndpoint.includes('https')) {
      formattedEndpoint = formattedEndpoint.replace('8080', '8000');
    } else {
      formattedEndpoint = `${ROOT}${formattedEndpoint}`;
    }
  }

  if (queryParams) {
    const queryString = serialize(queryParams);
    formattedEndpoint = `${formattedEndpoint}?${queryString}`;
  }
  return formattedEndpoint;
};

export const withQueryString = (endpoint, queryParams = {}) => {
  if (!isEmpty(queryParams)) {
    const queryString = qs.stringify(queryParams);
    return `${endpoint}?${queryString}`;
  }
  return endpoint;
};
