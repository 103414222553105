import { errorTypes } from 'catering/constants/errors';

export const DATADOG_SITE = 'datadoghq.com';
export const DATADOG_SERVICE = 'online_ordering';
export const KNOWN_ERROR_CODES = [
  errorTypes.ITEM_NOT_ON_MENU,
  errorTypes.ADDRESS_VALIDATION_ERROR,
  errorTypes.CLOSED_LOCATION,
  errorTypes.CART_IS_EMPTY,
  errorTypes.NOT_ACCEPTING_ORDERS,
  errorTypes.DELETED_MENU,
  errorTypes.ORDER_MINIMUM_NOT_MET,
  errorTypes.ITEM_MINIMUM_NOT_MET,
  errorTypes.INVALID_FULFILLMENT_TIME,
  errorTypes.LOW_INVENTORY,
  errorTypes.PICKUP_ADDRESS_CHANGED
];

export const KNOWN_ERROR_MESSAGES = [
  {
    substring1: 'Catering Location with slug',
    substring2: 'could not be found.'
  },
  {
    substring1: 'Catering Location with slug',
    substring2: 'does not deliver to'
  },
  {
    substring1: 'No fulfillment hours found within 12 months'
  },
  {
    substring1: 'This item is not available at this time.'
  },
  {
    substring1: '"shipping_street_address_1":["This field may not be blank."]'
  },
  {
    substring1: '"shipping_postal_code":["This field may not be blank."]'
  },
  {
    substring1: 'Does not match order minimum'
  },
  {
    substring1: 'Catering Cart Item with id',
    substring2: 'could not be found.'
  },
  {
    substring1: 'Selected fulfillment time is not valid'
  }
];
