import moment from 'moment';
import { getDataForLocation } from 'bento-ordering/locations/selectors/byLocationId';

import {
  getFirstTimeThatFallsOutsideOfWindows,
  isMidnight,
  getNearestDatetimeIntervalAfter
} from 'bento-ordering/base/utils/timeHelpers';
import {
  DATE_TIME_FORMAT,
  DATE_INPUT_FORMAT
} from 'bento-ordering/base/constants/timeFormat';
import { fulfillmentOptions } from 'constants/fulfillmentOptions';
import { MINUTES_IN_ORDER_THROTTLE_WINDOW } from 'bento-ordering/locations/constants/orderThrottleSettings';
import getDeliveryPrepByTimeForThrottles from 'bento-ordering/locations/utils/getDeliveryPrepByTimeForThrottles';
import createDeepEqualSelector from 'bento-ordering/utils/createDeepEqualSelector';
/**
 * next available time given an input time compared to throttled times
 * for a location
 * @param {{}} params
 * @param {number} params.onlineOrderingLocation id
 * @param {{}} params.locationThrottledTimes state from redux
 * @param {string} params.dateTime representing datetime
 * @return {string} datetime
 */
export const getNextAvailableTimeFromThrottlesSelector = ({
  onlineOrderingLocation,
  locationThrottledTimes,
  dateTime
}) => {
  const locationThrottledTimesForLocation = getDataForLocation(
    locationThrottledTimes,
    onlineOrderingLocation
  );

  return getFirstTimeThatFallsOutsideOfWindows(
    locationThrottledTimesForLocation.map(
      throttledTime => throttledTime.start_date_time_of_window
    ),
    dateTime
  );
};

/**
 * to get the next available time from
 * throttles for delivery, we have
 * to take into account delivery time
 * in order
 * @param {{}} param
 * @param {{}} param.cart cart or cartWorkingCopy from redux state - this must include the total_delivery_time key
 * @param {string} param.firstAvailableDeliveryTime - eg '15:00'
 * @param {string} param.firstAvailableDeliveryDate - eg '2019-05-01'
 * @param {{}} param.locationThrottledTimes - location throttled times from state
 * @return {string} - datetime string
 */
export const getNextAvailableTimeFromThrottlesForDeliverySelector = ({
  cart,
  firstAvailableDeliveryTime,
  firstAvailableDeliveryDate,
  locationThrottledTimes
}) => {
  // to get the prepared by time so that we can compare,
  // to order throttles window start times, we have to subtract delivery time in minutes
  const firstPreparedByTime = moment(
    `${firstAvailableDeliveryDate}T${firstAvailableDeliveryTime}`,
    DATE_TIME_FORMAT
  ).subtract(cart.total_delivery_time, 'minutes');

  // get the next available prepared by time by using the prepared by time
  // calculated above
  const nextAvailablePreparedByTimeWithThrottles = locationThrottledTimesSelectors.getNextAvailableTimeFromThrottlesSelector(
    {
      onlineOrderingLocation: cart.online_ordering_location,
      locationThrottledTimes,
      dateTime: firstPreparedByTime.format(DATE_TIME_FORMAT)
    }
  );

  // add back delivery time to get the fulfillment time
  const nextAvailableFulfillmentTimeWithThrottles = moment(
    nextAvailablePreparedByTimeWithThrottles
  )
    .add(cart.total_delivery_time, 'minutes')
    .format(DATE_TIME_FORMAT);

  // round up to nearest 15 minute interval
  return getNearestDatetimeIntervalAfter(
    nextAvailableFulfillmentTimeWithThrottles,
    MINUTES_IN_ORDER_THROTTLE_WINDOW
  );
};

/**
 * Builds throttled date times for quicker lookup.
 *
 * Keys point to array of moment objects for each throttled date time instance
 *
 * @param {{start_date_time_of_window: string}[]}locationThrottledTimesForLocation
 * @return {{}}
 */
const selectThrottledTimesByDate = createDeepEqualSelector(
  locationThrottledTimesForLocation => locationThrottledTimesForLocation,
  locationThrottledTimesForLocation => {
    const locationThrottledTimeMomentByDate = (
      locationThrottledTimesForLocation || []
    ).reduce((acc, curr) => {
      const throttledMoment = moment(
        curr.start_date_time_of_window,
        DATE_TIME_FORMAT
      );
      const dateString = throttledMoment.format(DATE_INPUT_FORMAT);
      if (!Object.prototype.hasOwnProperty.call(acc, dateString)) {
        acc[dateString] = [];
      }
      acc[dateString].push(throttledMoment);
      return acc;
    }, {});

    return locationThrottledTimeMomentByDate;
  }
);

/**
 * combine a list of times with throttled times to disable
 * options that are not available because of throttles
 *
 * handles overnight hours by doing the comparison with throttles by adding a day to the date
 * @param {{}} params
 * @param {string[]} params.times - eg ['10:00', '10:15']
 * @param {string} params.selectedDate - date in YYYY-MM-DD
 * @param {string} params.selectedMethod - PICKUP or DELIVERY
 * @param {{}[]} params.locationThrottledTimesForLocation - locationThrottledTimes for a given location from redux
 * @return {{}} timeObject
 * @return {string} timeObject.time - '18:00'
 * @return {boolean} timeObject.disabled
 */
export const selectCombineHoursWithLocationThrottles = ({
  times,
  locationThrottledTimesForLocation = [],
  selectedDate,
  selectedMethod,
  totalDeliveryTime
}) => {
  let processedDate = selectedDate;

  const locationThrottledTimeMomentByDate = locationThrottledTimesSelectors.selectThrottledTimesByDate(
    locationThrottledTimesForLocation
  );
  return times.map((time, currIndex) => {
    // we process the date to take into account overnight hours.
    // we know we need to add a day to the date only if
    // the current time we are iterating over is midnight and
    // it is not the first member of the array
    // (if it were the first member of the array, then
    // we want to keep the date as is)
    const shouldAddDayToDate = currIndex !== 0 && isMidnight(time);
    if (shouldAddDayToDate) {
      processedDate = moment(selectedDate, DATE_INPUT_FORMAT)
        .add(1, 'day')
        .format(DATE_INPUT_FORMAT);
    }
    const fulfillmentDatetimeMoment = moment(
      `${processedDate}T${time}`,
      DATE_TIME_FORMAT
    );
    let preparedByDatetimeMoment = fulfillmentDatetimeMoment;
    if (selectedMethod === fulfillmentOptions.DELIVERY) {
      preparedByDatetimeMoment = moment(
        getDeliveryPrepByTimeForThrottles(
          preparedByDatetimeMoment,
          totalDeliveryTime
        )
      );
    }
    const preparedByDateString = preparedByDatetimeMoment.format(
      DATE_INPUT_FORMAT
    );

    const matchingThrottledTime = (
      locationThrottledTimeMomentByDate[preparedByDateString] || []
    ).find(throttledMoment => {
      return throttledMoment.isSame(moment(preparedByDatetimeMoment));
    });
    // if there is a matching throttled time, then we want to disable this option
    const disabled = !!matchingThrottledTime;
    return {
      time,
      disabled
    };
  });
};

const locationThrottledTimesSelectors = {
  getNextAvailableTimeFromThrottlesSelector,
  getNextAvailableTimeFromThrottlesForDeliverySelector,
  selectCombineHoursWithLocationThrottles,
  selectThrottledTimesByDate
};

export default locationThrottledTimesSelectors;
