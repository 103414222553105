import { getLocationThrottledTimesEndpoint } from 'bento-ordering/base/constants/endpoints';
import { apiAction } from 'bento-ordering/base/utils/apiHelpers';

import { LOCATION_THROTTLED_TIMES } from 'bento-ordering/locations/constants/entities';
import getByLocationActionTypeBuilder from 'bento-ordering/locations/actions/byLocationIdActionTypes';

/**
 * @function
 *
 *  that can be used to build action types for locationThrottledTimes entity with location id in meta
 */
export const getLocationThrottledTimesActionTypes = getByLocationActionTypeBuilder(
  LOCATION_THROTTLED_TIMES
);
/**
 * dispatch apiAction to locationThrottledEndpoint
 * @param {number} locationId
 * @return {function}
 */
export const fetchLocationThrottledTimes = locationId =>
  function fetchLocationThrottledTimesAction(dispatch) {
    if (!locationId) {
      console.warn(
        'fetchLocationThrottledTimes was called without an online ordering location id'
      );
      return null;
    }

    const endpoint = getLocationThrottledTimesEndpoint(locationId);

    const actionTypes = getLocationThrottledTimesActionTypes(locationId);
    dispatch(apiAction(endpoint, 'GET', actionTypes));
  };

/**
 * dispatch fetchLocationThrottledTimes with the location id defined on the cart
 * does not dispatch fetch if no online ordering location on the cart or
 * if the throttled times have already been fetched for the location or if
 * if there is already fetching occurring (isLoading is true)
 * @return {function}
 */
export const fetchCartLocationThrottledTimes = () =>
  function fetchCartLocationThrottledTimesDispatch(dispatch, getState) {
    const { cart, locationThrottledTimes } = getState();
    const onlineOrderingLocation = cart.online_ordering_location;
    if (!onlineOrderingLocation) {
      console.warn(
        'fetchCartLocationThrottledTimes called when there was no online ordering location on the cart'
      );
      return null;
    }

    if (
      locationThrottledTimes.byLocationId[onlineOrderingLocation] ||
      // don't fetch twice before the first result has returned
      // example: on order settings on desktop, we call to fetch
      // but also on menu manager
      locationThrottledTimes.isLoading
    ) {
      return null;
    }

    dispatch(
      locationThrottledModule.fetchLocationThrottledTimes(
        onlineOrderingLocation
      )
    );
  };

const locationThrottledModule = {
  fetchLocationThrottledTimes,
  fetchCartLocationThrottledTimes
};

export default locationThrottledModule;
