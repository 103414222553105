import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { RSAA } from 'redux-api-middleware';
import { buildApiEndpoint } from 'helpers/apiHelpers';

export const apiAction = (endpoint, method, types, data, queryParams) => {
  const formattedEndpoint = buildApiEndpoint(endpoint, queryParams);

  const headers = {
    'X-CSRFToken': Cookies.get('csrftoken'),
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  };

  const action = {
    headers: headers,
    // credentials: 'same-origin',
    credentials: 'include',
    endpoint: formattedEndpoint,
    method,
    types,
    body: data ? JSON.stringify(data) : undefined
  };

  return {
    [RSAA]: action
  };
};

export const createAxios = () => {
  const csrfToken = Cookies.get('csrftoken');

  const headers = {
    'X-CSRFToken': csrfToken
  };

  return axios.create({
    headers: headers
  });
};

// We can wrap the promises returned by axios with makeCancelable and then
// cancel the promise in componentWillUnmount.  This will prevent the promise
// from resolving if a component has been unmouunted and triggering an error.

// https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val =>
      hasCanceled_ ? reject(new Error({ isCanceled: true })) : resolve(val)
    );
    promise.catch(error =>
      hasCanceled_ ? reject(new Error({ isCanceled: true })) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const exportData = function() {
  window.location.replace(this.constructor.exportEndpoint);
};

export const puke = object => <pre>{JSON.stringify(object, null, ' ')}</pre>;

export const convertArrayToObject = array => {
  return array
    ? array.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {})
    : {};
};

export const convertToArray = data => (Array.isArray(data) ? data : [data]);

// Note that sort is done on the array in place.  It
// does not return a new array
export function orderAllIdsByOrderProperty(allIds, byId) {
  function compareByOrder(a, b) {
    return byId[a].order - byId[b].order;
  }
  allIds.sort(compareByOrder);
}

export const convertApiItemsToOptions = apiItem =>
  apiItem.allIds.map(value => ({
    value: `${value}`,
    label: apiItem.byId[value].name
  }));
