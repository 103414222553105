import { LOCATION } from 'bento-ordering/locations/constants/entities';

export const LOCATION_BASE = '/';
export const LOCATION_DETAIL = `${LOCATION_BASE}:slug`;
export const LOCATION_OFFLINE = '/offline';
export const LOCATION_ASAP_OFFLINE = '/asap-offline';
export const entityPathMap = {
  [LOCATION]: {
    list: LOCATION_BASE,
    detail: LOCATION_DETAIL,
    offline: LOCATION_OFFLINE,
    asapOffline: LOCATION_ASAP_OFFLINE
  }
};
