import get from 'lodash/get';

import byLocationIdReducerFactory from 'bento-ordering/locations/reducers/byLocationIdReducerFactory';
import { LOCATION_INVENTORY } from 'bento-ordering/locations/constants/entities';
import { getLocationInventoryActionTypes } from 'bento-ordering/locations/actions/locationInventory';

const processSuccessPayload = payload => payload.products_with_inventory;
const actionTypes = getLocationInventoryActionTypes().map(
  actionType => actionType.type
);
const locationInventory = byLocationIdReducerFactory(
  LOCATION_INVENTORY,
  actionTypes,
  processSuccessPayload
);

export default locationInventory;

// SELECTORS
export const getProductsWithInventoryForLocation = (
  locationInventory,
  locationId
) => {
  return get(locationInventory, ['byLocationId', locationId], []);
};
