import moment from 'moment';
import {
  DATE_INPUT_FORMAT,
  MILITARY_TIME,
  DATE_TIME_FORMAT,
  MIDNIGHT,
  REOPENS_STORE_AT_INPUT_FORMAT,
  REOPENS_STORE_AT_OUTPUT_FORMAT
} from 'bento-ordering/base/constants/timeFormat';
import { MINUTES_IN_ORDER_THROTTLE_WINDOW } from 'bento-ordering/locations/constants/orderThrottleSettings';

export const get15MinIntervals = (start, end) => {
  const intervals = [];
  const counter = moment(start, MILITARY_TIME);
  const doesEndAtMidnight = isMidnight(end);

  let endWithMidnight = moment(end, MILITARY_TIME);
  if (doesEndAtMidnight) {
    endWithMidnight = moment('23:59', MILITARY_TIME);
  }
  while (counter.isSameOrBefore(endWithMidnight)) {
    intervals.push(counter.format(MILITARY_TIME));
    counter.add('m', 15);
  }
  if (doesEndAtMidnight) {
    intervals.push(moment(end, MILITARY_TIME).format(MILITARY_TIME));
  }
  return intervals;
};

/*
 given a date in format 2020-04-09, return the following date
 in the same format 2020-04-10
*/
export const getNextDay = dateString => {
  const momentDate = moment(dateString, DATE_INPUT_FORMAT);
  const nextDay = momentDate.add(1, 'days');
  return nextDay.format(DATE_INPUT_FORMAT);
};

export const combineDateTime = (date, time) => `${date}T${time}`;

export const convertMilitaryTimeToMoment = time => moment(time, MILITARY_TIME);
export const combineDateWithCloseTime = (date, time) =>
  getMidnightSafeEndDateTime(date, time).format(DATE_TIME_FORMAT);

/**
 * determines if a time string is midnight
 * @param {string} time - should just be the time, not include date, like '18:00'
 * @return {boolean}
 */
export const isMidnight = time => {
  return moment(time, MILITARY_TIME).isSame(moment(MIDNIGHT, MILITARY_TIME));
};
// only call on menu end time. open time should be left as midnight MIDNIGHT
export const getMidnightSafeEndDateTime = (date, time) => {
  const momentifiedDateTime = momentifyDateTime(date, time);
  if (isMidnight(time)) {
    return momentifiedDateTime.add(1, 'day');
  }
  return momentifiedDateTime;
};

export const convertDateToMoment = date => moment(date, DATE_INPUT_FORMAT);

export const momentifyDateTime = (date, time) =>
  moment(combineDateTime(date, time), DATE_TIME_FORMAT);

export const isDateTimeWithinMenuHoursRange = (date, time, open, close) => {
  const momentifiedDateTime = momentifyDateTime(date, time);
  const midnightSafeEndDateTime = getMidnightSafeEndDateTime(date, close);

  return (
    momentifiedDateTime.isSameOrAfter(momentifyDateTime(date, open)) &&
    momentifiedDateTime.isSameOrBefore(midnightSafeEndDateTime)
  );
};
export const isDateTimeBeforeMenuHoursRangeEnd = (date, time, close) => {
  const momentifiedDateTime = momentifyDateTime(date, time);
  return momentifiedDateTime.isSameOrBefore(
    getMidnightSafeEndDateTime(date, close)
  );
};
/**
 * get the datetime that is nearest based on an interval value of minutes
 * (rounded down)
 * @param {string} datetime
 * @param {number} interval
 * @return {string} representing datetime '2019-05-06 18:00'
 */
export const getNearestDatetimeInterval = (datetime, interval) => {
  const minutes = moment(datetime, DATE_TIME_FORMAT).minutes();
  const roundedDownQuotient = Math.floor(minutes / interval);
  const nearestMinutesWindow = roundedDownQuotient * interval;
  return moment(datetime)
    .minutes(nearestMinutesWindow)
    .format(DATE_TIME_FORMAT);
};

/**
 * get the datetime that is nearest based on an interval value of minutes
 * (rounded up)
 * @param {string} datetime
 * @param {number} interval
 * @return {string} representing datetime '2019-05-06 18:00'
 */
export const getNearestDatetimeIntervalAfter = (datetime, interval) => {
  const minutes = moment(datetime, DATE_TIME_FORMAT).minutes();
  const roundedDownQuotient = Math.floor(minutes / interval) + 1;
  const nearestMinutesWindow = roundedDownQuotient * interval;
  return moment(datetime)
    .minutes(nearestMinutesWindow)
    .format(DATE_TIME_FORMAT);
};

/**
 * Find the first time that falls outside of list of windows (recursive function)
 * @param {string[]} listOfStartTimes - each string represents the beginning of a window ['2019-05-04T18:00', ...]
 * @param {string} time - the time we want to get closest to
 * @param {boolean} [shouldRoundToNearestInterval=true] - rounds down the input time to nearest interval - only set to false on recursive calls
 * @param {number} [timeInterval=15] - represents the length of input windows (should always be 15 minutes, but in case this changes in the future)
 * @return {string} representing first datetime that falls outside list of windows
 */
export const getFirstTimeThatFallsOutsideOfWindows = (
  listOfStartTimes,
  time,
  shouldRoundToNearestInterval = true,
  timeInterval = MINUTES_IN_ORDER_THROTTLE_WINDOW
) => {
  const processedTime = shouldRoundToNearestInterval
    ? getNearestDatetimeInterval(time, timeInterval)
    : time;
  const throttledTime = listOfStartTimes.find(throttledTime => {
    return moment(getNearestDatetimeInterval(processedTime)).isSame(
      throttledTime
    );
  });

  if (throttledTime) {
    const nextTimeWindowStart = moment(processedTime)
      .add(timeInterval, 'minutes')
      .format(DATE_TIME_FORMAT);

    const DO_NOT_ROUND_TO_NEAREST_INTERVAL = false;
    return getFirstTimeThatFallsOutsideOfWindows(
      listOfStartTimes,
      nextTimeWindowStart,
      DO_NOT_ROUND_TO_NEAREST_INTERVAL
    );
  } else {
    return time;
  }
};

/**
 * Format a datetime string with a given input format into the output format.
 *
 * @param {*} datetimeString the datetime we want to format
 * @param {*} inputFormat the input format of the datetime
 * @param {*} outputFormat the output format of the datetime
 * @returns a string date formatted using the output format
 */
export const formatDateTime = (
  datetimeString,
  inputFormat = REOPENS_STORE_AT_INPUT_FORMAT,
  outputFormat = REOPENS_STORE_AT_OUTPUT_FORMAT
) => moment(datetimeString, inputFormat).format(outputFormat);

const timeHelpersModule = {
  isDateTimeWithinMenuHoursRange,
  isDateTimeBeforeMenuHoursRangeEnd,
  convertMilitaryTimeToMoment,
  getMidnightSafeEndDateTime
};

export default timeHelpersModule;
