import get from 'lodash/get';

/**
 * factory to build reducers that know how to divide up state based on location id
 * @param {string} entity
 * @return {function}
 */
const byLocationIdReducerFactory = (
  entity,
  actionTypes,
  processSuccessPayload = payload => payload
) => {
  return (
    state = { byLocationId: {}, isLoading: false, error: '' },
    action
  ) => {
    switch (action.type) {
      // REQUEST action type
      case actionTypes[0]: {
        return {
          ...state,
          isLoading: true,
          error: ''
        };
      }
      // SUCCESS action type
      case actionTypes[1]:
        if (!action.meta || !action.meta.locationId) {
          throw Error(
            `must specify meta for success action type with locationId key for ${entity} reducer created with byLocationIdReducerFactory`
          );
        }

        return {
          ...state,
          byLocationId: {
            ...state.byLocationId,
            [action.meta.locationId]: processSuccessPayload(action.payload)
          },
          isLoading: false,
          error: ''
        };
      // FAILURE action type
      case actionTypes[2]:
        return {
          ...state,
          isLoading: false,
          error: get(action, 'payload.response.error', '')
        };
      default:
        return state;
    }
  };
};

export default byLocationIdReducerFactory;
