export const handleSaveError = function(error) {
  // Axios and jQuery format the returned error differently.
  let errorMessage;
  if (error.responseJSON) {
    errorMessage = getErrorMessage(error.responseJSON);
  } else {
    errorMessage = getErrorMessage(error.response.data);
  }
  this.setState({
    isSaving: false,
    errorMessage
  });
};

export const resetErrorMessage = function() {
  this.setState({
    errorMessage: ''
  });
};

export const getErrorMessage = error => {
  let errorMessage = 'Uh oh.';

  if (typeof error !== 'object') {
    errorMessage +=
      ' Something went wrong. Please try again shortly or contact support.';
    return errorMessage;
  }
  const detail = error.detail;

  // Detail will be a string.
  if (detail) {
    errorMessage += getErrorMessageFromDetail(detail);
    return errorMessage;
  }

  if (typeof error === 'object') {
    const errorCount = Object.keys(error).length;
    const errorNoun = errorCount > 1 ? 'errors' : 'error';
    errorMessage = `${errorCount} ${errorNoun}! Please update the indicated fields.`;
  }

  return errorMessage;
};

function getErrorMessageFromDetail(detail) {
  let errorMessage = '';

  if (detail.indexOf('CSRF') !== -1) {
    errorMessage +=
      ' There was an error with this request. Please try logging out and back in.';
  } else if (detail.indexOf('Authentication') !== -1) {
    errorMessage +=
      ' It looks like your account is logged out. Please log back in and try saving again.';
  } else {
    errorMessage += ' The request failed because: ' + detail;
  }

  return errorMessage;
}

export const getFieldErrors = (statusCode, response) => {
  if (statusCode === 400 && response.constructor === Object) {
    return response;
  }
  return {};
};
