import { apiMiddleware } from 'redux-api-middleware';
import { configureStore as configStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from 'config/rootReducer';
import getBasePath from 'bento-ordering/utils/getBasePath';
import { accountHasInvestorDayFeatureFlag } from 'bento-ordering/utils/featureFlags/hasInvestorDayFeatureFlag';

// Cache basePath set on full page load
const _basename = getBasePath();

export const browserHistory = createBrowserHistory({
  basename: _basename
});

browserHistory.listen(() => {
  // This is required to prevent client side navigation between 2 base paths
  // from creating a broken application state.
  if (_basename !== getBasePath()) {
    window.location.reload();
  }
});

export const testHistory = createMemoryHistory({
  basename: _basename
});

let prevQueryParams = null;
const persistQueryParamsMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { location } = action.payload;
    const queryParams = location.search;
    const state = store.getState();
    const hasInvestorDayFeature = accountHasInvestorDayFeatureFlag(
      state.account
    );

    // if the previous location had query params, but the new (current) one doesn't, add it
    if (hasInvestorDayFeature && !queryParams && prevQueryParams) {
      const newAction = {
        ...action,
        payload: {
          ...action.payload,
          location: {
            ...action.payload.location,
            search: prevQueryParams
          }
        }
      };
      return next(newAction);
    } else if (queryParams) {
      // when we get new params -> update
      prevQueryParams = queryParams;
    }
  }
  return next(action);
};

export const configureStore = (preloadedState, history = browserHistory) => {
  return configStore({
    reducer: rootReducer(history),
    middleware: [
      apiMiddleware,
      thunk,
      routerMiddleware(history),
      persistQueryParamsMiddleware
    ],
    preloadedState
  });
};
