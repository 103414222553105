import {
  apiAction,
  convertArrayToObject,
  convertToArray
} from 'bento-ordering/base/utils/apiHelpers';
import { entityEndpointMap } from 'bento-ordering/base/constants/endpoints';
import {
  LIST_ORDER_UPDATE_REQUEST,
  LIST_ORDER_UPDATE_SUCCESS,
  LIST_ORDER_UPDATE_FAILURE,
  RESET_API_STATE,
  RESET_FIELD_ERRORS,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  CLONE_REQUEST,
  CLONE_SUCCESS,
  CLONE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE
} from 'bento-ordering/base/actions/actionTypes';

export const formatListResponse = (payload, isPaginated = false) => {
  // for some endpoints, (stripe and square integrations), there is only 1 item object returned, so we manually place the item within an array
  const entities = isPaginated ? payload.results : convertToArray(payload);

  const byId = convertArrayToObject(entities);
  const allIds = entities.map(item => item.id);
  const processedPayload = { byId, allIds };

  return processedPayload;
};

// actionTypes order matters. must be REQUEST, SUCCESS, FAILURE
export const getListTypes = (entity, actionTypes, queryParams, meta = {}) => {
  return [
    { type: `[${entity}] ${actionTypes[0]}`, meta: { entity } },
    {
      type: `[${entity}] ${actionTypes[1]}`,
      payload: (action, state, response) => {
        const contentType = response.headers.get('Content-Type');

        if (contentType && ~contentType.indexOf('json')) {
          return response.json().then(json => {
            // The returned data is paginated if they are not in an array
            // and if a results key exists.
            const isPaginated = !Array.isArray(json) && !!json.results;
            const payload = formatListResponse(json, isPaginated);

            if (isPaginated && queryParams) {
              payload.count = json.count;
              payload.totalPages = Math.ceil(json.count / queryParams.per_page);
            }

            return payload;
          });
        }
      },
      meta: { entity, lastFetched: Date.now(), ...meta }
    },
    { type: `[${entity}] ${actionTypes[2]}`, meta: { entity } }
  ];
};
export function fetchList(entity, queryParams, meta = {}) {
  const endpoint = entityEndpointMap[entity];
  const actionTypes = [
    FETCH_LIST_REQUEST,
    FETCH_LIST_SUCCESS,
    FETCH_LIST_FAILURE
  ];

  const types = getListTypes(entity, actionTypes, queryParams, meta);

  return apiAction(endpoint, 'GET', types, null, queryParams);
}

// TODO: the above fetchList function should be replaced with 1) this function and 2) middleware to
// format the data.
export function fetchListWithoutFormatting(entity, queryParams, meta = {}) {
  const endpoint = entityEndpointMap[entity];
  const finalMeta = { entity, ...meta };
  const types = [
    { type: `[${entity}] ${FETCH_LIST_REQUEST}`, meta: finalMeta },
    { type: `[${entity}] ${FETCH_LIST_SUCCESS}`, meta: finalMeta },
    { type: `[${entity}] ${FETCH_LIST_FAILURE}`, meta: finalMeta }
  ];
  return apiAction(endpoint, 'GET', types, null, queryParams);
}

export function deleteEntity(entity, id, meta = {}) {
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = `${baseEndpoint}/${id}`;
  const finalMeta = { entity, ...meta };
  const types = [
    {
      type: `[${entity}] ${DELETE_REQUEST}`,
      payload: (action, state, response) => ({ id }),
      meta: finalMeta
    },
    {
      type: `[${entity}] ${DELETE_SUCCESS}`,
      payload: (action, state, response) => ({ id }),
      meta: finalMeta
    },
    { type: `[${entity}] ${DELETE_FAILURE}`, meta: finalMeta }
  ];

  return apiAction(endpoint, 'DELETE', types);
}

export function cloneEntity(entity, id) {
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = `${baseEndpoint}/${id}/clone`;
  const types = [
    { type: `[${entity}] ${CLONE_REQUEST}`, meta: { entity } },
    { type: `[${entity}] ${CLONE_SUCCESS}`, meta: { source: id, entity } },
    { type: `[${entity}] ${CLONE_FAILURE}`, meta: { entity } }
  ];

  return apiAction(endpoint, 'POST', types, {});
}

export function createEntity(entity, data) {
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = baseEndpoint;
  const types = [
    { type: `[${entity}] ${CREATE_REQUEST}`, meta: { entity } },
    { type: `[${entity}] ${CREATE_SUCCESS}`, meta: { entity } },
    { type: `[${entity}] ${CREATE_FAILURE}`, meta: { entity } }
  ];

  return apiAction(endpoint, 'POST', types, data);
}

export function fetchEntity({ entity, id, data, queryParams }) {
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = `${baseEndpoint}${id ? `/${id}` : ''}`;
  const types = [
    { type: `[${entity}] ${FETCH_REQUEST}`, meta: { entity } },
    { type: `[${entity}] ${FETCH_SUCCESS}`, meta: { entity } },
    { type: `[${entity}] ${FETCH_FAILURE}`, meta: { entity } }
  ];

  return apiAction(endpoint, 'GET', types, data, queryParams);
}

export function saveWorkingCopy(entity) {
  return function(dispatch, getState) {
    const workingCopy = getState()[entity].workingCopy;
    return workingCopy.id
      ? dispatch(updateEntity(entity, workingCopy.id, workingCopy))
      : dispatch(createEntity(entity, workingCopy));
  };
}

// Default is to use PUT.
export function updateEntity(entity, id, data, patch = false) {
  const method = patch ? 'PATCH' : 'PUT';
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = `${baseEndpoint}/${id}`;
  const types = [
    { type: `[${entity}] ${UPDATE_REQUEST}`, meta: { entity } },
    { type: `[${entity}] ${UPDATE_SUCCESS}`, meta: { entity } },
    { type: `[${entity}] ${UPDATE_FAILURE}`, meta: { entity } }
  ];

  return apiAction(endpoint, method, types, data);
}

export function saveListOrder(entity, allIds) {
  const baseEndpoint = entityEndpointMap[entity];
  const endpoint = `${baseEndpoint}/reorder`;
  const types = [
    { type: `[${entity}] ${LIST_ORDER_UPDATE_REQUEST}`, meta: { entity } },
    { type: `[${entity}] ${LIST_ORDER_UPDATE_SUCCESS}`, meta: { entity } },
    { type: `[${entity}] ${LIST_ORDER_UPDATE_FAILURE}`, meta: { entity } }
  ];

  return apiAction(endpoint, 'POST', types, allIds);
}

export function resetFieldErrors(entity) {
  return {
    type: `[${entity}] ${RESET_FIELD_ERRORS}`
  };
}

export function resetApiState(entity) {
  return {
    type: `[${entity}] ${RESET_API_STATE}`
  };
}
