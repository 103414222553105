import { ROOT, BASE_CART_ENDPOINT } from 'constants/endpoints';
import { LOCATION } from 'bento-ordering/locations/constants/entities';
import { ACCOUNT } from 'bento-ordering/account/constants/entities';
import { CART } from 'bento-ordering/cart/constants/entities';

export const LINK_TO_BENTO_WEBSITE = '/';

export const BASE =
  process.env.NODE_ENV === 'production' ? '/api' : `${ROOT}/api`;

export const LOCATION_ENDPOINT = `${BASE}/online_ordering_location_public/`;
export const ACCOUNT_ENDPOINT = `${BASE}/online_ordering_account_public/`;
export const MENU_BASE = `${BASE}/online_ordering_menu_public/`;
export const VALIDATE_CART_ENDPOINT = `${BASE}/validate_cart/`;
export const RESET_PICKUP_ADDRESS_ENDPOINT = `${BASE}/reset_pickup_address/`;

export const MENU_ENDPOINT = `${BASE}/online_ordering/menu/`;
export const getMenuEndpoint = menuId => `${MENU_ENDPOINT}${menuId}/`;
const MENU_ITEMS_DETAIL_ENDPOINT = `${BASE}/online_ordering_menu_details/`;
export const getMenuDetailEndpoint = menuId =>
  `${MENU_ITEMS_DETAIL_ENDPOINT}${menuId}/`;
export const CART_ITEMS_AVAILABLE_ENDPOINT = `${BASE}/online_ordering_menu_public/menu/cart/available/`;

export const CART_JSON_ENDPOINT = `${BASE_CART_ENDPOINT}/json`;
export const BASE_CART_ITEM_ENDPOINT = '/store/cart_item';
export const SETUP_INITIAL_DATA_ENDPOINT = `${BASE}/online_ordering/initial_data/`;
export const VALIDATE_ADDRESS_ENDPOINT = `${BASE}/online-ordering/cart/address/validate/`;
export const RELATE_LOCATIONS_TO_CART_ADDRESS_ENDPOINT = `${BASE}/relate_locations_to_cart_address/`;

export const BASE_OO_CART_ENDPOINT = `/store/online_ordering_cart`;
export const OO_CART_API_ENDPOINT = `${BASE_OO_CART_ENDPOINT}/api`;
export const OO_CART_LOCATION_API_ENDPOINT = `${BASE}/set_cart_location`;
export const OO_CART_ADD_ENDPOINT = `${BASE_OO_CART_ENDPOINT}/add`;
export const BASE_LOCATION_INVENTORY_ENDPOINT = `${BASE}/location_inventory`;
export const BASE_LOCATION_THROTTLED_TIMES_ENDPOINT = `${BASE}/v2-location_throttled_times`;
export const BASE_GROUP_ORDERING_API_ENDPOINT = `${BASE}/group-ordering/`;

export const getUpdateCartItemEndpoint = cartItemId =>
  `${BASE_CART_ITEM_ENDPOINT}/${cartItemId}`;

export const getLocationInventoryEndpoint = locationId =>
  `${BASE_LOCATION_INVENTORY_ENDPOINT}/${locationId}/`;

export const getLocationThrottledTimesEndpoint = locationId =>
  `${BASE_LOCATION_THROTTLED_TIMES_ENDPOINT}/${locationId}/`;

// make these paths more restful
export const getMenuItemEndpoint = menuItemSlug =>
  `${BASE}/online_ordering_menu_public/menu/${menuItemSlug}/`;

export const getUpdatedLocationFulfillmentOptions = locationId =>
  `${BASE}/location_fulfillment_options/${locationId}`;

export const getGroupOrderEndpoint = groupOrderSlug =>
  `${BASE_GROUP_ORDERING_API_ENDPOINT}${groupOrderSlug}/`;

export const getBentoStreamEndpoint = cartSlug =>
  `${BASE}/public/bento_stream/access_room/?room_id=${cartSlug}`;

export const getGroupOrderGuestEndpoint = groupOrderSlug =>
  `${BASE_GROUP_ORDERING_API_ENDPOINT}${groupOrderSlug}/guest/`;

export const getRemoveFromGroupOrderEndpoint = (groupOrderSlug, uuid) =>
  `${BASE_GROUP_ORDERING_API_ENDPOINT}${groupOrderSlug}/guest/${uuid}/`;

export const getMenuItemPricePreviewEndpoint = productId =>
  `${BASE}/online_ordering/cart/product/price_preview/${productId}`;

export const entityEndpointMap = {
  [LOCATION]: LOCATION_ENDPOINT,
  [ACCOUNT]: ACCOUNT_ENDPOINT,
  [CART]: BASE_CART_ENDPOINT
};
