export const WHITE = '#ffffff';
export const MEDIUM_TOFU = '#e5e5e6';
export const LIGHT_SOY = '#babcc1';
export const MEDIUM_SOY = '#696970';
export const SOY = '#30323b';

export const PEACH = '#ffdbcf';
export const PLUM = '#84565e';
export const TOMATO = '#f15541';
export const ERROR = '#c7254e';

export const BOK_CHOY = '#5bca81';

export const NO_OPACITY_WHITE = 'rgba(255, 255, 255, 0)';

export const TOFU = '#f5f5f6';

export const DEFAULT_PRIMARY_COLOR = PEACH;
export const DEFAULT_SECONDARY_COLOR = PLUM;
export const DEFAULT_BODY_COLOR = WHITE;

export const createPalette = (
  primaryColor = DEFAULT_PRIMARY_COLOR,
  secondaryColor = DEFAULT_SECONDARY_COLOR,
  bodyColor = DEFAULT_BODY_COLOR
) => ({
  primary: {
    main: primaryColor,
    contrastText: bodyColor
  },
  secondary: {
    main: secondaryColor
  },
  text: {
    primary: SOY,
    secondary: MEDIUM_SOY,
    disabled: LIGHT_SOY,
    hint: secondaryColor
  },
  background: {
    default: WHITE,
    secondary: 'rgba(0,0,0,0.5)'
  },
  borders: {
    primary: MEDIUM_TOFU
  },
  action: {
    disabledBackground: primaryColor
  },
  error: {
    main: ERROR
  },
  white: WHITE,
  mediumTofu: MEDIUM_TOFU,
  lightSoy: LIGHT_SOY,
  mediumSoy: MEDIUM_SOY,
  soy: SOY,
  peach: PEACH,
  plum: PLUM,
  tomato: TOMATO,
  red: ERROR,
  bokChoy: BOK_CHOY,
  tofu: TOFU
});
