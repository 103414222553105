const constants = {
  boxShadows: {
    normal:
      'rgba(0, 0, 0, 0.08) 0px 3px 10px 0px, rgba(0, 0, 0, 0.01) 0px 1px 0px 0px, rgba(0, 0, 0, 0.01) 0px 0px 5px 0px',
    selected:
      '0px 3px 10px 0px rgba(0,0,0,0.3), 0px 1px 0px 0px rgba(0,0,0,0.01), 0px 0px 5px 0px rgba(0,0,0,.01)',
    appBar: '0 0px 7px 0 rgba(0, 0, 0, 0.1)',
    dialog:
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
  },
  padding: {
    modal: 20
  }
};

// NOTE: This is a static map with font names mapped to actual names
export const FONT_PRESET_MAP = {
  circular_std_book: 'CircularStd-Book',
  circular_std_medium: 'CircularStd-Medium',
  raleway: 'Raleway',
  montserrat: 'Montserrat',
  josefin_sans: 'Josefin Sans',
  playfair_display: 'Playfair Display',
  arvo: 'Arvo',
  dosis: 'Dosis',
  overpass: 'Overpass'
};

export const HEADER_AND_NAVIGATION_LETTER_SPACING = '1.25px';
export const DEFAULT_LETTER_SPACING = '0.25px';

export default constants;
