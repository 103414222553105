import { GROUP_ORDER } from 'bento-ordering/group_ordering/constants/entities';

export const GROUP_ORDER_SETTINGS = `/group-order-settings`;
export const GROUP_ORDER_INVITE = `/group-order-invite`;
export const GROUP_ORDER_JOIN = '/group-order-join';

export const entityPathMap = {
  [GROUP_ORDER]: {
    groupOrderSettings: GROUP_ORDER_SETTINGS,
    groupOrderInvite: GROUP_ORDER_INVITE,
    groupOrderJoin: GROUP_ORDER_JOIN
  }
};
