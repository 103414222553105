import { BASE } from 'bento-ordering/base/constants/endpoints';

export const CREATE_ORDER_WITHOUT_CHECKOUT_ENDPOINT = `${BASE}/online_ordering/create_order_without_checkout/`;
export const SET_SEAT_ID_ON_CART_ENDPOINT = `${BASE}/online_ordering/set_seat_id_on_cart/`;

export const CREATE_ORDER_WITHOUT_CHECKOUT_REQUEST =
  'CREATE_ORDER_WITHOUT_CHECKOUT_REQUEST';
export const CREATE_ORDER_WITHOUT_CHECKOUT_SUCCESS =
  'CREATE_ORDER_WITHOUT_CHECKOUT_SUCCESS';
export const CREATE_ORDER_WITHOUT_CHECKOUT_FAILURE =
  'CREATE_ORDER_WITHOUT_CHECKOUT_FAILURE';

export const SET_SEAT_ID_ON_CART_REQUEST = 'SET_SEAT_ID_ON_CART_REQUEST';
export const SET_SEAT_ID_ON_CART_SUCCESS = 'SET_SEAT_ID_ON_CART_SUCCESS';
export const SET_SEAT_ID_ON_CART_FAILURE = 'SET_SEAT_ID_ON_CART_FAILURE';
export const SET_SEAT_ID_MODAL = 'SET_SEAT_ID_MODAL';
