/**
 * get data stored on key for location. for use on reducers
 * that use byLocationId factory
 * @param {Object} state for entity
 * @param {number} id location id
 * @return {*} data stored under the location key.
 * @return {[]} if no key for that id
 */
export const getDataForLocation = (state, id) => {
  return state.byLocationId[id] || [];
};
