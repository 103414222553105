import get from 'lodash/get';
import reduceReducers from 'reduce-reducers';

import singleEntity from 'bento-ordering/base/reducers/singleEntity';
import { ACCOUNT as ENTITY } from 'bento-ordering/account/constants/entities';
import setupDataFactory from 'bento-ordering/base/reducers/setupDataFactory';
const apiReducer = singleEntity(ENTITY);
const reducers = reduceReducers(apiReducer, setupDataFactory(ENTITY));
export default reducers;

/* SELECTORS */

export const getCustomMessageSelector = state =>
  get(state, 'online_ordering_general_settings.custom_message');

export const getCartMinimumSelector = cart => {
  return parseFloat(get(cart, 'min_order_amount', 0));
};
export const getSingleLocationIdSelector = state =>
  state.single_online_ordering_location_id;

export const getBetaFeatureFlags = state =>
  get(state, 'account.beta_feature_flags', []);
