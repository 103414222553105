import React from 'react';

const setupAxeCore = () => {
  if (
    process.env.NODE_ENV !== 'production' &&
    String(process.env.ENABLE_ACCESSIBILITY_CHECKS) === 'true'
  ) {
    const ReactDOM = require('react-dom');
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }
};

export default setupAxeCore;
