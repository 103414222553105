/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_SITE, DATADOG_SERVICE } from 'logging/constants';
import { getEnvironment } from 'config/helpers';
import { PRODUCTION } from 'config/constants';

const _warn = console.warn;
const _info = console.info;

export const initializeDatadog = () => {
  const { DATADOG_CLIENT_TOKEN, NODE_ENV, SHORT_COMMIT_HASH } = process.env;

  const env = getEnvironment();

  if (env !== PRODUCTION) {
    return;
  }

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: NODE_ENV,
    version: SHORT_COMMIT_HASH,
    sampleRate: 100
  });

  // Override default console.warn functionality and send warning to datadog
  // Usage Format: console.warn('Message', { data: 'object' })
  console.warn = (subject, ...args) => {
    _warn.apply(console, [subject, ...args]);
    datadogLogs.logger.warn(subject, [...args]);
  };

  // Override default console.info functionality and send info to datadog
  // Usage Format: console.info('Message', { data: 'object' })
  console.info = (subject, ...args) => {
    _info.apply(console, [subject, ...args]);
    datadogLogs.logger.info(subject, [...args]);
  };
};
