import update from 'immutability-helper';
import get from 'lodash/get';
import { getFieldErrors } from 'bento-ordering/base/utils/errorHelpers';

import {
  CREATE_FAILURE,
  UPDATE_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_FAILURE,
  FETCH_LIST_SUCCESS,
  DELETE_REQUEST,
  CLONE_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_REQUEST,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  RESET_FIELD_ERRORS,
  RESET_API_STATE
} from 'bento-ordering/base/actions/actionTypes';

export const baseApiState = {
  byId: {},
  allIds: [],
  isFetching: false,
  isSaving: false,
  isError: false,
  fieldErrors: {},
  totalPages: 0,
  count: 0,
  lastFetched: 0
};

const updateListSuccess = (state, action) => {
  // Note that the totalPages and count properties should only be in the payload
  // if the response is paginated.
  return {
    ...state,
    byId: action.payload.byId,
    allIds: action.payload.allIds,
    totalPages: action.payload.totalPages,
    count: action.payload.count,
    ...action.meta
  };
};

const bentoApi = entity => {
  return (state = baseApiState, action) => {
    switch (action.type) {
      case `[${entity}] ${FETCH_LIST_REQUEST}`: {
        return {
          ...state,
          isFetching: true,
          isError: false
        };
      }
      case `[${entity}] ${FETCH_LIST_SUCCESS}`: {
        const updatedListState = updateListSuccess(state, action);
        return {
          ...updatedListState,
          isFetching: false,
          isError: false
        };
      }
      case `[${entity}] ${FETCH_LIST_FAILURE}`: {
        return {
          ...state,
          isFetching: false,
          isError: true
        };
      }

      case `[${entity}] ${DELETE_REQUEST}`: {
        const newById = update(state.byId, { $unset: [action.payload.id] });
        const indexToRemove = state.allIds.indexOf(action.payload.id);
        const newAllIds = update(state.allIds, {
          $splice: [[indexToRemove, 1]]
        });
        return {
          ...state,
          count: state.count - 1,
          byId: newById,
          allIds: newAllIds
        };
      }
      case `[${entity}] ${CLONE_SUCCESS}`: {
        const newId = action.payload.id;
        const sourceId = action.meta.source;
        const targetIndex = state.allIds.indexOf(sourceId);
        return {
          ...state,
          count: state.count + 1,
          allIds: update(state.allIds, { $splice: [[targetIndex, 0, newId]] }),
          byId: update(state.byId, { [newId]: { $set: action.payload } })
        };
      }
      case `[${entity}] ${UPDATE_REQUEST}`: {
        return {
          ...state,
          isSaving: true,
          isError: false
        };
      }
      case `[${entity}] ${UPDATE_SUCCESS}`: {
        const newById = update(state.byId, {
          [action.payload.id]: { $set: action.payload }
        });
        return {
          ...state,
          byId: newById,
          fieldErrors: {},
          isSaving: false,
          isError: false
        };
      }
      case `[${entity}] ${UPDATE_FAILURE}`: {
        return {
          ...state,
          isSaving: false,
          isError: true,
          fieldErrors: getFieldErrors(
            action.payload.status,
            action.payload.response
          )
        };
      }
      case `[${entity}] ${CREATE_REQUEST}`: {
        return {
          ...state,
          isSaving: true,
          isError: false
        };
      }
      case `[${entity}] ${CREATE_SUCCESS}`: {
        const entityId = action.payload.id;
        const newById = update(state.byId, {
          [entityId]: { $set: action.payload }
        });
        const newAllIds = update(state.allIds, { $push: [entityId] });
        return {
          ...state,
          count: state.count + 1,
          byId: newById,
          allIds: newAllIds,
          fieldErrors: {},
          isSaving: false,
          isError: false
        };
      }
      case `[${entity}] ${CREATE_FAILURE}`: {
        return {
          ...state,
          isSaving: false,
          isError: true,
          fieldErrors: getFieldErrors(
            action.payload.status,
            action.payload.response
          )
        };
      }
      case `[${entity}] ${FETCH_REQUEST}`: {
        return {
          ...state,
          isFetching: true,
          isError: false
        };
      }

      case `[${entity}] ${FETCH_SUCCESS}`: {
        const entityId = action.payload.id;
        const newById = update(state.byId, {
          [entityId]: { $set: action.payload }
        });
        const newAllIds = state.allIds.includes(entityId)
          ? state.allIds
          : update(state.allIds, { $push: [entityId] });
        return {
          ...state,
          allIds: newAllIds,
          byId: newById,
          fieldErrors: {},
          isFetching: false,
          isError: false
        };
      }
      case `[${entity}] ${RESET_FIELD_ERRORS}`: {
        return {
          ...state,
          isError: false,
          fieldErrors: {}
        };
      }
      case `[${entity}] ${RESET_API_STATE}`: {
        return {
          ...state,
          ...baseApiState
        };
      }
      default:
        return state;
    }
  };
};

export default bentoApi;

/* state here is entity specific, not the root of state */
export const getItemByIdSelector = (state, id) => {
  return get(state, `byId[${id}]`, {});
};

export const getProcessedListSelector = state => {
  return state.allIds.map(id => state.byId[id]);
};

export const getSoleEntity = state => {
  const id = get(state, `allIds[0]`);
  return getItemByIdSelector(state, id);
};

export const isSoleEntity = state => get(state, `allIds`, []).length === 1;
