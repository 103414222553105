import {
  CART_ITEMS_AVAILABLE_REQUEST,
  CART_ITEMS_AVAILABLE_SUCCESS,
  CART_ITEMS_AVAILABLE_FAILURE,
  CART_ITEMS_AVAILABLE_RESET
} from 'bento-ordering/cart/constants/actionTypes';

const initialState = {
  isAvailable: true
};

const cartAvailableReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case CART_ITEMS_AVAILABLE_REQUEST:
      return state;
    case CART_ITEMS_AVAILABLE_SUCCESS:
      return {
        ...state,
        ...actions.payload
      };
    case CART_ITEMS_AVAILABLE_FAILURE:
      return {
        ...state,
        isAvailable: false
      };
    case CART_ITEMS_AVAILABLE_RESET:
      return {
        ...state,
        isAvailable: true
      };
    default:
      return state;
  }
};

export default cartAvailableReducer;
