import isEqual from 'lodash/isEqual';

import { createSelectorCreator, defaultMemoize } from 'reselect';
/**
 * create a "selector creator" that uses lodash.isEqual instead of ===
 *
 * @return {function}
 */
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export default createDeepEqualSelector;
